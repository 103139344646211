@import 'variables.scss';

.progress-bar {
    --progress-bar-height: 20px;
    width: 100%;
    background-color: lighten($default-border-color, 15%);
    height: var(--progress-bar-height);
    border-radius: var(--progress-bar-height);
    overflow: hidden;
    text-align: left;

    .progress {
        height: 100%;
        border-top-right-radius: var(--progress-bar-height);
        border-bottom-right-radius: var(--progress-bar-height);
    }

    &.right {
        text-align: right;
        .progress {
            float: right;
            border-top-left-radius: var(--progress-bar-height);
            border-bottom-left-radius: var(--progress-bar-height);
        }
    }

    &.small {
        --progress-bar-height: 10px;
    }
}


.progress-comparison-bar-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;

    .detail-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        span {
            font-size: 13px;
            &.value-1 {
                text-align: left;
                color: $default-text-color-dark;
                flex: 1;
                font-weight: 500;
            }
            &.title {
                text-align: center;
                color: $default-text-color-light;
                width: 250px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            &.value-2 {
                text-align: right;
                color: $default-text-color-dark;
                flex: 1;
                font-weight: 500;
            }
        }
    }
    .bar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .progress-seperator {
            width: 20px;
        }
    }
}


//  Used in INV Dashboard
.detail-total-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2px;

    .detail-total-wrapper {
        --detail-progress-bar-height: 20px;
        width: 100%;
        background-color: lighten($default-border-color, 15%);
        height: var(--detail-progress-bar-height);
        border-radius: 2px;
        overflow: hidden;
        text-align: left;

        .detail-total {
            height: 100%;
        }

        &.small {
            --detail-progress-bar-height: 15px;
        }
    }

    .title {
        --title-color: #149ddd;
        width: 120px;
        padding: 2px 4px;
        text-align: center;
        background: var(--title-color);
        color: #fff;
        margin-left: 10px;
        border-radius: 4px;
        font-size: 12px;
        position: relative;

        &::before {
            content: ' ';
            position: absolute;
            border: 5px solid transparent;
            border-right-color: var(--title-color);
            left: -9px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    
    &.right {
        text-align: right;
        .detail-value {
            float: right;
        }
    }

    &:last-child {
        margin-bottom: unset;
    }

}