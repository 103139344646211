//  ==========================================================================
//  Created Date    :   April 20, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Guest Registration Form styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.grc {
    margin: 0;
    border: 1px solid $default-border-color;
    padding: 25px;

    table {
        &.as-grc-table {
            width: 100%;
            table-layout: fixed;
            border: none;

            thead {
                tr {
                    td, th {
                        font-weight: bold;
                        font-size: 14px;

                        &.grc-title {
                            color: $default-text-color-dark;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td, th {
                        border: 0;
                        font-size: 12px;
                        padding: 2px;
                        padding-left: 10px;

                        &.section-title {
                            text-transform: uppercase;
                            padding: 5px;
                            padding-left: 10px;
                            font-weight: bold;
                            color: $default-text-color-dark;
                            background: transparentize($default-guest-registration-card-secondary-theme, 0.5);

                            &.highlighted {
                                font-size: 14px;
                                font-weight: bold;
                                padding-top: 10px;
                                padding-left: 5px;
                                font-style: normal !important;
                                background: transparent;
                                color: $default-text-color-dark;
                                border-bottom: 2px solid $default-guest-registration-card-primary-theme;
                            }

                            &.for-hotel-use {
                                background: transparent;
                                padding-left: 5px;
                                text-decoration: underline;
                            }
                        }

                        &.rsvn-reg-number-section {
                            text-align: left;
                            background: transparentize($default-guest-registration-card-primary-theme, 0.4);
                            
                            span {
                                &.rsvn-reg-number {
                                    color: #ffffff;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    padding: 10px 15px;
                                    font-size: 20px;
                                }
                            }
                        }

                        span {
                            &.section-title {
                                font-weight: bold;
                            }
                            &.less-padding {
                                padding-top: 5px !important;
                            }
                            &.value {
                                font-weight: normal;
                                font-style: normal;
                                text-transform: none;
                                text-align: right;
                            }
                        }

                        &.terms-and-conditions-wrapper {

                            span {
                                &.terms-and-conditions {
                                    text-transform: uppercase;
                                    font-weight: bold;
                                    color: $default-text-color-dark;
                                    text-decoration: underline;
                                }
                            }
                            
                            div {
                                font-weight: unset !important;
                                font-style: unset !important;
                                font-size: 14px !important;
                                margin-top: 5px;
    
                                &.terms-and-condition-container {
                                    font-weight: normal !important;
                                    font-style: normal !important;
                                    text-transform: none !important;
                                }
                            }

                        }

                    }
                }
            }

            tfoot {
                tr {
                    td, th {
                        text-align: center;

                        div {
                            font-weight: unset !important;
                            font-style: unset !important;
                            font-size: 14px !important;
                        }
                    }
                }
            }

        }
    }

}