//  ==========================================================================
//  Created Date    :   April 20, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Popover styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-popover {
    position: relative !important;
    width: auto;
}

.as-popover-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px transparentize(#000000, 0.8);
    z-index: $default-popover-z-index;
    transition: all .2s ease-in-out;
    border-radius: 4px;
    &.drop-left {
        left: 0;
        right: auto;
    }
    &.drop-right {
        right: 0;
        left: auto;
    }
}

.as-show-popover {
    display: block;
}

.as-hide-popover {
    display: none;
}