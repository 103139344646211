@import "variables.scss";

.as-donut-chart {
    width: 100%;
    font-size: 16px;
    margin: 0 auto;
    --highlighted-portion: 0;
    --remaining-portion: 100;
    animation: all 2s ease-in-out;

    svg {
        &.as-donut {
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
            --as-donut-chart-stroke-width: 3;
            
            circle {
                &.donut-outer-section {
                    fill: $default-body-background-color;
                }
                &.donut-inner-section {
                    fill: #ffffff;
                }
                &.donut-ring {
                    fill: transparent;
                    stroke: lighten($default-border-color, 5%);
                    stroke-width: var(--as-donut-chart-stroke-width);
                }
                &.donut-highlight {
                    fill: transparent;
                    stroke-width: var(--as-donut-chart-stroke-width);
                    transform-origin: center;
                    stroke: teal;
                    stroke-dasharray: var(--highlighted-portion, 0), var(--remaining-portion, 100);
                    stroke-dashoffset: 25;
                    animation: highlightData 2s ease-in-out;
                }
            }

            g {
                &.donut-text {
                    fill: teal;

                    text {
                        tspan {
                            &.donut-percent {
                                font-size: 0.38em;
                                line-height: 1;
                                // transform: translateY(0.38em);
                                font-weight: bold;
                                text-align: center;
                                text-anchor: middle;
                            }
                            
                            &.donut-data {
                                font-size: 0.12em;
                                line-height: 0.12em;
                                // transform: translateY(0.5em);
                                text-align: center;
                                text-anchor: middle;
                                color: $default-text-color-light;
                                fill: $default-text-color-light;
                            }
                        }
                    }
                }
            }
        }
    }
    
    &.negative {
        svg {
            &.as-donut {
                circle {
                    &.donut-highlight {
                        stroke: $default-red-color;
                        transform: scaleX(-1);
                    }
                }
    
                g {
                    &.donut-text {
                        fill: $default-red-color;
                        text {
                            tspan {
                                &.donut-data {
                                    color: $default-red-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes highlightData {
    0% {
        stroke-dasharray: 0, 100;
    }
    100% {
        stroke-dasharray: var(--highlighted-portion, 0), var(--remaining-portion, 100);
    }
}