@import "variables.scss";

//  Grid & Media Rules
.grid-row {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    gap: 15px;

    .grid-col {
        // Default to full width for the smallest breakpoint
        grid-column: span 16;

        @media (min-width: 576px) { // xs breakpoint
            &.xs-1 { grid-column: span 1; }
            &.xs-2 { grid-column: span 2; }
            &.xs-3 { grid-column: span 3; }
            &.xs-4 { grid-column: span 4; }
            &.xs-5 { grid-column: span 5; }
            &.xs-6 { grid-column: span 6; }
            &.xs-7 { grid-column: span 7; }
            &.xs-8 { grid-column: span 8; }
            &.xs-9 { grid-column: span 9; }
            &.xs-10 { grid-column: span 10; }
            &.xs-11 { grid-column: span 11; }
            &.xs-12 { grid-column: span 12; }
            &.xs-13 { grid-column: span 13; }
            &.xs-14 { grid-column: span 14; }
            &.xs-15 { grid-column: span 15; }
            &.xs-16 { grid-column: span 16; }
        }

        @media (min-width: 768px) { // sm breakpoint
            &.sm-1 { grid-column: span 1; }
            &.sm-2 { grid-column: span 2; }
            &.sm-3 { grid-column: span 3; }
            &.sm-4 { grid-column: span 4; }
            &.sm-5 { grid-column: span 5; }
            &.sm-6 { grid-column: span 6; }
            &.sm-7 { grid-column: span 7; }
            &.sm-8 { grid-column: span 8; }
            &.sm-9 { grid-column: span 9; }
            &.sm-10 { grid-column: span 10; }
            &.sm-11 { grid-column: span 11; }
            &.sm-12 { grid-column: span 12; }
            &.sm-13 { grid-column: span 13; }
            &.sm-14 { grid-column: span 14; }
            &.sm-15 { grid-column: span 15; }
            &.sm-16 { grid-column: span 16; }
        }

        @media (min-width: 992px) { // md breakpoint
            &.md-1 { grid-column: span 1; }
            &.md-2 { grid-column: span 2; }
            &.md-3 { grid-column: span 3; }
            &.md-4 { grid-column: span 4; }
            &.md-5 { grid-column: span 5; }
            &.md-6 { grid-column: span 6; }
            &.md-7 { grid-column: span 7; }
            &.md-8 { grid-column: span 8; }
            &.md-9 { grid-column: span 9; }
            &.md-10 { grid-column: span 10; }
            &.md-11 { grid-column: span 11; }
            &.md-12 { grid-column: span 12; }
            &.md-13 { grid-column: span 13; }
            &.md-14 { grid-column: span 14; }
            &.md-15 { grid-column: span 15; }
            &.md-16 { grid-column: span 16; }
        }

        @media (min-width: 1200px) { // lg breakpoint
            &.lg-1 { grid-column: span 1; }
            &.lg-2 { grid-column: span 2; }
            &.lg-3 { grid-column: span 3; }
            &.lg-4 { grid-column: span 4; }
            &.lg-5 { grid-column: span 5; }
            &.lg-6 { grid-column: span 6; }
            &.lg-7 { grid-column: span 7; }
            &.lg-8 { grid-column: span 8; }
            &.lg-9 { grid-column: span 9; }
            &.lg-10 { grid-column: span 10; }
            &.lg-11 { grid-column: span 11; }
            &.lg-12 { grid-column: span 12; }
            &.lg-13 { grid-column: span 13; }
            &.lg-14 { grid-column: span 14; }
            &.lg-15 { grid-column: span 15; }
            &.lg-16 { grid-column: span 16; }
        }

        label {
            font-size: 0.9rem;
        }
    }
}