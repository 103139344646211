//  ==========================================================================
//  Created Date    :   April 20, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Text styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-text-theme {
  color: $default-theme-color;
}

.as-text-blue {
  color: $default-blue-color;
}

.as-text-green {
  color: $default-green-color;
}

.as-text-yellow {
  color: $default-yellow-color;
}

.as-text-red {
  color: $default-red-color;

  &.dark {
    color: darken($default-red-color, 30%) !important;
  }

}

.as-text-light-blue {
  color: $default-light-blue-color;
}

.as-text-purple {
  color: $default-purple-accent;
}

.as-text-default {
  color: $default-text-color;
}

.as-text-light {
  color: $default-text-color-light;
}

.as-text-dark {
  color: $default-text-color-dark;
}

.as-text-normal {
  color: $default-text-color !important;
}

.as-text-border {
  color: $default-border-color !important;
}

.as-text-bold {
  font-weight: bold;
}

.as-text-left {
  text-align: left;
}

.as-text-center {
  text-align: center;
}

.as-text-right {
  text-align: right;
}

.as-text-small {
  font-size: small;
}

.as-text-smaller {
  font-size: smaller;
}

.as-text-x-small {
  font-size: x-small;
}

.as-text-xx-small {
  font-size: xx-small;
}

.as-text-medium {
  font-size: $default-font-size;
}

.as-text-large {
  font-size: large;
}

.as-text-larger {
  font-size: larger;
}

.as-text-x-large {
  font-size: x-large;
}

.as-text-xx-large {
  font-size: xx-large;
}