@import './variables.scss';

button {
    &.link-btn {
        width: 100%;
        border-radius: 0;
        padding: 0;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.formula-code-container {
    border: 1px solid $default-border-color;
    border-radius: 5px;
    padding: 10px;
    max-height: 50vh;
    overflow-y: auto;

    .code-list {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

        .code-item {

            button {
                width: 100%;
                text-align: left;
                padding: 10px;
                border: 1px solid transparent;
                border-radius: 5px;
                background-color: transparentize($default-border-color, 0.92);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &.with-border {
                    border: 1px solid transparentize($default-border-color, 0.5);
                }

                &:hover, &:focus {
                    border-color: transparentize($default-border-color, 0.5);
                    background-color: transparentize($default-border-color, 0.5);
                }

                span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    &.code {
                        font-size: 14px;
                        color: $default-text-color-dark;
                        display: block;
                    }
                    &.head {
                        font-size: 11px;
                        color: $default-text-color-light;
                        display: block;
                        padding-top: 2px;
                    }
                }
            }
        }
    }
}