//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Master styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

* {
    box-sizing: border-box;
    caret-color: $default-theme-color;
}

html, body {
    font-family: $default-font-family;
    font-size: $default-font-size;
    background: $default-body-background-color !important;
    color: $default-text-color;
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    margin: 0;
}


//  Scroller Vertical
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border-radius: 4px;
    &:hover {
        background: #888888;
    }
}

//  Scrollbar Horizontal
::-webkit-scrollbar:horizontal {
    height: 8px;
}

::-webkit-scrollbar-track:horizontal {
    background: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
    background: #d4d4d4;
    border-radius: 4px;
    &:hover {
        background: #888888;
    }
}


//  Code Block (Used only on guides)
.codeblock{
    border:1px solid #cccccc;
    border-left: 5px solid $default-theme-color;
    border-radius: 4px;
    background-color: rgb(56, 56, 56);
    padding: 15px;
    color: #ffffff;
    max-height: 400px;
    overflow: hidden;
    //  Scroller For Code Block
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #d4d4d4;
        border-radius: 2px;
        &:hover {
            background: #d4d4d4;
        }
    }
    &:hover {
        overflow: overlay;
    }
}

//  Overlay For Small Screens
#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparentize($default-text-color, 0.02);
    z-index: $default-overlay-z-index;
    cursor: pointer;
    overflow: hidden;
}

//  Unset All
*.as-unset-all {
    all: unset !important;
}

//  Word related
.break-word {
    overflow-wrap: break-word;
}

//  Rotate
.as-rotate {
    
    animation: rotateObject 2s linear infinite;
    -webkit-animation: rotateObject 2s linear infinite;
    -moz-animation: rotateObject 2s linear infinite;
    -ms-animation: rotateObject 2s linear infinite;
    -o-animation: rotateObject 2s linear infinite;

    @keyframes rotateObject {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes rotateObject {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

}


//  Space/Padding/Float For Items
.space-right {
    padding-right: 10px;
}

.space-left {
    padding-left: 10px;
}

.space-both {
    padding-left: 10px;
    padding-right: 10px;
}

.as-float-right {
    float: right;
}

.as-float-left {
    float: left;
}


//  For Borders
.as-border-top {
    border-top: 1px solid $default-border-color;
}

.as-border-right {
    border-right: 1px solid $default-border-color;
}

.as-border-bottom {
    border-bottom: 1px solid $default-border-color;
}

.as-border-left {
    border-left: 1px solid $default-border-color;
}

//  For Right Click Menu List
.menu-option {
    color: $default-text-color-light !important;
    text-align: left !important;
    cursor: pointer !important;

    span {
        padding-right: 10px !important;
    }

    &:hover {
        background: transparentize($default-theme-color, 0.8) !important;
        color: $default-text-color-dark !important;
    }
}

//  For Local SVG
svg {
    &.as-local-svg {
        path, circle, rect, line, polygon, ellipse {
            fill: $default-theme-color !important;
        }
    }
}


.shortcut-badge {
    display: inline;
    text-transform: uppercase;
    text-decoration: underline;
    background-color: transparentize($default-border-color, 0.5);
    color: $default-text-color-dark !important;
    border-radius: 2px;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: bold;
    line-height: 0;
    box-shadow: 0px 0px 1px 0px #00000090;

    &.no-underline {
        text-decoration: none;
    }
}

.vip-badge-container-for-list{
    width: 35px;
    height: 35px;

    svg {
        filter: drop-shadow(0px 1px 1px #00000020);
        path, rect {
            fill: rgb(234, 180, 21);
        }
    }
}


//  Remove Up and Down caret from number input
input {
    &[type=number] {
        &.without-action-btns {
            &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                -webkit-appearance: none !important;
            }
        }
    }
}


//  Grid & Media Rules
.row {
    &::after {
        content: "";
        clear: both;
        display: table;
    }
}

[class*="col-"] {
    float: left;
    padding: 10px;

    &.no-padding {
        &.all {
            padding: 0;
        }
        &.left {
            padding-left: 0;
        }
        &.right {
            padding-right: 0;
        }
        &.top {
            padding-top: 0;
        }
        &.bottom {
            padding-bottom: 0;
        }
    }
}

.row {
    &.min-space {
        [class*="col-"] {
            float: left;
            padding: 8px;
        }

        .as-form-wrapper {
            [class*="col-"] {
                float: left;
                padding: 8px;

                .as-error-message {
                    font-size: x-small !important;
                    padding: 3px 3px !important;
                }

            }   
        }
    }
}

@media only screen and (min-width: 992px){
    //  For Large Screens
    .col-1 {width: 8.33%;}
    .col-2 {width: 16.66%;}
    .col-3 {width: 25%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 75%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}
    .col-12 {width: 100%;}
}

@media only screen and (max-width: 992px) {
    //  For Medium Screens
    .col-m-1 {width: 8.33%;}
    .col-m-2 {width: 16.66%;}
    .col-m-3 {width: 25%;}
    .col-m-4 {width: 33.33%;}
    .col-m-5 {width: 41.66%;}
    .col-m-6 {width: 50%;}
    .col-m-7 {width: 58.33%;}
    .col-m-8 {width: 66.66%;}
    .col-m-9 {width: 75%;}
    .col-m-10 {width: 83.33%;}
    .col-m-11 {width: 91.66%;}
    .col-m-12 {width: 100%;}
}

@media only screen and (max-width: 768px) {
    //  For Small Screens
    .col-s-1 {width: 8.33%;}
    .col-s-2 {width: 16.66%;}
    .col-s-3 {width: 25%;}
    .col-s-4 {width: 33.33%;}
    .col-s-5 {width: 41.66%;}
    .col-s-6 {width: 50%;}
    .col-s-7 {width: 58.33%;}
    .col-s-8 {width: 66.66%;}
    .col-s-9 {width: 75%;}
    .col-s-10 {width: 83.33%;}
    .col-s-11 {width: 91.66%;}
    .col-s-12 {width: 100%;}
}

@media only screen and (max-width: 480px) {
    //  For Extra Small Screens
    .col-x-1 {width: 8.33%;}
    .col-x-2 {width: 16.66%;}
    .col-x-3 {width: 25%;}
    .col-x-4 {width: 33.33%;}
    .col-x-5 {width: 41.66%;}
    .col-x-6 {width: 50%;}
    .col-x-7 {width: 58.33%;}
    .col-x-8 {width: 66.66%;}
    .col-x-9 {width: 75%;}
    .col-x-10 {width: 83.33%;}
    .col-x-11 {width: 91.66%;}
    .col-x-12 {width: 100%;}
}

@media only screen and (min-width: 400px) {
    //  For Extra Small Screens (iPhone 5 width or less)
    .col-xx-1 {width: 8.33%;}
    .col-xx-2 {width: 16.66%;}
    .col-xx-3 {width: 25%;}
    .col-xx-4 {width: 33.33%;}
    .col-xx-5 {width: 41.66%;}
    .col-xx-6 {width: 50%;}
    .col-xx-7 {width: 58.33%;}
    .col-xx-8 {width: 66.66%;}
    .col-xx-9 {width: 75%;}
    .col-xx-10 {width: 83.33%;}
    .col-xx-11 {width: 91.66%;}
    .col-xx-12 {width: 100%;}
}