@import url(variables.scss);

.rsvn-group-header-container {
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparentize(#ffffff, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0px -5px 0px $default-body-background-color,
                0px 1px 10px rgba(0, 0, 0, 0.06);
    position: sticky;
    top: 11.4rem;
    z-index: 4;

    &.attached-to-card {
        border-radius: 0 0 0.5rem 0.5rem;
    }

    .left-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;

        .checkbox-input-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;
        }

        input {
            &[type=checkbox] {
                margin: 0;
            }
        }

        label {
            &.check-uncheck-label {
                margin: 0 !important;
                line-height: normal;
                font-size: 1rem;
                color: $default-text-color;

                &.light {
                    color: $default-text-color-light;
                }
            }
        }
    }

    .right-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;
    }

    button {
        margin: 0;
    }
}


.rsvn-update-info-wrapper {
    border-right: 4px solid $default-green-color;
    padding: 0.5rem;
    span {
        &.rsvn-number-title {
            font-size: 1.25rem;
            font-weight: bold;
            color: $default-green-color;
            text-transform: uppercase;
            font-style: italic;
        }
    }
}


.rg-container {
    --_grid-gap: 0.5rem;
    display: grid;
    grid-template-columns: 0.1fr 3fr 9fr minmax(18rem, 3fr) 0.1fr;
    justify-items: start;
    align-items: start;
    gap: calc(var(--_grid-gap) * 2);
    margin: 1rem 0;
    padding: calc(var(--_grid-gap) * 2);
    background-color: #ffffff;
    border-radius: 0.5rem;
    border-left: 0.5rem solid #ffffff;
    position: relative;

    &:first-child {
        margin-top: 0.5rem;
    }

    &.has-error {
        background-color: transparentize($default-red-color, 0.9);
    }

    &.provisional, &.confirmed, &.wait-listed, &.cancelled, &.no-show {
        .rsvn-status {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $default-text-color-light;
            padding: calc(var(--_grid-gap) * 0.5)
                    calc(var(--_grid-gap) * 1.5);
            border-top-right-radius: 0.5rem;
            border-bottom-left-radius: 1rem;
            -webkit-box-shadow: 0 3px 5px transparentize(#000000, 0.75);
            box-shadow: 0 3px 5px transparentize(#000000, 0.75);

            span {
                font-weight: bold;
                text-transform: uppercase;
                color: #ffffff;
            }
        }
    }

    &.provisional {
        .rsvn-status {
            background-color: $default-blue-color;
        }
    }

    &.confirmed {
        .rsvn-status {
            background-color: $default-green-color;
        }
    }

    &.wait-listed {
        .rsvn-status {
            background-color: $default-yellow-color;
        }
    }

    &.cancelled {
        .rsvn-status {
            background-color: $default-red-color;
        }
    }

    &.no-show {
        .rsvn-status {
            background-color: $default-red-color;
        }
    }

    .rg-col {
        display: grid;
        gap: var(--_grid-gap);

        &.action-col {
            display: grid;
            grid-template-columns: 1fr;
            align-items: start;
            justify-items: start;
            // gap: var(--_grid-gap);

            input {
                &[type=checkbox] {
                    // margin-top: calc(var(--_grid-gap) * 2);
                    height: 15px !important;
                    width: 15px !important;
                    padding: 0 !important;

                    &:not(.switch) {
                        width: 15px !important;
                        &::after {
                            width: 3px;
                            height: 5px;
                            left: 4px;
                            top: 2px;
                        }
                    }
                }
            }

            .guest-classification-wrapper {
                width: 1.5rem;
            }

            .country-wrapper {
                width: 1.5rem;
                height: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparentize($default-purple-accent, 0.9);
                border-radius: 1.5rem;
                font-size: 1.5rem;
                color: $default-purple-accent;
            }

            .guest-classification-wrapper, .country-wrapper {
                position: relative;
                &.with-title {
                    cursor: pointer;
                    text-transform: capitalize;
                    animation: all 0.25s ease-in-out;
                    &:hover, &:focus {
                        &::before {
                            content: attr(data-title);
                            position: absolute;
                            background-color: $default-text-color-dark;
                            color: #fff;
                            white-space: nowrap;
                            padding: 0.4rem 0.8rem;
                            border-radius: 5px;
                            top: 50%;
                            left: 2rem;
                            transform: translateY(-50%);
                            pointer-events: none;
                            z-index: 4;
                            font-size: 1rem;
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            padding: 0;
                            height: 0;
                            width: 0;
                            border: 5px solid transparent;
                            border-right-color: $default-text-color-dark;
                            top: 50%;
                            left: 1.25rem;
                            transform: translateY(-50%);
                            pointer-events: none;
                            z-index: 4;
                        }
                    }
                }
            }

            &:last-child {
                align-items: center;
                justify-items: center;
                justify-self: center;
                align-self: center;
            }

            .action-btn {
                --__main-action-btn-size: 2.5rem;
                width: var(--__main-action-btn-size);
                height: var(--__main-action-btn-size);
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid transparentize($default-text-color-light, 0.9);
                border-radius: calc(var(--__main-action-btn-size) * 2);
                background-color: transparentize($default-text-color-light, 0.9);
                color: $default-text-color-light;
                font-size: calc(var(--__main-action-btn-size) * 0.4);

                
                &.trash-btn {
                    border-color: transparentize($default-red-color, 0.9);
                    background-color: transparentize($default-red-color, 0.9);
                    color: $default-red-color;
                    position: relative;

                    &:hover, &:focus {
                        &::before {
                            content: attr(data-title);
                            position: absolute;
                            background-color: darken($default-red-color, 10%);
                            color: #fff;
                            white-space: nowrap;
                            padding: calc(var(--__main-action-btn-size) * 0.15) calc(var(--__main-action-btn-size) * 0.25);
                            border-radius: 5px;
                            top: calc(var(--__main-action-btn-size) * 1.25);
                            right: 0;
                            pointer-events: none;
                            z-index: 4;
                            font-size: 1rem;
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            padding: 0;
                            height: 0;
                            width: 0;
                            border: 8px solid transparent;
                            border-bottom-color: darken($default-red-color, 10%);
                            top: calc(var(--__main-action-btn-size) * 0.55);
                            transform: translateY(35%);
                            pointer-events: none;
                            z-index: 4;
                        }
                    }

                    &:disabled {
                        &:hover, &:focus {
                            &::before, &::after {
                                all: unset;
                            }
                        }
                    }
                }
            }
        }

        &.date-input-col {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr minmax(5rem, 1fr);
            align-content: start;
            justify-content: space-between;
            align-items: start;
            justify-items: stretch;

            .date-time-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 0;
                
                --_toggle-btn-size: 3rem;
    
                label {
                    margin-left: var(--_toggle-btn-size);
                }
    
                .date-time-input-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
    
                    .date-input-with-toggle-btn {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
        
                        button {
                            &.date-toggle-btn {
                                background-color: $default-theme-color;
                                color: #fff;
                                align-self: stretch;
                                width: var(--_toggle-btn-size);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid $default-theme-color;
                                border-top-left-radius: 5px;
                                font-size: 14px;
                                margin: 0;
                                padding: 0;
        
                                &.arrival {
                                    background-color: #003893;
                                    border-color: #003893;
                                }
                                &.departure {
                                    background-color: #DC143C;
                                    border-color: #DC143C;
                                    border-top-left-radius: 0;
                                    border-bottom-left-radius: 5px;
                                }
        
                                &.time-toggle-btn {
                                    width: calc(var(--_toggle-btn-size) * 0.75);
                                    &.arrival {
                                        border-color: transparent;
                                        border-top: 1px solid $default-border-color;
                                        border-radius: 0;
                                        background-color: transparent;
                                        color: #003893;
                                        -webkit-box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
                                        box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
                                    }
    
                                    &.departure {
                                        border-color: transparent;
                                        border-bottom: 1px solid $default-border-color;
                                        border-radius: 0;
                                        background-color: transparent;
                                        color: #DC143C;
                                        -webkit-box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
                                        box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
                                    }
                                }
                            }
                        }
            
                        input {
                            &.as-form-component {
                                &.date-input {
                                    border-left: 1px solid transparent;
                                    border-right: 1px solid transparent;
                                    border-radius: 0;
                                    min-width: 8rem;
        
                                    &.as-required {
                                        border-color: $default-red-color;
                                    }
    
                                    &.arrival {
                                        border-bottom: 1px solid transparent;
                                        &.as-required {
                                            border-bottom-color: $default-red-color;
                                        }
                                    }
    
                                    &.departure {
                                        border-top: 1px solid transparent;
                                        &.as-required {
                                            border-top-color: $default-red-color;
                                        }
                                    }
                                }
                                &.time-input {
                                    border-radius: 0;
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                    border-left: 1px solid transparent;
                                    width: 5rem;
        
                                    &.as-required {
                                        border-top-color: $default-red-color;
                                        border-left-color: $default-red-color;
                                    }
    
                                    &.arrival {
                                        border-bottom: 1px solid transparent;
                                        border-bottom-right-radius: 0;
                                        &.as-required {
                                            border-bottom-color: $default-red-color;
                                        }
                                    }
    
                                    &.departure {
                                        border-top: 1px solid transparent;
                                        border-top-right-radius: 0;
                                        &.as-required {
                                            border-top-color: $default-red-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }

        &.room-and-guest-col {
            display: grid;
            grid-template-columns: 1fr;
            align-content: start;
            justify-content: space-between;
            align-items: start;
            justify-items: stretch;

            .room-and-guest-col__row-1 {
                display: grid;
                grid-template-columns: minmax(33%, 1fr) minmax(33%, 1fr) 1fr;
                align-content: start;
                justify-content: space-between;
                align-items: start;
                justify-items: stretch;
                gap: var(--_grid-gap);

                .room-and-guest-col__row-1__col {
                    .occupancy-wrapper {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        align-content: start;
                        justify-content: space-between;
                        align-items: start;
                        justify-items: stretch;
                        gap: var(--_grid-gap);

                        .occupancy-input-label {
                            flex: 1;
                            position: relative;

                            label {
                                margin: 0 !important;
                                position: absolute;
                                top: 50%;
                                left: var(--_grid-gap);
                                transform: translateY(-50%);
                            }

                            input {
                                padding-left: calc(var(--_grid-gap) * 3);
                                -moz-appearance: textfield;
                
                                /* Chrome, Safari, Edge, Opera */
                                &::-webkit-outer-spin-button,
                                &::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }
                            }
                        }

                        // &.a-c-i {
                        //     .occupancy-input-label {
                        //         input {
                        //             padding-left: calc(var(--_grid-gap) * 5);
                        //         }
                        //     }
                        // }
                    }
                }
            }

            .room-and-guest-col__row-2 {
                display: grid;
                grid-template-columns: minmax(12%, 1fr) 2fr 2fr auto;
                align-content: start;
                justify-content: space-between;
                align-items: start;
                justify-items: stretch;
                gap: var(--_grid-gap);
            }
        }

        &.occupancy-col, &.rate-col {
            justify-content: stretch;
            flex-direction: column;
            
            .occupancy-col__row-1, .occupancy-col__row-2, .rate-col__row-1 {
                display: flex;
                justify-content: space-between;
                gap: var(--_grid-gap);
                width: 100%;

                .occupancy-col__row-2__col, .rate-col__row-1__col {
                    flex: 1;
                    max-width: 100%;

                    &.guest-details-btn-col {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        flex: unset;
                    }

                    .selected-rate-details {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: var(--_grid-gap);

                        input {
                            &.selected-rate-value-input {
                                font-size: 1rem;
                                border-color: transparentize($default-border-color, 0.75);
                                box-shadow: none;
                                
                                &:first-child {
                                    color: $default-text-color-dark;
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: 0;
                                    border-right: 0;
                                }
                                &:last-child {
                                    color: $default-green-color;
                                    text-align: right;
                                    border-top-left-radius: 0;
                                    border-bottom-left-radius: 0;
                                    border-left: 0;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.as-blink {
        animation: blinkContainer .4s infinite ease-in-out;
        &.updated {
            animation: blinkUpdatedContainer .4s infinite ease-in-out;
        }
    }

    @keyframes blinkContainer {
        0% {
            background-color: transparentize($default-theme-color, 0.96);
        }
        50% {
            background-color: transparentize($default-theme-color, 0.80);
        }
        100% {
            background-color: transparentize($default-theme-color, 0.96);
        }
    }

    @keyframes blinkUpdatedContainer {
        0% {
            background-color: transparentize($default-yellow-color, 0.96);
        }
        50% {
            background-color: transparentize($default-yellow-color, 0.80);
        }
        100% {
            background-color: transparentize($default-yellow-color, 0.96);
        }
    }
}


.total-nights-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 1.5rem;


    svg {
        width: 5rem;
        height: auto;
    }

    .total-nights-input-wrapper {
        position: absolute;
        width: 100%;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -60%);

        input {
            &.total-nights-input {
                padding: 0;
                margin: 0;
                width: auto;
                max-width: 4rem;
                background: transparent;
                border: 0;
                box-shadow: none;
                color: #fff;
                text-align: center;
                font-size: 1.15rem !important;
                font-weight: bold;
                -moz-appearance: textfield;
                
                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }

        strong {
            font-size: 1rem;
            color: #fff;
            display: block;
        }
    }
}


button {
    cursor: pointer;

    &.guest-action-btn {
        background-color: $default-theme-color;
        color: #fff;
        // align-self: stretch;
        width: var(--_toggle-btn-size);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $default-theme-color;
        border-radius: 5px;
        font-size: 14px;
        margin: 0;
        padding: 5px;
        position: relative;

        &.guest-history {
            background-color: $default-blue-color;
            border-color: $default-blue-color;
        }

        &.guest-details-btn {
            background-color: $default-green-color;
            border-color: $default-green-color;
        }

        &.edit-rate {
            background-color: $default-yellow-color;
            border-color: $default-yellow-color;
            margin-left: var(--_grid-gap);
        }
    }

    &.with-data-title {
        --_action-btn-size: 1rem;

        &:hover, &:focus {
            &::before {
                content: attr(data-title);
                position: absolute;
                background-color: $default-text-color;
                color: #fff;
                white-space: nowrap;
                padding: calc(var(--_action-btn-size) * 0.25) calc(var(--_action-btn-size) * 0.5);
                border-radius: 5px;
                top: calc(var(--_action-btn-size) * 2.5);
                pointer-events: none;
                z-index: 4;
                font-size: 1rem;
            }
            &::after {
                content: '';
                position: absolute;
                padding: 0;
                height: 0;
                width: 0;
                border: 8px solid transparent;
                border-bottom-color: $default-text-color;
                top: var(--_action-btn-size);
                transform: translateY(35%);
                pointer-events: none;
                z-index: 4;
            }
        }
    }
}


.room-number-option {
    span {
        &.in-house-room {
            color: $default-in-house-color;
            font-size: 0.82rem;
        }
    }

    &.react-select-disabled-option {
        color: $default-red-color;
        cursor: not-allowed;
    
        span {
            &.already-assigned-room {
                color: $default-red-color;
                font-size: 0.82rem;
            }
        }
    }

}


.rate-input-option {
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.25rem;

    .rate-input-option__title-value-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        span {
            &.title {
                color: $default-text-color-light;
                font-size: 0.75rem;
                text-transform: uppercase;
            }
            &.value {
                color: $default-text-color-dark;
                font-size: 1rem;
            }
        }
    }


    .room-rate-wrapper {
        span {
            &.value {
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }

    .details-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        gap: 5px;

        .meal-plan-wrapper {
            span {
                &.value {
                    color: $default-green-color;
                    font-weight: 500;
                }
            }
        }

        .total-amount-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
            width: 100%;

            .amount-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 5px;
                width: 100%;

                span {
                    font-size: 0.8rem;
                    text-align: right;
                    &.currency, &.value {
                        color: $default-green-color;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}


.rsvn-group__option-container {
    button {
        --_opt-btn-size: 30px;
        // width: var(--_opt-btn-size);
        // height: var(--_opt-btn-size);
        margin: 0;
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid transparentize($default-yellow-color, 0.85);
        border-radius: calc(var(--_opt-btn-size) * 2);
        background-color: transparentize($default-yellow-color, 0.85);
        color: $default-yellow-color;
    }
}


svg {
    &.as-local-svg {
        &.regular-icon {
            path {
                fill: #00835e !important;
            }
        }
        &.house-guest-icon {
            path {
                fill: #ff8000 !important;
            }
        }
        &.complimentary-icon {
            path {
                fill: #f40000 !important;
            }
        }
        &.home-with-moon-icon {
            path {
                fill: #0a2a54 !important;
            }

            &.error {
                path {
                    fill: #f54236 !important;
                }   
            }
        }
    }
}


.split-container {
    all: unset;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    gap: 1rem;

    .left-content {
        grid-column: span 10;
        transition: width 1s ease-in-out;
        width: 100%;
    }

    .right-content {
        display: inherit;
        grid-column: span 6;
        transition: opacity 1s ease-in-out, width 1s ease-in-out;
        opacity: 1;
        width: 100%;
    }

    &.hotel-position-hidden {
        transition: width 1s ease-in-out;

        .left-content {
            grid-column: span 16;
            width: 100%;
        }
        .right-content {
            grid-column: span 0;
            opacity: 0;
            visibility: hidden;
            width: 0;
        }
    }
}


button {
    &.hotel-position-toggle-btn {
        --_hp-toggle-btn-size: 1.85rem;
        height: var(--_hp-toggle-btn-size);
        width: var(--_hp-toggle-btn-size);
        margin: 0;
        padding: 0;
        display: grid;
        justify-items: center;
        align-items: center;
        border: 2px solid transparentize($default-green-color, 0.9);
        border-radius: 50%;
        background-color: transparentize($default-green-color, 0.75);
        color: $default-green-color;
        position: relative;

        &:hover, &:focus {
            &::before {
                content: attr(data-title);
                position: absolute;
                background-color: $default-text-color;
                color: #fff;
                white-space: nowrap;
                padding: calc(var(--_hp-toggle-btn-size) * 0.15) calc(var(--_hp-toggle-btn-size) * 0.25);
                border-radius: 5px;
                bottom: calc(var(--_hp-toggle-btn-size) * 1.25);
                pointer-events: none;
                z-index: 4;
                font-size: 1rem;
            }
            &::after {
                content: '';
                position: absolute;
                padding: 0;
                height: 0;
                width: 0;
                border: 5px solid transparent;
                border-top-color: $default-text-color;
                bottom: var(--_hp-toggle-btn-size);
                transform: translateY(35%);
                pointer-events: none;
                z-index: 4;
            }
        }
    }
}


.group-wise-guest-action-btn-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    button {
        padding: 2px;
        margin: 0;
        border-radius: 5px;
        height: unset;
        
        &.group-visibility-toggle-btn {
            height: 2.5rem;
            width: 2.5rem;
            margin: 0;
            padding: 0;
            display: grid;
            justify-items: center;
            align-items: center;
            border: 2px solid transparentize($default-gray-color, 0.9);
            border-radius: 50%;
            background-color: transparentize($default-gray-color, 0.75);
            color: $default-text-color;
            position: relative;

            &:hover, &:focus {
                background-color: transparentize($default-gray-color, 0.6);
            }
        }
    }
}


.guest-table-group-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
    font-weight: bold;
    background-color: transparentize($default-border-color, 0.75);
    color: $default-text-color-dark;
    border-radius: 5px;
    height: 3rem;
    overflow: hidden;

    .guest-table-group-header__info-container {
        flex: 1;
        padding: 10px;
        cursor: pointer;

        .group-info-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
    
            &.end {
                justify-content: flex-end;
            }
            
            .icon-text-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
            }
        }
    }

    .guest-table-group-header__action-container {
        padding: 10px;
    }

}

table {
    &.guest-details-tbl {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        --_guest-table-spacing: 2px;

        thead {
            tr {
                th, td {
                    white-space: nowrap;
                }
            }
        }

        thead, tbody {
            tr {
                th, td {
                    vertical-align: middle;
                    padding: calc(var(--_guest-table-spacing) * 2) var(--_guest-table-spacing);
                    border-bottom: 1px solid transparentize($default-border-color, 0.25);
                    color: $default-text-color-dark;
                    font-weight: 500;

                    &:nth-child(1) {
                        width: 3%;
                        text-align: center;
                    }
                    &:nth-child(2) {
                        width: 4%;
                        text-align: center;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(4) {
                        width: 36%;
                    }
                    &:nth-child(5) {
                        width: 26%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: var(--_guest-table-spacing);
                    border-bottom: 1px solid transparentize($default-border-color, 0.65);

                    button {
                        margin: 0 auto;
                        height: 2rem;
                        width: 2rem;
                        border-radius: 50%;
                        padding: 0;
                        display: grid;
                        justify-items: center;
                        align-items: center;
                        border-color: transparent;
                        background-color: transparent;
                    }
                }

                &:first-child {
                    td {
                        padding-top: calc(var(--_guest-table-spacing) * 2);
                    }
                }

                &.has-error {
                    td {
                        background-color: transparentize($default-red-color, 0.9);
                    }
                }
            }
        }

        &.with-full-column {
            thead, tbody {
                tr {
                    th, td {
                        &:nth-child(1) {
                            width: 2%;
                        }
                        &:nth-child(2) {
                            width: 3%;
                        }
                        &:nth-child(3) {
                            width: 6%;
                        }
                        &:nth-child(4) {
                            width: 18%;
                        }
                        &:nth-child(5) {
                            width: 12%;
                        }
                        &:nth-child(6) {
                            width: 8%;
                        }
                        &:nth-child(7) {
                            width: 10%;
                        }
                        &:nth-child(8) {
                            width: 15%;
                        }
                        &:nth-child(9) {
                            width: 13%;
                        }
                        &:nth-child(10) {
                            width: 13%;
                        }
                    }
                }
            }
        }

        input {
            &[type=checkbox] {
                margin: 0 !important;
                height: 15px !important;
                width: 15px !important;
                padding: 0 !important;

                &:not(.switch) {
                    width: 15px !important;
                    &::after {
                        width: 3px;
                        height: 5px;
                        left: 4px;
                        top: 2px;
                    }
                }
            }
        }
    }
}


.room-category__available-room {
    clip-path: polygon(15% 0, 100% 1%, 100% 100%, 15% 100%, 0% 50%);
    background-color: transparentize($default-theme-color, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    padding: 2px 4px 2px 6px;
    margin: -5px -5px;
    margin-right: -8px;
    
    span {
        color: $default-text-color-dark;
        font-size: 1rem;
        font-weight: bold;
    }

    &.selected {
        background-color: $default-theme-color;
        span {
            color: #ffffff;
        }
    }
}


button {
    &.lock-unlock-btn {
        background-color: transparentize(#d6a344, 0.9);
        color: #d6a344;
        border: 2px solid transparent;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
}