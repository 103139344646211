//  ==========================================================================
//  Created Date    :   March 9, 2022
//  Created By      :   Himal Poudel
//  Description     :   This file consists FO Package Rate styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";


.package-summary-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $default-body-background-color;
    padding: 20px 15px;
    border-radius: 10px;

    .package-summary-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 0 10px 10px;

        div {
            &.title-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                span {
                    &.package-summary-header-title {
                        font-size: 14px;
                        font-weight: bold;
                        color: $default-text-color-dark;
                        padding-left: 10px;
                    }
                }
            }
        }

    }

    .package-summary-body {
        input {
            border-color: darken($default-body-background-color, 10%) !important;
        }

        .package-details-table-wrapper {
            position: relative;
            width: 100%;
            max-height: 60vh;
            overflow-y: auto;

            table {
                &.rate-details-table {
                    thead {
                        tr {
                            td, th {
                                background-color: #ffffff;
                                position: sticky;
                                top: 0;
                                z-index: 1;
                            }
                            &:nth-child(2) {
                                td, th {
                                    top: 31px;
                                }   
                            }
                        }
                    }
                    tfoot {
                        tr {
                            td, th {
                                position: sticky;
                                bottom: 0;
                                z-index: 1;
                                background-color: #ffffff;
                                padding-top: 12px;
                                padding-bottom: 12px;
                            }
                        }
                    }
                }
            }
        }

    }

}


.label-checkbox-wrapper {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    label {
        margin: 0 !important;
        padding: 0 !important;
    }

    input {
        &[type=checkbox] {
            margin: 0 !important;
        }
    }
}