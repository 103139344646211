//  ==========================================================================
//  Created Date    :   April 16, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Sidebar styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import './variables.scss';

.as-sidebar {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    padding-bottom: 50px;
    border: none;
    width: $default-sidebar-width;
    height: 100%;
    background: $default-sidebar-background-color;
    box-shadow: 0px 0px 4px 0px $default-border-color;
    z-index: $default-sidebar-z-index;
    overflow-y: auto;
    overflow-x: hidden;

    ul {
        &.as-modules {
            box-sizing: border-box;
            list-style: none;
            margin: 0;
            padding: 10px 0;
            width: 100%;
            text-align: center;

            li {
                box-sizing: border-box;

                &.as-modules-lists {
                    margin: 0;
                    padding: $default-menu-lists-padding 5px $default-menu-lists-padding 0;
                    overflow: visible;

                    &.as-app-icon-wrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-family: 'Nexa Bold', 'NexaDemo-Bold', 'Roboto', 'Arial Black';
                        font-size: 11px;
                        letter-spacing: 0px;
                        color: $default-text-color-dark;
                        padding: 25px 5px;
                        position: sticky;
                        top: 0;
                        background-color: $default-sidebar-background-color;
                        z-index: $default-sidebar-header-z-index;

                        svg {
                            // max-width: 18px;
                            height: auto;
                            margin: 0;
                            padding: 0;

                            &.as-software-logo {
                                path {
                                    fill: $default-text-color-dark !important;
                                    &.highlight {
                                        fill: $default-theme-color !important;
                                    }
                                }
                            }

                        }

                        span {
                            margin: 0;
                            padding: 10px 0 0 0;
                            color: $default-text-color-dark;
                            white-space: nowrap;

                            span {
                                color: $default-theme-color;
                            }
                        }
                    }

                    .as-module-icon {
                        font-size: 30px;
                        font-weight: lighter;
                        padding-bottom: 10px;
                    }

                    .as-modules-menu-link {
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        justify-items: center;
                        border: none;
                        border-radius: 0;
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                        outline: none;
                        height: 68px;
                        width: 100%;
                        margin: 20px auto;
                        padding: 0px 5px;
                        font-weight: bold;
                        font-size: $default-menu-font-size;
                        background-color: transparent;
                        color: $default-theme-color;
                        text-decoration: none;
                        text-transform: uppercase;
                        cursor: pointer;
                        position: relative;

                        svg {
                            &.as-local-svg {
                                width: 45px;
                                height: 45px;
                                padding-bottom: 8px;
                            }
                        }

                        &:hover, &:focus, &:active {
                            
                            &::before {
                                content: "";
                                position: absolute;
                                background-color: transparent;
                                top: -25px;
                                height: 25px;
                                width: 15px;
                                left: 0;
                                border-bottom-left-radius: 12px;
                                box-shadow: 0 15px 0 0 lighten($default-border-color, 5%);
                                z-index: 1;
                            }
                            
                            &::after {
                                content: "";
                                position: absolute;
                                background-color: transparent;
                                bottom: -25px;
                                height: 25px;
                                width: 15px;
                                left: 0;
                                border-top-left-radius: 12px;
                                box-shadow: 0 -15px 0 0 lighten($default-border-color, 5%);
                                z-index: 1;
                            }

                            color: $default-text-color-dark;
                            background: lighten($default-border-color, 5%);
                            box-shadow:  0px 5px 15px transparentize($default-border-color, 0.9);

                            svg {
                                &.as-local-svg {
                                    path, circle, rect, line, polygon {
                                        fill: $default-text-color-dark !important;
                                        z-index: 2;
                                    }
                                }
                            }

                        }

                        &.as-visible-menu {
                            
                            &::before {
                                content: "";
                                position: absolute;
                                background-color: transparent;
                                top: -25px;
                                height: 25px;
                                width: 15px;
                                left: 0;
                                border-bottom-left-radius: 12px;
                                box-shadow: 0 15px 0 0 lighten($default-border-color, 5%);
                                z-index: 1;
                            }
                            
                            &::after {
                                content: "";
                                position: absolute;
                                background-color: transparent;
                                bottom: -25px;
                                height: 25px;
                                width: 15px;
                                left: 0;
                                border-top-left-radius: 12px;
                                box-shadow: 0 -15px 0 0 lighten($default-border-color, 5%);
                                z-index: 1;
                            }

                            color: $default-text-color-dark;
                            background: lighten($default-border-color, 5%);
                            box-shadow:  0px 5px 15px transparentize($default-border-color, 0.9);

                            svg {
                                &.as-local-svg {
                                    path, circle, rect, line, polygon {
                                        fill: $default-text-color-dark !important;
                                        z-index: 2;
                                    }
                                }
                            }


                        }

                        &.as-active-menu {
                            color: #ffffff;
                            background: $default-theme-color;
                            background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                            box-shadow:  0px 5px 15px transparentize($default-theme-color, 0.5);

                            svg {
                                &.as-local-svg {
                                    path, circle, rect, line, polygon {
                                        fill: #ffffff !important;
                                        z-index: 2;
                                    }
                                }
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                background-color: transparent;
                                top: -25px;
                                height: 25px;
                                width: 15px;
                                left: 0;
                                border-bottom-left-radius: 12px;
                                box-shadow: 0 15px 0 0 lighten($default-theme-color, 3%);
                                z-index: 1;
                            }
                            
                            &::after {
                                content: "";
                                position: absolute;
                                background-color: transparent;
                                bottom: -25px;
                                height: 25px;
                                width: 15px;
                                left: 0;
                                border-top-left-radius: 12px;
                                box-shadow: 0 -15px 0 0 $default-theme-color;
                                z-index: 1;
                            }

                            &:hover, &:focus, &:active {
                            
                                &::before {
                                    content: "";
                                    position: absolute;
                                    background-color: transparent;
                                    top: -25px;
                                    height: 25px;
                                    width: 15px;
                                    left: 0;
                                    border-bottom-left-radius: 12px;
                                    box-shadow: 0 15px 0 0 $default-theme-color;
                                    z-index: 1;
                                }
                                
                                &::after {
                                    content: "";
                                    position: absolute;
                                    background-color: transparent;
                                    bottom: -25px;
                                    height: 25px;
                                    width: 15px;
                                    left: 0;
                                    border-top-left-radius: 12px;
                                    box-shadow: 0 -15px 0 0 darken($default-theme-color, 5%);
                                    z-index: 1;
                                }

                                background: darken($default-theme-color, 5%);
                                background: linear-gradient(140deg, $default-theme-color, darken($default-theme-color, 5%), darken($default-theme-color, 10%));
                                box-shadow:  0px 5px 15px transparentize($default-theme-color, 0.3);
    
                            }

                        }

                        span {
                            &.as-menu-name {
                                white-space: nowrap;
                                font-size: $default-menu-font-size;
                                text-decoration: none;
                                text-transform: uppercase;
                                font-weight: 600;
                                z-index: 2;
                            }
                        }

                        &.disabled {
                            cursor: not-allowed;
                            color: $default-text-color-light;

                            svg {
                                &.as-local-svg {
                                    path, circle, rect, line, polygon {
                                        fill: $default-text-color-light !important;
                                    }
                                }
                            }

                            &:hover, &:focus, &:active {
                                &::before {
                                    box-shadow: 0 15px 0 0 transparent;
                                }
                                
                                &::after {
                                    box-shadow: 0 -15px 0 0 transparent;
                                }
    
                                color: $default-text-color-light;
                                background: transparent;
                                box-shadow:  0px 5px 15px transparent;
    
                                svg {
                                    &.as-local-svg {
                                        path, circle, rect, line, polygon {
                                            fill: $default-text-color-light !important;
                                        }
                                    }
                                }

                            }

                            &.as-active-menu {
                                background: $default-text-color-light;
                                background: linear-gradient(140deg, lighten($default-text-color-light, 5%), $default-text-color-light, darken($default-text-color-light, 5%));
                                box-shadow:  0px 5px 15px transparentize($default-text-color-light, 0.5);
                                color: #fff;
    
                                &::before {
                                    box-shadow: 0 15px 0 0 lighten($default-text-color-light, 3%);
                                }
                                
                                &::after {
                                    box-shadow: 0 -15px 0 0 $default-text-color-light;
                                }
    
                                &:hover, &:focus, &:active {
                                    &::before {
                                        box-shadow: 0 15px 0 0 lighten($default-text-color-light, 3%);
                                    }
                                    
                                    &::after {
                                        box-shadow: 0 -15px 0 0 $default-text-color-light;
                                    }
    
                                    background: $default-text-color-light;
                                    background: linear-gradient(140deg, lighten($default-text-color-light, 5%), $default-text-color-light, darken($default-text-color-light, 5%));
                                    box-shadow:  0px 5px 15px transparentize($default-text-color-light, 0.5);
                                    color: #fff;
                                }
    
                                svg {
                                    &.as-local-svg {
                                        path, circle, rect, line, polygon {
                                            fill: #fff !important;
                                        }
                                    }
                                }
                            }
                        }

                    }

                    button {

                        &.as-modules-menu-button {
                            box-sizing: border-box;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            justify-items: center;
                            border: none;
                            border-radius: 2px;
                            outline: none;
                            height: 68px;
                            width: 68px;
                            margin: 10px auto;
                            padding: 0px 5px;
                            font-weight: bold;
                            font-size: $default-menu-font-size;
                            background-color: transparent;
                            color: $default-theme-color;
                            text-transform: uppercase;
                            text-decoration: none;
                            cursor: pointer;
    
                            &:hover, &:focus, &:active {
                                color: #ffffff;
                                background: $default-theme-color;
                                background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                                box-shadow:  0px 5px 20px transparentize($default-theme-color, 0.5);
                            }

                            &.as-active-menu {
                                color: #ffffff;
                                background: $default-theme-color;
                                background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                                box-shadow:  0px 5px 20px transparentize($default-theme-color, 0.5);
                            }

                            &.as-opened-menu {
                                color: #ffffff;
                                background: $default-theme-color;
                                background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                                box-shadow:  0px 5px 20px transparentize($default-theme-color, 0.5);

                                &:after {
                                    content: ' ';
                                    position: absolute;
                                    height: 0px;
                                    width: 0px;
                                    border: 10px solid transparent;
                                    border-right-color: $default-theme-color;
                                    right: -15px;
                                }

                                &:focus {

                                    &:after {
                                        content: ' ';
                                        position: absolute;
                                        height: 0px;
                                        width: 0px;
                                        border: 10px solid transparent;
                                        border-right-color: $default-theme-color;
                                        right: -15px;
                                    }

                                }

                            }

                            span {
                                &.as-menu-name {
                                    white-space: nowrap;
                                    font-size: $default-menu-font-size;
                                    text-decoration: none;
                                    text-transform: uppercase;
                                    font-weight: 600;
                                }
                            }

                        }

                    }

                }

                .as-dropdown {
                    position: static !important;

                    .as-dropdown-content {
                        box-sizing: border-box;
                        border: 1px solid $default-border-color;
                        border-left: 2px solid $default-theme-color;
                        border-radius: 5px;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                        z-index: 1;
                        padding: 15px;
                        top: 10px;
                        left: 115%;
                        height: 98%;
                        max-height: 96%;
                        width: 60vw;

                        a {
                            border-radius: 5px;

                            &:hover {
                                color: $default-text-color-dark;
                                background-color: $default-body-background-color;
                            }
                        }

                    }

                }

                
            }
        }
    }

    //  Scroller Vertical
    &::-webkit-scrollbar {
        display: none;
    }

}


.as-mega-menu {
    box-sizing: border-box;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(5px);
    overflow: hidden !important;
    top: 0 !important;
    left: 90px !important;
    right: 0px !important;
    height: 100% !important;
    z-index: $default-mega-menu-z-index !important;
    cursor: pointer;

    .as-mega-menu-content {
        box-sizing: border-box !important;
        position: fixed;
        background-color: #ffffff !important;
        top: 0px !important;
        height: 100% !important;
        width: 65% !important;
        z-index: $default-mega-menu-content-z-index !important;
        overflow: hidden !important;
        -webkit-animation-name: displayMegaMenu;
        -webkit-animation-duration: 0.4s;
        animation-name: displayMegaMenu;
        animation-duration: 0.4s;
        -moz-animation-name: displayMegaMenu;
        -moz-animation-duration: 0.4s;
        box-shadow: 1px 0px 10px 10px transparentize(#000000, 0.9);

        .as-mega-menu-header {
            width: 100% !important;
            padding: 25px !important;
            text-align: center;

            span {
                &.as-mega-menu-module-name {
                    font-size: 14px;
                    color: $default-theme-color;
                }
            }

            input {
                &.as-search-menus {
                    width: 100% !important;
                    height: 30px !important;
                    box-sizing: border-box !important;
                    padding: 10px 40px !important;
                    border: 1px solid $default-border-color !important;
                    border-radius: 15px !important;
                    margin-top: 15px;
                    background: url(./../../Icons/search_icon.png) no-repeat;
                    background-position: 15px;
                    background-size: $default-font-size;
                }
            }
        }

        .as-mega-menu-body {
            height: 85vh !important;
            overflow: auto !important;

            .as-mega-menu-group {
                display: grid;
                grid-gap: 0.5rem;
                grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

                .as-mega-menu-lists-div {
                    height: auto !important;
                    max-height: 75vh !important;
                    overflow-y: auto !important;
                    overflow-x: hidden !important;
                    border-right: 1px solid transparentize($default-border-color, 0.5) !important;
                    padding-right: 20px !important;

                    ul {
                        list-style: none !important;
                        font-size: $default-mega-menu-font-size !important;
                        text-transform: uppercase;
                        color: $default-text-color-dark;

                        span{
                            text-align: center;
                            font-weight: 600;
                        }

                        li {

                            &.as-mega-menu-lists {
                                text-transform: capitalize;
                                text-align: left;
                                padding: 6px 0px;
                                font-size: $default-mega-menu-font-size !important;

                                &:first-of-type {
                                    padding-top: 15px !important;
                                }

                                a {
                                    svg {
                                        margin: 0 10px 0 0;        
                                    }
                                }

                                .as-mega-menu-redirect-links {    
                                    text-decoration: none !important;
                                    color: $default-text-color !important;
                                    padding: 5px 15px 5px 0px;
                                    border: 1px solid transparent !important;
                                    box-sizing: border-box !important;
                                    width: auto !important;
                                    margin: 5px 0 !important;
                                    background: transparent !important;
                                    border-radius: 5px !important;
                                    outline: none !important;
                                    font-size: $default-mega-menu-font-size !important;
                                    white-space: nowrap;
                                    transition: padding-left 0.2s ease-in-out;

                                    &:hover, &:focus {
                                        background-color: transparentize($default-theme-color,0.8) !important;
                                        color: $default-text-color-dark !important;
                                        outline: none !important;
                                        border: 1px solid $default-theme-color !important;
                                        padding-left: 10px;
                                    }

                                }

                            }

                        }

                    }

                    &:last-child {
                        border-right: none !important;
                    }

                }

            }

        }

        @keyframes displayMegaMenu {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0px);
            }
        }
        
        @-webkit-keyframes displayMegaMenu {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0px);
            }
        }
        
        @-moz-keyframes displayMegaMenu {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0px);
            }
        }

    }
}



@media only screen and (max-width: 560px) {

    .as-sidebar {
        width: $default-sidebar-width-small;
    
        ul {
            &.as-modules {
    
                li {
                    box-sizing: border-box;
    
                    &.as-modules-lists {

                        &.as-app-icon-wrapper {

                            span{
                                display: none;
                                span {
                                    display: none;
                                }
                            }
                        }
    
                        .as-module-icon {
                            font-size: 35px;
                        }
    
                        .as-modules-menu-link {
                            box-sizing: border-box;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            justify-items: center;
                            border: none;
                            border-radius: 0;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            outline: none;
                            height: 40px;
                            width: 100%;
                            margin: 20px auto;
                            padding: 6px 5px 0px 5px;
                            font-size: $default-menu-font-size;
                            background-color: transparent;
                            color: $default-theme-color;
                            text-decoration: none;
                            text-transform: uppercase;
                            cursor: pointer;
    
                            span {
                                &.as-menu-name {
                                    display: none;
                                }
                            }

                            svg {
                                z-index: 2;
                                &.as-local-svg {
                                    path, circle, rect, line, polygon {
                                        z-index: 2;
                                    }
                                }
                            }
    
                        }
    
                        button {
    
                            &.as-modules-menu-button {
                                box-sizing: border-box;
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                align-items: center;
                                justify-items: center;
                                border: none;
                                border-radius: 2px;
                                outline: none;
                                height: 80px;
                                width: 80px;
                                min-height: 80px;
                                min-width: 80px;
                                max-height: 80px;
                                max-width: 80px;
                                margin: 10px auto;
                                padding: 0px 5px;
                                font-weight: bold;
                                font-size: $default-menu-font-size;
                                background-color: transparent;
                                color: $default-theme-color;
                                text-transform: uppercase;
                                text-decoration: none;
                                cursor: pointer;
        
                                &:hover, &:focus, &:active {
                                    color: #ffffff;
                                    background: $default-theme-color;
                                    background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                                    box-shadow:  0px 5px 20px transparentize($default-theme-color, 0.5);
                                }
    
                                &.as-active-menu {
                                    color: #ffffff;
                                    background: $default-theme-color;
                                    background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                                    box-shadow:  0px 5px 20px transparentize($default-theme-color, 0.5);
                                }
    
                                &.as-opened-menu {
                                    color: #ffffff;
                                    background: $default-theme-color;
                                    background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                                    box-shadow:  0px 5px 20px transparentize($default-theme-color, 0.5);
    
                                    &:after {
                                        content: ' ';
                                        position: absolute;
                                        height: 0px;
                                        width: 0px;
                                        border: 10px solid transparent;
                                        border-right-color: $default-theme-color;
                                        right: -15px;
                                    }
    
                                    &:focus {
    
                                        &:after {
                                            content: ' ';
                                            position: absolute;
                                            height: 0px;
                                            width: 0px;
                                            border: 10px solid transparent;
                                            border-right-color: $default-theme-color;
                                            right: -15px;
                                        }
    
                                    }
    
                                }
    
                                span {
                                    &.as-menu-name {
                                        white-space: nowrap;
                                        font-size: $default-menu-font-size;
                                        text-decoration: none;
                                        text-transform: uppercase;
                                        font-weight: 600;
                                    }
                                }
    
                            }
    
                        }
    
                    }
    
                    .as-dropdown {
                        position: static !important;
    
                        .as-dropdown-content {
                            box-sizing: border-box;
                            border: 1px solid $default-border-color;
                            border-left: 2px solid $default-theme-color;
                            border-radius: 5px;
                            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                            z-index: 1;
                            padding: 15px;
                            top: 10px;
                            left: 115%;
                            height: 98%;
                            max-height: 96%;
                            width: 60vw;
    
                            a {
                                border-radius: 5px;
    
                                &:hover {
                                    color: $default-text-color-dark;
                                    background-color: $default-body-background-color;
                                }
                            }
    
                        }
    
                    }
    
                    
                }
            }
        }
    }

}




//  For extra extra small screens with width less than 425px.
@media only screen and (max-width: 560px) {

    .as-mega-menu {
        left: 50px !important;
    
        .as-mega-menu-content {
            width: 80% !important;
    
            .as-mega-menu-body {
                padding-bottom: 50px;
            }
    
        }
    }

}