//  ==========================================================================
//  Created Date    :   July 14, 2022
//  Created By      :   Himal Poudel
//  Description     :   This file consists POS's Event FP Print styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.paper-container {
    overflow: auto;
    width: 100%;
    height: auto;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .document-wrapper {
        width: max-content;
        padding: 40px 25px;
        border-radius: 2px;
        background-color: #ffffff;
        border: 2px solid transparentize($default-border-color, 0.5);
    }
}

