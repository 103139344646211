//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Draggable DIV styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-draggable-div {
    // display: none;
    z-index: 9;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 10px 20px transparentize(#000000, 0.6);
    text-align: center;
    height: auto;
    width: auto;
    min-height: 266px;
    min-width: 800px;
  }
  
  /*Drgable */
  
  .as-draggable-div {
    position: absolute;
    // resize: both;   //  Enable this to css resize
    overflow: auto;
  }
  
  .as-draggable-div-header {
    padding: 10px;
    cursor: move;
    z-index: 10;
    background: $default-theme-color;
    background: linear-gradient(140deg, lighten($default-theme-color, 15%), $default-theme-color, darken($default-theme-color, 5%));
    color: #ffffff;
  }
  
  /*Resizeable*/
  
  .as-draggable-div .resizer-right {
    width: 5px;
    height: 100%;
    background: transparent;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: e-resize;
  }
  
  .as-draggable-div .resizer-bottom {
    width: 100%;
    height: 5px;
    background: transparent;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: n-resize;
  }
  
  .as-draggable-div .resizer-both {
    width: 5px;
    height: 5px;
    background: transparent;
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: nw-resize;
  }
  
  /*NOSELECT*/
  
  .as-draggable-div * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }
  