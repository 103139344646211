//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Modal styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-modal {
    display: none;
    position: fixed;
    z-index: $default-modal-z-index;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: transparentize(#666666, 0.1);
}

.as-modal-content {
    position: relative;
    background-color: #ffffff;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
    padding: 0;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.2);
    -webkit-animation-name: displayModal;
    -webkit-animation-duration: 0.2s;
    animation-name: displayModal;
    animation-duration: 0.2s;
}

.as-modal-medium {
    width: 60%;
}

.as-modal-small {
    width: 40%;
}

.as-modal-x-small {
    width: 25%;
}

@keyframes displayModal {
    from {
        top:-300px;
        opacity:0;
    }
    to {
        top:15%;
        opacity:1;
    }
}

@-webkit-keyframes displayModal {
    from {
        top:-300px;
        opacity:0;
    }
    to {
        top:15%;
        opacity:1;
    }
}

.close {
    float: right;
    font-size: 20px;
    font-weight: bold;
    &:hover, &:focus {
        color: #000000;
        text-decoration: none;
        cursor: pointer;
    }
}

.as-modal-header {
    padding: 15px;
    background-color: #ffffff;
    border-bottom: 1px solid transparentize($default-border-color, 0.8);
}

.as-modal-title{
    font-weight: 600;
    font-size: 16px;
}

.as-modal-body {
    max-height: 65vh;
    padding: 15px;
    overflow: auto;
}

.as-modal-footer {
    text-align: right;
    padding: 15px;
    background-color: #ffffff;
    border-top: 1px solid transparentize($default-border-color, 0.8);
}









//  This is for modal popup inside body content.
.as-body-content-modal-container {
    box-sizing: border-box;
    position: fixed;
    top: 10px;
    left: 125px;
    right: 80px;
    height: 96.5vh;
    min-height: 96.5vh;
    max-height: 96.5vh;
    background-color: #ffffff;
    border: 1px solid $default-border-color;
    border-radius: 10px;
    padding: 20px 25px;
    z-index: 12;
    animation: openModal 0.4s;

    .as-body-content-modal {

        span {
            
            &.as-close-body-content-modal {
                position: absolute;
                right: -10px;
                top: -10px;
                color: #ffffff;
                background-color: $default-theme-color;
                height: 20px;
                width: 20px;
                font-size: 12px;
                line-height: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;

                &:hover {
                    background-color: darken($default-theme-color, 5%);
                }
            }

        }

        .as-body-content-modal-body {
            max-height: 90vh;
            overflow: auto;

        }

    }

}





//  This is for modal popup of dashboard.
.as-modal-popup-wrapper {
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    left: $default-sidebar-width;
    right: 0px;
    height: 100%;
    background: transparentize(#000000, 0.5);
    z-index: $default-dashboard-modal-z-index;
    animation: openModal 0.4s;
    backdrop-filter: blur(5px);

    &.sub-window {
        left: 0;
    }

    .as-modal-popup {
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 85vw;
        max-width: 85vw;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        border: 1px solid $default-border-color;
        box-shadow: 0px 10px 20px transparentize(#000000, 0.6);
        border-radius: 10px;
        padding: 15px;

        &.as-large-modal-popup {
            min-width: 88vw;
            max-width: 88vw;
            .as-modal-popup-body {
                min-height: 82vh;
                max-height: 82vh;
            }
        }
        &.as-small-modal-popup {
            min-width: 50vw;
            max-width: 50vw;
            .as-modal-popup-body {
                height: auto;
                min-height: 60vh;
                max-height: 60vh;
            }
        }
        &.as-x-small-modal-popup {
            min-width: 30vw;
            max-width: 30vw;
            .as-modal-popup-body {
                height: auto;
                min-height: auto;
                max-height: 35vh;
            }
        }
        &.as-full-scale-modal-popup {
            position: unset;
            top: unset;
            left: unset;
            min-width: unset;
            max-width: unset;
            transform: unset;
            width: 100%;
            height: 100%;
            border-radius: 0;
            .as-modal-popup-body {
                min-height: 84vh;
                max-height: 84vh;
                padding: 10px 10px 5px;
            }
        }

        button {
            &.as-close-modal-popup {
                margin: 0;
                position: absolute;
                right: -10px;
                top: -10px;
                border: 2px solid $default-theme-color;
                color: #ffffff;
                background-color: $default-theme-color;
                height: 20px;
                width: 20px;
                font-size: 12px;
                line-height: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;

                &:hover {
                    background-color: darken($default-theme-color, 5%);
                }

                &.full-scale {
                    right: 10px;
                    top: 10px;
                    border: 2px solid $default-border-color;
                    background-color: $default-border-color;
                    height: 45px;
                    width: 45px;
                    font-size: 18px;
                    line-height: 18px;
                }

            }
        }

        .as-modal-popup-header {
            padding: 5px 5px 15px;

            span {
                &.as-modal-popup-header {
                    color: $default-text-color-dark;
                    font-weight: 600;
                    font-size: 16px;
                }
            }
        }

        .as-modal-popup-body {
            overflow: auto;
            padding: 5px 10px;
            min-height: 70vh;
            max-height: 70vh;
            .as-modal-popup-wrapper {
                left: 0px;
                box-shadow: 0 0 0 100vmax transparentize(#000000, 0.5);
                backdrop-filter: blur(5px);
            }
        }

        .as-modal-popup-footer {
            padding: 10px 5px 5px;

            button {
                border-width: 2px;
            }

            &.space-between {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

    }

}

@keyframes openModal {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes openModal {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@-moz-keyframes openModal {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}


@media only screen and (max-width: 560px) {
    //  This is for modal popup inside body content.
    .as-body-content-modal-container {
        top: 0px !important;
        left: 55px !important;
        right: 5px !important;
        height: 96.5vh !important;
        min-height: 96.5vh !important;
        max-height: 96.5vh !important;
        z-index: 999999 !important;

        .as-body-content-modal {
            padding-top: 15px !important;

            span {
                
                &.as-close-body-content-modal {
                    top: 5px;
                    right: 5px;
                    height: 25px;
                    width: 25px;
                }
    
            }

            .as-body-content-modal-body {
                max-height: 80vh;
                overflow: auto;    
            }

        }
    }


    //  This is for modal popup of dashboard.
    .as-modal-popup-wrapper {
        top: 0px;
        left: 50px;
        right: 0;

        .as-modal-popup {
            min-width: unset;
            max-width: unset;
            height: 98% !important;
            width: 98% !important;
            border-radius: 5px;
            padding: 10px;
            overflow: auto !important;

            .as-modal-popup-header {
                padding: 10px 5px 10px;

                span {
                    &.as-modal-popup-header {
                        font-size: 14px;
                    }
                }
            }

            .as-modal-popup-body {
                overflow: unset !important;
                overflow: auto !important;
                padding: 5px 10px;
                min-height: unset;
                max-height: unset;
                height: 85% !important;
            }

            &.as-large-modal-popup, &.as-medium-modal-popup, &.as-small-modal-popup, &.as-x-small-modal-popup {
                min-width: unset;
                max-width: unset;
                width: 98%;
                height: 98%;
                overflow: unset !important;
                
                .as-modal-popup-header {
                    padding: 5px 5px 10px;
    
                    span {
                        &.as-modal-popup-header {
                            font-size: 14px;
                        }
                    }
                }

                .as-modal-popup-body {
                    min-height: unset;
                    max-height: unset;
                    height: 85% !important;
                    overflow: auto !important;
                }
            }

        }

    }

}




//  For POS Take Order Page
.as-take-order-wrapper {
    position: fixed;
    top: 0;
    left: 100px;
    right: 40px;
    height: 100%;
    background: $default-body-background-color;
    margin: 0;
    z-index: $default-take-order-z-index;

    .as-take-order-container {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        border: 1px solid $default-border-color;
        border-radius: 10px;
        background: #ffffff;
        padding: 25px;
        overflow: auto;

        .as-take-order-header {
            width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            .as-acc-date {
                color: $default-theme-color;
            }

            .as-outlet-heading {
                span {
                    color: $default-text-color-dark;
                    font-size: 20px;
                    font-weight: 800;
                    text-align: center;
                }
            }
            .as-user-div {
                text-align: right;
                color: $default-theme-color;
            }
        }
    }
}




//  Slider Pop Up
.as-slider-popup-backdrop {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: $default-sidebar-width;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: $default-modal-z-index;
    background: transparentize(#000000, 0.8);
    backdrop-filter: blur(5px);

    &.sub-window {
        left: 0;
    }

    .as-slider-popup-container {
        box-sizing: border-box;
        height: 100%;
        min-width: 400px;
        max-width: 400px;
        padding: 0px 0px 25px 0px;
        background: #ffffff;
        box-shadow: 1px 0px 10px 10px transparentize(#000000, 0.9);
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-animation: showSliderPopUp 0.4s;
        -moz-animation: showSliderPopUp 0.4s;
        animation: showSliderPopUp 0.4s;

        .as-slider-popup-header {
            padding: 25px 15px 20px 15px;
            color: $default-text-color-dark;
            font-weight: 600;
            font-size: 16px;
            width: 100%;
            background: #ffffff;
            position: sticky;
            top: 0;
            z-index: 4;
        }

        .as-slider-popup-body {
            padding: 5px 15px 15px;
        }

        @keyframes showSliderPopUp {
            0% {
                transform: translateX(-400px);
            }
            100% {
                transform: translateX(0px);
            }
        }
        
        @-webkit-keyframes showSliderPopUp {
            0% {
                transform: translateX(-400px);
            }
            100% {
                transform: translateX(0px);
            }
        }
        
        @-moz-keyframes showSliderPopUp {
            0% {
                transform: translateX(-400px);
            }
            100% {
                transform: translateX(0px);
            }
        }

    }

    &.right {
        width: 100%;
        height: 100%;
        right: 0;
        left: 0;

        .as-slider-popup-container {
            position: absolute;
            right: 0;
            -webkit-animation: showSliderPopUpFromRight 0.4s;
            -moz-animation: showSliderPopUpFromRight 0.4s;
            animation: showSliderPopUpFromRight 0.4s;
    
            @keyframes showSliderPopUpFromRight {
                0% {
                    transform: translateX(400px);
                }
                100% {
                    transform: translateX(0px);
                }
            }
            
            @-webkit-keyframes showSliderPopUpFromRight {
                0% {
                    transform: translateX(400px);
                }
                100% {
                    transform: translateX(0px);
                }
            }
            
            @-moz-keyframes showSliderPopUpFromRight {
                0% {
                    transform: translateX(400px);
                }
                100% {
                    transform: translateX(0px);
                }
            }
    
        }
    }
    
}

@media only screen and (max-width: 560px) {

    //  Slider Pop Up
    .as-slider-popup-backdrop {
        left: $default-sidebar-width-small;

        .as-slider-popup-container {
            min-width: 90%;
            max-width: 90%;
            overflow-x: auto;
            overflow-y: auto;

            .as-slider-popup-body {
                padding: 10px;
            }
        }
        
    }
}