//  ==========================================================================
//  Created Date    :   July 29, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Attachments styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.choose-input{
    font-size: $default-font-size;
    font-style: italic;
    color: $default-text-color;
    text-align: center;
    cursor: pointer;
    padding: 24px 16px;
    transition: all ease-in-out 120ms;
    background: $default-body-background-color;
    border-radius: 5px;
    width: 100%;

    &:hover, &:focus {
        background: darken($default-body-background-color, 5%);
    }
}

.input-file-attachment{
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:focus, &:focus-within {
        + label.choose-input {
            outline: 2px solid $default-green-color !important;
        }
    }
}

.upload-animation{
	animation: blinkingText 1.5s infinite;
}

@keyframes blinkingText{
	0% {
        color: #888;
    }
	49% {
        color: #888;
    }
	50% {
        color: transparent;
    }
	99% {
        color:#888;
    }
	100% {
        color: #888;
    }
}