@import "variables.scss";

.page-number-container {
    button {
        border: 1px solid transparentize($default-border-color, 0.5);
        background-color: lighten($default-border-color, 15%);
        border-radius: 2px;
        padding: 5px 10px;
        
        &.go-to-first-index-btn, &.back-index-btn, &.forward-index-btn, &.go-to-last-index-btn {
            border-color: $default-border-color;
            background-color: lighten($default-border-color, 8%);
            color: $default-text-color-dark;
        }

        &.page-number-btn {
            color: $default-text-color;
            &.active {
                border-color: $default-theme-color;
                background-color: $default-theme-color;
                color: #ffffff;
            }
        }
    }
}