//  ==========================================================================
//  Created Date    :   January 25, 2021
//  Created By      :   Himal Poudel
//  Description     :   This file consists Message styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-message {
    width: 100%;
    padding: 5px 15px;
    margin: 5px 0;
    border: 1px solid $default-border-color;
    color: darken($default-text-color, 10%);
    background: lighten($default-text-color, 40%);
    font-size: $default-font-size;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &.success {
        color: darken($default-green-color, 10%);
        border-color: $default-green-color;
        background: lighten($default-green-color, 40%);
    }

    &.warning {
        color: darken($default-yellow-color, 10%);
        border-color: $default-yellow-color;
        background: lighten($default-yellow-color, 40%);
    }

    &.info {
        color: darken($default-blue-color, 10%);
        border-color: $default-blue-color;
        background: lighten($default-blue-color, 40%);
    }

    &.error {
        color: darken($default-red-color, 10%);
        border-color: $default-red-color;
        background: lighten($default-red-color, 40%);
    }

    &.light-bg {
        color: unset;
        border-color: $default-border-color;
        background: lighten($default-border-color, 15%);
    }

    button {
        &.close {
            position: absolute;
            padding: 0;
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
        }
    }

    &.list-message {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;

        .title-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
        }

        .message-list {
            clear: both;
            ul {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 5px;
                margin: 0;
                padding: 0 20px;
            }
        }
    }

}