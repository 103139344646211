@import url(variables.scss);

.card {
    width: 100%;
    background-color: #ffffff;
    --_card-spacing: 1rem;
    border-radius: calc(var(--_card-spacing) * 0.5);

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: var(--_card-spacing);
        padding-bottom: 0;

        .icon-title-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;

            .icon {
                font-size: 1.5rem;
                color: $default-theme-color;

                &.blue {
                    color: $default-blue-color;
                }
                &.green {
                    color: $default-green-color;
                }
                &.yellow {
                    color: $default-yellow-color;
                }
                &.red {
                    color: $default-red-color;
                }
                &.purple {
                    color: $default-purple-accent;
                }
                &.light {
                    color: $default-text-color-light;
                }
            }

            .title-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                span {
                    font-weight: normal;
                    &.title {
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: $default-text-color-dark;
                    }
                    &.desc {
                        font-size: 0.9rem;
                        color: $default-text-color-light;
                    }
                }
            }
        }

        .card-header-contents {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 0.15rem;
        }
    }

    .card-body {
        padding: var(--_card-spacing);

        &.no-padding {
            padding: 0;
            padding-bottom: var(--_card-spacing);
        }
    }

    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--_card-spacing);
        padding-top: 0;

        .left-footer, .right-footer {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 0.15rem;
        }

        .left-footer {
            justify-content: flex-start;
        }

        .right-footer {
            justify-content: flex-end;
        }
    }

    &.sticky-card {
        position: sticky;
        
        &.top {
            top: 7.2rem;
            z-index: 4;
        }

        &.show-border {
            box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.1);
            border-radius: 0;
        }
    }

}