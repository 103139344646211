//  ==========================================================================
//  Created Date    :   April 20, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Collapsible Card styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-collapsible {
    background-color: transparentize($default-body-background-color, 0.8);
    cursor: pointer;
    padding: 15px;
    width: 100%;
    border: 1px solid transparentize($default-border-color, 0.8);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: left;
    outline: none;
    font-size: 15px;
    color: $default-text-color;
    &::after {
        content: '\002B';
        font-weight: bold;
        float: right;
        margin-left: 5px;
    }
}

.as-collapsible-active {
    &::after {
        content: "\2212";
    }
}
  
.as-collapsible-content {
    padding: 0 15px;
    max-height: auto;
    overflow: auto;
    transition: max-height 0.2s ease-out;
    background-color: #ffffff;
    color: $default-text-color;
    border: 1px solid $default-border-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dark-bg {
    background-color: $default-text-color;
    color: #ffffff;
    border: 1px solid $default-border-color;
}

.blue-bg {
    background-color: transparentize($default-blue-color, 0.8);
    color: darken($default-blue-color ,15%);
    border: 1px solid transparentize($default-blue-color, 0.8);
}

.green-bg {
    background-color: transparentize($default-green-color, 0.8);
    color: darken($default-green-color ,15%);
    border: 1px solid transparentize($default-green-color, 0.8);
}

.yellow-bg {
    background-color: transparentize($default-yellow-color, 0.8);
    color: darken($default-yellow-color  ,15%);
    border: 1px solid transparentize($default-yellow-color, 0.8);
}

.red-bg {
    background-color: transparentize($default-red-color, 0.8);
    color: darken($default-red-color ,15%);
    border: 1px solid transparentize($default-red-color, 0.8);
}

.light-blue-bg {
    background-color: transparentize($default-light-blue-color, 0.8);
    color: darken($default-light-blue-color ,15%);
    border: 1px solid transparentize($default-light-blue-color, 0.8);
}