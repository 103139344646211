//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Body Content styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import './variables.scss';
@import "mixins/mixins.scss";

.as-body-content {
    box-sizing: border-box;
    display: block;
    padding: 0px 15px 0px 105px;
    height: 100%;
    min-height: 94vh;
    position: relative;
    background: $default-body-background-color;
    z-index: $default-body-z-index;

    &.sub-window {
        padding: 0px 15px 0px 15px;
    }
}

.as-body-content-top-first-row {
    box-sizing: border-box;
    background: $default-body-background-color;
    height: 45px;
    margin: 0 -15px 0 -20px;
    padding: 20px 15px 0 20px;
    z-index: $default-body-content-top-container-z-index;
    color: $default-theme-color;
    font-size: 14px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.as-body-content-top-block {

    button {
        &.as-header-redirect-btn, &.as-actions-dropdown-button {
            display: inline-block;
            padding: 0;
            margin: 0;
            text-align: right;
            cursor: pointer;
            white-space: nowrap;
            text-decoration: none;
            user-select: none;
            -webkit-font-smoothing: antialiased;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            position: relative;
            overflow: hidden;
            border: none;
            // outline: none;
            box-shadow: none;
            color: $default-theme-color;
            text-transform: uppercase;
            font-weight: 600;
            font-family: inherit;
            background: transparent;
        }
        &.as-header-redirect-btn {
            margin-right: 20px;
        }
        &.as-actions-dropdown-button {

        }
    }

    .as-dropdown-content {
        box-sizing: border-box;
        border: 1px solid $default-border-color;
        border-top: 2px solid $default-theme-color;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: $default-actionbar-dropdown-z-index;
        right: -10px;
        top: 10px;
        border-radius: 5px;
        padding: 10px;
        -webkit-animation-name: displayProfileDropDown;
        -webkit-animation-duration: .4s;
        animation-name: displayProfileDropDown;
        animation-duration: .4s;

        &:before {
            content: '';
            float: right;
            margin-top: -30px;
            height: 0;
            width: 0;
            border: 10px solid transparent;
            border-bottom-color: $default-theme-color;
        }

        a {
            border-radius: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            svg {
                &.as-local-svg {
                    width: 2em;
                    padding-right: 10px;
                }
            }

            &:hover {
                color: $default-text-color-dark;
                background-color: $default-body-background-color;
            }
        }

        @keyframes displayProfileDropDown {
            0%{
                transform: translateY(50px);
                opacity: 0;
            }
            // 50%{
            //     transform: translateY(-10px);
            //     opacity: 0.5;
            // }
            100%{
                transform: translateY(0px);
                opacity: 1;
            }
        }

        @-webkit-keyframes displayProfileDropDown {
            0%{
                transform: translateY(50px);
                opacity: 0;
            }
            // 50%{
            //     transform: translateY(40px);
            //     opacity: 0.5;
            // }
            100%{
                transform: translateY(0px);
                opacity: 1;
            }
        }

    }


}

.as-body-title-container {
    box-sizing: border-box;
    background: $default-body-background-color;
    margin: 0 -15px 0 -20px;
    padding: 0 15px 0 20px;
    z-index: $default-body-title-z-index;
    position: sticky;
    position: -webkit-sticky;
    top: 45px;
    clear: both;
}

// .sub-window {
//     .as-body-title-container {
//         margin: 0 -15px 0 -15px !important;
//     }
// }

.as-body-header{
    font-size: 16px;
    color: $default-text-color-dark;
    margin: 0;
    padding: 0;
}

.page-header-actions{
  text-align: right;
}

.with-shadow{
  box-shadow: 2px 2px 8px #cccccc;
}

.as-no-space {
    margin: 0 !important;
    padding: 0 !important;
}

.as-no-margin {
    margin: 0 !important;
}

.as-no-margin-top {
    margin-top: 0 !important;
}

.as-no-margin-right {
    margin-right: 0 !important;
}

.as-no-margin-bottom {
    margin-bottom: 0 !important;
}

.as-no-margin-left {
    margin-left: 0 !important;
}

.as-no-padding {
    padding: 0 !important;
}

.as-no-padding-top {
    padding-top: 0 !important;
}

.as-no-padding-right {
    padding-right: 0 !important;
}

.as-no-padding-bottom {
    padding-bottom: 0 !important;
}

.as-no-padding-left {
    padding-left: 0 !important;
}


//  REDIRECT BUTTON
.as-page-redirect-button-wrapper {
    float: right;
    text-align: right;
}

.as-page-redirect-button {
    box-sizing: border-box;
    width: 27px;
    height: 27px;
    border: 2px solid transparentize(#000000, 0.98);
    border-radius: 50%;
    background: transparentize(#000000, 0.6);
    box-shadow:  0px 4px 10px transparentize($default-text-color-light, 0.75);
    color: #ffffff;
    outline: none !important;
    cursor: pointer;
    margin: 0 10px 5px 0;
    font-size: small;
    padding: 0;

    &:hover {
        border: 2px solid transparentize(#000000, 0.95);
        background: transparentize(#000000, 0.5);
    }

    &.as-active-page-button {
        border: 2px solid transparentize($default-theme-color, 0);
        background: linear-gradient(140deg, lighten($default-theme-color, 15%), $default-theme-color, darken($default-theme-color, 5%));
        box-shadow:  0px 5px 20px transparentize($default-theme-color, 0.5);
        color: #ffffff;

        &:hover {
            background: linear-gradient(140deg, lighten($default-theme-color, 10%), $default-theme-color, darken($default-theme-color, 10%));
        }

    }

    &.red {
        border: 2px solid transparentize($default-red-color, 0);
        background: linear-gradient(140deg, lighten($default-red-color, 15%), $default-red-color, darken($default-red-color, 5%));
        box-shadow:  0px 5px 20px transparentize($default-red-color, 0.5);
        color: #ffffff;

        &:hover {
            background: linear-gradient(140deg, lighten($default-red-color, 10%), $default-red-color, darken($default-red-color, 10%));
        }

    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &.no-drop {
        box-shadow: none !important;
    }

    &.with-svg {
        
        //  For Local SVG
        svg {
            width: 80% !important;
            height: auto !important;

            &.as-local-svg {
                path, circle, rect, line, polygon, ellipse {
                    fill: #fff !important;
                }
            }
        }

        &.as-active-page-button {
            svg {
                &.as-local-svg {
                    path, circle, rect, line, polygon, ellipse {
                        fill: #fff !important;
                    }
                }
            }
        }
    }
}

.as-page-redirect-name {
    font-size: 8px;
    font-weight: 600;
    color: $default-text-color;
    clear: right;
}


//  Main Content
// .as-body-content-main-content {
//     div {
//         &.row {
//             padding: 0px 15px 0px 105px;
//             &:nth-child(1) {
//                 padding: 0px 15px 0px 105px;
//             }
//         }
//     }
// }


//  For Watermark
.as-watermark-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 60px;
    right: 20px;
    width: 100%;
    pointer-events: none;

    .as-watermark-container {
        background-color: #b108089d;
        max-width: 300px;
        border-radius: 5px;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.8;

        span {
            &.as-watermark {
        
                // color: transparentize($default-text-color-light, 0.6);
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                user-select: none;
                -webkit-font-smoothing: antialiased;
                -webkit-user-drag: none;
                -webkit-user-select: none;
                pointer-events: none;
    
                // animation: blinkWatermark 1s infinite;
    
            }
    
            @keyframes blinkWatermark {
                0% {
                    color: $default-text-color-dark;
                }
                50% {
                    color: $default-text-color-light;
                }
                100% {
                    color: $default-text-color-dark;
                }
            }
    
        }

    }


}

//  Page Footer
.as-page-footer {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 15px 10px 115px;
    margin: 0;
    background: transparent;
    background: transparentize($default-body-background-color, 0.2);
    // backdrop-filter: blur(1px);
    font-size: small;
    z-index: $default-page-footer-z-index;
    // position: sticky;
    // bottom: 0;
    pointer-events: none;

    div {
        margin: 0 !important;
        padding: 0 !important;
        color: $default-text-color-light !important;
        font-size: 11.5px;
        span {
            font-size: 11.5px;
            &.as-highlighted-text {
                text-transform: uppercase;
                font-weight: 600;
                color: $default-text-color-dark !important;
            }
        }
    }
}


@media only screen and (max-width: 560px) {
    .as-body-content {
        padding: 25px 10px 50px 60px;

        &.sub-window {
            padding: 25px 10px 50px 10px;
        }
    }
    
    .as-body-content-top-first-row {
        margin: 0 -15px 0 -20px;
        padding: 20px 0 0 0;

        span {
            font-size: 12px;
        }
    }

    .as-body-title-container {
        margin: 0 -10px 0 -20px;
        padding: 0 10px 0 0;
    }

    .as-page-footer {
        display: none !important;
    }

    
    .as-body-content-top-first-row {
        padding-top: 0px;
        position: unset;
        background-color: $default-body-background-color;
        top: 0;
        margin: 0;
        height: auto;
        z-index: $default-body-content-top-container-z-index;
    }   

    .as-body-content-top-block {
        font-size: 12px;
        position: unset !important;
    }

    .as-body-title-container {
        position: unset;
        margin: 0;
        padding-right: 0 !important;
        vertical-align: top !important;
    }

    .as-body-header {
        padding: 5px 0;
    }

    .as-page-redirect-button-wrapper {
        padding: 0;
        margin: 0;
    }

    .as-body-header {
        display: block !important;
        width: 100% !important;
    }

}

.react-datepicker-popper {
    z-index: 4 !important;
}

//  For Page Not Authorized
.as-page-not-authorized {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    overflow-y: auto;

    svg {
        &.as-local-svg {
            &.as-lock-image {
                height: 100px;
                width: auto;
                display: block;
    
                path {
                    &.as-lock-path{
                        fill: $default-red-color !important;
                    }
                }
            }
        }
    }

    span {
        &.as-authorization-message, &.as-authorization-info {
            font-size: x-large;
            color: $default-text-color-dark;
            padding: 25px 0 5px;
            font-weight: bolder;
            text-align: center;
            background: transparent;
        }
        &.as-authorization-info {
            font-size: medium;
            color: $default-text-color-light;
            padding: 0 0 25px;
        }
    }

    button {
        &.as-redirect-to-home-btn {
            display: inline-block;
            padding: 12px 24px;
            border-radius: 4px;
            text-align: center;
            cursor: pointer; 
            white-space: nowrap;
            text-decoration: none;
            user-select: none;
            -webkit-font-smoothing: antialiased;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            font-size: $default-font-size;
            letter-spacing: 0px;
            font-weight: 600;
            position: relative;
            overflow: hidden;
            border: 1px solid $default-theme-color;
            color: #ffffff;
            background: $default-theme-color;
            background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
            box-shadow:  0px 4px 8px transparentize($default-theme-color, 0.6);
            font-size: 14px;

            span {
                padding-left: 10px;
            }
        }
    }

    .as-authorization-logo {
        padding-top: 100px;
        font-size: 14px;
        color: $default-text-color-light;
        letter-spacing: 0;

        span {
            color: $default-theme-color;
            font-weight: 600;
        }
    }

}


//  For Page Not Authorized
.as-page-not-found {
    box-sizing: border-box;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 50px 0 105px;
    overflow-y: auto;

    span {
        &.as-404 {
            font-size: 80px;
            font-weight: bolder;
            letter-spacing: 0px;
            color: $default-text-color-dark;
            font-family: 'Nexa Bold', 'NexaDemo-Bold', 'Roboto';
            padding: 0;
            margin: 0;

            span {
                color: $default-theme-color;
            }
        }
    }

    span {
        &.as-page-error-message {
            font-size: large;
            color: $default-text-color;
            padding: 25px 0 5px;
            font-weight: bolder;
            text-align: center;
        }
        &.as-info {
            font-size: medium;
            color: $default-text-color-light;
            padding: 0 0 25px;
            font-weight: bolder;
            text-align: center;
        }
    }

    button {
        &.as-redirect-to-home-btn {
            display: inline-block;
            padding: 12px 24px;
            border-radius: 4px;
            text-align: center;
            cursor: pointer; 
            white-space: nowrap;
            text-decoration: none;
            user-select: none;
            -webkit-font-smoothing: antialiased;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            font-size: $default-font-size;
            letter-spacing: 0px;
            font-weight: 600;
            position: relative;
            overflow: hidden;
            border: 1px solid $default-theme-color;
            color: #ffffff;
            background: $default-theme-color;
            background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
            box-shadow:  0px 4px 8px transparentize($default-theme-color, 0.6);
            font-size: 14px;

            span {
                padding-left: 10px;
            }
        }
    }

    .as-logo {
        padding-top: 100px;
        font-size: 14px;
        color: $default-text-color-light;
        letter-spacing: 0;

        span {
            span {
                color: $default-theme-color;
                font-weight: 600;

            }
        }
    }

}


//  For Equal Height Columns
.as-eq-col-wrapper {
    min-height: 80vh;
    max-height: 80vh;
    background: #ffffff;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    overflow: auto;
    -webkit-overflow-scrolling: auto !important;
    -ms-overflow-style: auto !important;
    // -ms-overflow-style: -ms-autohiding-scrollbar !important;
    // -ms-overflow-style: scrollbar !important;

    &.left-content {
        border-right: 1px solid transparentize($default-border-color, 0.5);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .has-padding {
            padding: 20px 10px;
        }

    }

    &.right-content {
        border-left: 1px solid transparentize($default-border-color, 0.5);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .has-padding {
            padding: 20px 10px;
        }

    }
}

.left-col {
    padding: 0 !important;
    padding-left: 10px !important;
}

.right-col {
    padding: 0 !important;
    padding-right: 10px !important;
}


.highlighted-wrapper {
    background-color: $default-body-background-color;
    border-radius: 10px;
    padding: 10px;

    input {
        border: 0px;
    }
}


@media only screen and (max-width: 560px) {
    .as-page-not-authorized {
        padding: 0 10px 0 60px;
    }

    .as-page-not-found {
        padding: 0 10px 0 60px;
    }
}

@media only screen and (max-width: 768px) {
    .as-eq-col-wrapper {
        &.left-content {
            border-right: 0;
            border-bottom: 1px solid transparentize($default-border-color, 0.5);
            border-top-right-radius: 5px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        &.right-content {
            border-left: 0;
            border-top: 1px solid transparentize($default-border-color, 0.5);
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .left-col, .right-col {
        padding: 0 10px 0 10px !important;
    }

}

//  Change Outline color of focusable elements
* {
    outline-color: $default-green-color !important;
}