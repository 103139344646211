@import url(variables.scss);

.multi-rooms-rg-container {
    --_grid-gap: 0.5rem;
    display: grid;
    // grid-template-columns: 3fr minmax(15rem, 6fr) minmax(15rem, 3fr);
    grid-template-columns: 1fr;
    justify-items: stretch;
    align-items: start;
    gap: calc(var(--_grid-gap) * 3);
    position: relative;

    .rg-col {
        display: grid;
        gap: var(--_grid-gap);

        &.date-input-col {
            display: grid;
            grid-template-rows: 1fr;
            // grid-template-columns: 1fr minmax(5rem, 1fr);
            grid-template-columns: 3fr 1fr;
            align-content: start;
            justify-content: space-between;
            align-items: start;
            justify-items: stretch;

            .date-time-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 0;
                
                --_toggle-btn-size: 3rem;
    
                .date-time-input-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
    
                    .date-input-with-toggle-btn {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
        
                        button {
                            &.date-toggle-btn {
                                background-color: $default-theme-color;
                                color: #fff;
                                align-self: stretch;
                                width: var(--_toggle-btn-size);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid $default-theme-color;
                                border-top-left-radius: 5px;
                                font-size: 14px;
                                margin: 0;
                                padding: 0;
        
                                &.arrival {
                                    background-color: #003893;
                                    border-color: #003893;
                                }
                                &.departure {
                                    background-color: #DC143C;
                                    border-color: #DC143C;
                                    border-top-left-radius: 0;
                                    border-bottom-left-radius: 5px;
                                }
        
                                &.time-toggle-btn {
                                    width: calc(var(--_toggle-btn-size) * 0.75);
                                    &.arrival {
                                        border-color: transparent;
                                        border-top: 1px solid $default-border-color;
                                        border-radius: 0;
                                        background-color: transparent;
                                        color: #003893;
                                        -webkit-box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
                                        box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
                                    }
    
                                    &.departure {
                                        border-color: transparent;
                                        border-bottom: 1px solid $default-border-color;
                                        border-radius: 0;
                                        background-color: transparent;
                                        color: #DC143C;
                                        -webkit-box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
                                        box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
                                    }
                                }
                            }
                        }
            
                        input {
                            &.as-form-component {
                                &.date-input {
                                    border-left: 1px solid transparent;
                                    border-right: 1px solid transparent;
                                    border-radius: 0;
                                    min-width: 8rem;
        
                                    &.as-required {
                                        border-color: $default-red-color;
                                    }
    
                                    &.arrival {
                                        border-bottom: 1px solid transparent;
                                        &.as-required {
                                            border-bottom-color: $default-red-color;
                                        }
                                    }
    
                                    &.departure {
                                        border-top: 1px solid transparent;
                                        &.as-required {
                                            border-top-color: $default-red-color;
                                        }
                                    }
                                }
                                &.time-input {
                                    border-radius: 0;
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                    border-left: 1px solid transparent;
                                    width: 5rem;
        
                                    &.as-required {
                                        border-top-color: $default-red-color;
                                        border-left-color: $default-red-color;
                                    }
    
                                    &.arrival {
                                        border-bottom: 1px solid transparent;
                                        border-bottom-right-radius: 0;
                                        &.as-required {
                                            border-bottom-color: $default-red-color;
                                        }
                                    }
    
                                    &.departure {
                                        border-top: 1px solid transparent;
                                        border-top-right-radius: 0;
                                        &.as-required {
                                            border-top-color: $default-red-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }

        &.room-and-guest-col {
            display: grid;
            grid-template-columns: 1fr;
            align-content: start;
            justify-content: space-between;
            align-items: start;
            justify-items: stretch;

            .room-and-guest-col__row-1 {
                display: grid;
                grid-template-columns: 1fr;
                align-content: start;
                justify-content: space-between;
                align-items: start;
                justify-items: stretch;
                gap: var(--_grid-gap);

                .room-and-guest-col__row-1__col {
                    .occupancy-wrapper {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        align-content: start;
                        justify-content: space-between;
                        align-items: start;
                        justify-items: stretch;
                        gap: var(--_grid-gap);

                        .occupancy-input-label {
                            flex: 1;
                            position: relative;

                            label {
                                margin: 0 !important;
                                position: absolute;
                                top: 50%;
                                left: var(--_grid-gap);
                                transform: translateY(-50%);
                            }

                            input {
                                padding-left: calc(var(--_grid-gap) * 3);
                                -moz-appearance: textfield;
                
                                /* Chrome, Safari, Edge, Opera */
                                &::-webkit-outer-spin-button,
                                &::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }
                            }
                        }

                        // &.a-c-i {
                        //     .occupancy-input-label {
                        //         input {
                        //             padding-left: calc(var(--_grid-gap) * 5);
                        //         }
                        //     }
                        // }
                    }
                }
            }

            .room-and-guest-col__row-2 {
                display: grid;
                grid-template-columns: 1fr;
                align-content: start;
                justify-content: space-between;
                align-items: start;
                justify-items: stretch;
                gap: var(--_grid-gap);

                label {
                    margin-bottom: unset !important;
                }

                .info-wrapper {
                    padding: var(--_grid-gap) 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: var(--_grid-gap);

                    span {
                        text-wrap: pretty;
                    }
                }
            }
        }

        &.rate-col {
            justify-content: stretch;
            flex-direction: column;
            
            .rate-col__row-1 {
                display: flex;
                justify-content: space-between;
                gap: var(--_grid-gap);
                width: 100%;

                .rate-col__row-1__col {
                    flex: 1;
                    max-width: 100%;

                    &.guest-details-btn-col {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        flex: unset;
                    }

                    .selected-rate-details {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: var(--_grid-gap);

                        input {
                            &.selected-rate-value-input {
                                font-size: 1rem;
                                border-color: transparentize($default-border-color, 0.75);
                                box-shadow: none;
                                
                                &:first-child {
                                    color: $default-text-color-dark;
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: 0;
                                    border-right: 0;
                                }
                                &:last-child {
                                    color: $default-green-color;
                                    text-align: right;
                                    border-top-left-radius: 0;
                                    border-bottom-left-radius: 0;
                                    border-left: 0;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.multiple-rooms-modal {
    &.as-x-small-modal-popup {
        .as-modal-popup-body {
            max-height: 50vh !important;
        }
    }
}