#body-TZ {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
  }

  #code-container-TZ {
    position: relative;
    width: 1000px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  #code-header-TZ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  #code-header-TZ h2 {
    margin: 0;
    font-size: 18px;
  }

  #copy-button-TZ {
    padding: 8px 15px;
    background-color: #333;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }

  #code-TZ {
    background-color: #f5f5f5;
    padding: 10px;
    margin: 0;
    font-family: Consolas, monospace;
    font-size: 14px;
    white-space: pre-wrap;
  }