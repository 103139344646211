//  =============================================================================
//  Created Date    :   April 16, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists of variables that are used
//                      on entire scss files.
//                      (Editor can add new variables or change the value
//                      of existing variables but not the variables name.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  =============================================================================


//  For Screen Size
$xxsScreen : 425px !default;    //  Width less than 425px
$xsScreen : 768px !default;     //  Width less than 768px
$smScreen : 768px !default;     //  Width greater than 768px
$mdScreen : 992px !default;     //  Width greater than 992px
$lgScreen : 1200px !default;    //  Width greater than 1200px


//  Colors
//  Dark Theme Colors: #e01f1f, #191d1d, rgb(255, 18, 107);
//  Orange: rgb(255, 74, 0)
//  Main Theme: #0ad26e
//  Noted Color(Blue): #149ddd
$default-theme-color: #149ddd;
$default-gradient-theme-color: linear-gradient(140deg, lighten($default-theme-color, 15%), $default-theme-color, darken($default-theme-color, 5%));
$default-body-background-color: #f1f2f2;
$default-text-color: #555555;
$default-text-color-light: #888888;
$default-text-color-dark: #121B34;
$default-border-color: #CCCCCC;
$default-blue-color: rgb(30, 144, 255);
$default-red-color: rgb(244, 67, 54);
$default-green-color: rgb(76, 175, 80);
$default-yellow-color: rgb(255, 165, 30);
$default-light-blue-color: rgb(77, 208, 255);
$default-gray-color: rgb(128, 128, 128);
$default-sidebar-background-color: #ffffff;

$default-vacant-color: rgb(76, 175, 80);
$default-reserved-color: #888888;
$default-arrival-color: #0ad26e;
$default-in-house-color: rgb(244, 67, 54);
$default-in-house-dirty-color: rgb(248, 124, 8);
$default-dirty-color: rgb(252, 178, 18);
$default-departing-color: rgb(22, 150, 255);
$default-out-of-order-color: #542b5c;
$default-out-of-service-color: #964B00;
$default-management-block-color: #b723fc;
$default-todays-arrival-color:#00008B;
$default-todays-departure-color:#800000;
$default-stay-over-color:#8B8000;

$default-menu-category-highlight-color: rgb(76, 175, 80);
$default-attendant-highlight-color: rgb(89, 32, 245);
$default-dashboard-due-in-color: #0ad26e;
$default-dashboard-due-out-color: rgb(163, 29, 14);
$default-dashboard-occupancy-color: rgb(244, 66, 54); 
$default-dashboard-room-position-color: rgb(10, 94, 219); 
$default-dashboard-do-not-disturb-color: rgb(244, 66, 54);
$default-dashboard-recent-reservation-color: rgb(136, 136, 136);
$default-dashboard-waitlist-reservation-color: rgb(255, 165, 30);
$default-dashboard-cancelled-reservation-color: rgb(221, 0, 0);
$default-dashboard-no-show-reservation-color: rgb(0, 0, 0);
$default-dashboard-pick-drop-color: rgb(255, 165, 30);
$default-dashboard-scanty-color: rgb(18, 103, 168);
$default-dashboard-vip-guest-color: rgb(91, 59, 218);
$default-dashboard-birthday-color: rgb(167, 138, 82);
$default-dashboard-anniversary-color: rgb(134, 9, 32);
$default-dashboard-guest-message-color: rgb(18, 144, 216);
$default-dashboard-guest-complaint-color: rgb(224, 24, 24);
$default-dashboard-lost-items-color: rgb(66, 55, 11);
$default-dashboard-found-items-color: rgb(14, 180, 96);
$default-dashboard-complimentary-rooms-color: rgb(30, 175, 14);
$default-dashboard-house-use-rooms-color: rgb(216, 156, 26);
$default-dashboard-house-count-color: rgb(191, 23, 23);
$default-dashboard-pax-count-color: rgb(61, 100, 228);
$default-guest-registration-card-primary-theme: #149DDD;
$default-guest-registration-card-secondary-theme: #CCCCCC;
$default-purple-accent: rgb(89, 32, 245);

//  For React Select
$default-theme-color-background: transparentize($default-theme-color, 0.8);


//  Fonts
$default-font-family: "Roboto", "Helvetica", "Arial", sans-serif;


//  Typography
$default-font-size: 12px;
$default-menu-font-size: 9px;
$default-mega-menu-font-size: 14px;
$default-actionbar-font-size: 10px;
$default-mega-table-font-size: 12px;
$default-order-table-font-size: 12px;
$default-order-table-font-size-for-touch-screen: 11px;
$default-report-font-size: 12px;
$default-wizard-button-font-size: 12px;
$default-font-size-for-min-space: 0.85rem;
$default-hotel-position-font-size: 11px;


//  Margins
$default-menu-lists-padding: 0px;       //  Space between menus (Front Office, POS, ...)
$default-report-table-head-padding: 15px 10px;     //  Padding for Report Table (thead)
$default-report-table-body-padding: 10px 10px;     //  Padding for Report Table (tbody)


//  Borders
$default-mega-table-row-radius: 10px;
$default-order-table-row-radius: 5px;

//  Component Width
$default-sidebar-width: 90px;
$default-sidebar-width-small: 50px;

//  Z-index
$default-body-z-index: 0;
$default-body-content-header-z-index: 2;
$default-body-content-top-container-z-index: 6;
$default-body-title-z-index: 5;
$default-login-page-z-index: 2;
$default-login-form-sm-screen-z-index: 13;
$default-sidebar-z-index: 2;
$default-sidebar-header-z-index: 3;
$default-mega-menu-z-index: 10;
$default-mega-menu-content-z-index: 20;
$default-menu-dropdown-z-index: 3;
$default-actionbar-z-index: 2;
$default-actionbar-dropdown-z-index: 3;
$default-mega-table-header-z-index: 2;
$default-order-table-header-z-index: 2;
$default-mega-table-dropdown-z-index: 1;
$default-page-loader-z-index: 999;
$default-overlay-z-index: 4;
$default-actionbar-btn-sm-screen-z-index: 10;
$default-alert-z-index: 14;
$default-modal-z-index: 12;
$default-dashboard-modal-z-index: 12;
$default-clock-in-password-z-index: 13;
$default-popover-z-index: 2;
$default-take-order-z-index: 12;
$default-floating-container-z-index: 4;
$default-page-footer-z-index: 1;
$default-loader-from-top-z-index: 100;
$default-toast-z-index: 9999;


//  Export Variables    (These variables can be used in inline styling.)
:export {
    defaultThemeColor: $default-theme-color;
    defaultGreenColor: $default-green-color;
    defaultRedColor: $default-red-color;
    defaultYellowColor: $default-yellow-color;
    defaultBlueColor: $default-blue-color;
    defaultTextColor: $default-text-color;
    defaultTextColorLight: $default-text-color-light;
    defaultTextColorDark: $default-text-color-dark;
    defaultBodyBackgroundColor: $default-body-background-color;
    defaultThemeColorBackground: $default-theme-color-background;
    defaultBorderColor: $default-border-color;
    defaultFontSize: $default-font-size;
    defaultFontSizeForMinSpace: $default-font-size-for-min-space;
    defaultReservedColor: $default-reserved-color;
    defaultArrivalColor: $default-arrival-color;
    defaultInHouseColor: $default-in-house-color;
    defaultVacantColor: $default-vacant-color;
    defaultOutOfOrderColor: $default-out-of-order-color;
    defaultOutOfServiceColor: $default-out-of-service-color;
    defaultManagementBlockColor: $default-management-block-color;
    defaultDashboardDueOutColor: $default-dashboard-due-out-color;

    defaultVacantColorFaded: transparentize($default-vacant-color, 0.5);
    defaultReservedColorFaded: transparentize($default-reserved-color, 0.5);
    defaultArrivalColorFaded: transparentize($default-arrival-color, 0.5);
    defaultInHouseColorFaded: transparentize($default-in-house-color, 0.5);
    defaultOutOfOrderColorFaded: transparentize($default-out-of-order-color, 0.5);
    defaultOutOfServiceColorFaded: transparentize($default-out-of-service-color, 0.5);
    defaultManagementBlockColorFaded: transparentize($default-management-block-color, 0.5);
    defaultDueOutColorFaded: transparentize($default-dashboard-due-out-color, 0.5);
    defaultTextColorBg: transparentize($default-text-color, 0.98);

    defaultBackgroundColorBlue: transparentize($default-blue-color, 0.90);
    defaultBackgroundColorGreen: transparentize($default-green-color, 0.90);
    defaultBackgroundColorRed: transparentize($default-red-color, 0.90);
    defaultBackgroundColorYellow: transparentize($default-yellow-color, 0.90);
    defaultBackgroundColorText: transparentize($default-text-color, 0.90);

    defaultBackgroundColorBlue50: transparentize($default-blue-color, 0.50);
    defaultBackgroundColorGreen50: transparentize($default-green-color, 0.50);
    defaultBackgroundColorRed50: transparentize($default-red-color, 0.50);
    defaultBackgroundColorYellow50: transparentize($default-yellow-color, 0.50);
    defaultBackgroundColorText50: transparentize($default-text-color, 0.50);
    
    defaultBlueColorLight25: lighten($default-blue-color, 25%);
    defaultGreenColorLight25: lighten($default-green-color, 25%);
    defaultRedColorLight25: lighten($default-red-color, 25%);
    defaultYellowColorLight25: lighten($default-yellow-color, 25%);
    defaultTextColorLight25: lighten($default-text-color, 25%);

    defaultGRCPrimaryColor: $default-guest-registration-card-primary-theme;
    defaultGRCPrimaryColor40: transparentize($default-guest-registration-card-primary-theme, 0.40);
    defaultGRCPrimaryColor50: transparentize($default-guest-registration-card-primary-theme, 0.50);
    defaultGRCSecondaryColor: $default-guest-registration-card-secondary-theme;
    defaultGRCSecondaryColor50: transparentize($default-guest-registration-card-secondary-theme, 0.50);

    defaultSidebarWidth: $default-sidebar-width;
    defaultSidebarWidthSmall: $default-sidebar-width-small;

    reportHeaderRowBg: darken($default-theme-color, 15%);
    reportFirstLevelRowBg: lighten($default-theme-color, 35%);
    reportSecondLevelRowBg: lighten($default-text-color-light, 25%);
    reportThirdLevelRowBg: lighten($default-text-color-light, 35%);
    reportFourthLevelRowBg: lighten($default-text-color-light, 45%);
    reportRowRedBg: lighten($default-red-color, 35%);
    reportRowRedTextColor: darken($default-red-color, 35%);
    reportRowGreenBg: lighten($default-green-color, 35%);
    reportRowGreenTextColor: darken($default-green-color, 35%);
    reportRowYellowBg: lighten($default-yellow-color, 35%);
    reportRowYellowTextColor: darken($default-yellow-color, 35%);

    defaultSidebarZIndex: $default-sidebar-z-index;
}
