//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Clock In/Out PIN login styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-clock-in-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $default-body-background-color;
    z-index: $default-clock-in-password-z-index;
    display: flex;
    justify-content: center;
    align-items: center;

    .as-clock-in-password-container {
        box-sizing: border-box;
        padding: 25px 15px;
        border-radius: 5px;
        width: 300px;
        overflow: hidden;
        border: 1px solid $default-body-background-color;
        background: $default-body-background-color;
        background: linear-gradient(140deg, lighten($default-body-background-color,4%), lighten($default-body-background-color,2%), $default-body-background-color, $default-body-background-color, darken($default-body-background-color, 1%));
        box-shadow:  5px 10px 20px 0px transparentize(#000000, 0.5);
        animation: openPINContainer 0.4s;
        -webkit-animation: openPINContainer 0.4s;

        .as-clock-in-header {
            padding: 10px;

            .as-clock-in-title-wrapper {
                text-align: center;

                button {
                    &.as-go-back {
                        float: left;
                        text-align: left;
                        margin-top: 5px;
                        border: none;
                        background: transparent;
                        cursor: pointer;
                        color: $default-theme-color;
                        width: 20px;
                    }
                }
                span {
                    &.as-clock-in-title {
                        margin-left: -20px;
                        text-align: center;
                        color: $default-theme-color;
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
            }

            input {
                &.as-input-pin {
                    width: 100%;
                    display: block;
                    margin-top: 15px;
                    padding: 5px;
                    outline: none;
                    border: none;
                    text-align: center;
                    font-size: 30px;
                    font-weight: bolder;
                    letter-spacing: 5px;
                    background: transparent;
                    color: $default-text-color-dark;
                    line-height: 0;

                    &::placeholder {
                        letter-spacing: normal;
                        font-size: 18px;
                        font-weight: normal;
                    }
                    &::-ms-input-placeholder {
                        letter-spacing: normal;
                        font-size: 18px;
                        font-weight: normal;
                    }
                    &:-ms-input-placeholder {
                        letter-spacing: normal;
                        font-size: 18px;
                        font-weight: normal;
                    }

                }
            }

        }

        .as-clock-in-body {
            display: block;
            width: 100%;
            padding: 5px;
            text-align: center;

            button {
                &.as-pin-btn {
                    box-sizing: border-box;
                    height: 65px;
                    width: 65px;
                    outline: none;
                    border: none;
                    border-radius: 50%;
                    padding: 0;
                    margin: 5px;
                    color: $default-text-color-dark;
                    font-size: 18px;
                    line-height: 18px;
                    text-align: center;
                    background: $default-body-background-color;
                    background: linear-gradient(145deg, darken($default-body-background-color, 5%), lighten($default-body-background-color,2%));
                    box-shadow: 5px 5px 10px darken($default-body-background-color, 15%), 
                                -5px -5px 10px lighten($default-body-background-color, 10%);
                    cursor: pointer;

                    &:active {
                        color: $default-theme-color;
                        box-shadow: inset 5px 5px 10px darken($default-body-background-color, 15%), 
                                    inset -5px -5px 10px lighten($default-body-background-color, 5%);
                    }

                }
            }

        }

    }



    @keyframes openPINContainer {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @-webkit-keyframes openPINContainer {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @-moz-keyframes openPINContainer {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
}