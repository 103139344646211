/*
  To convert modal div to full screen.
*/
.as-modal-full-screen{
  width: 100% !important;
  height: 100% !important;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.as-modal-full-screen>.as-modal-body{
  min-height: 85vh !important;
  max-height: 85vh !important;
}

/*******USED IN BODY OF DIV WHICH HAS FULL SCREEN MODE*********/
.as-modal-body-full-screen{
  min-height: 50vh !important;
  max-height: 50vh !important;
}

/****************/

.as-modal {
    display: none;
    position: fixed;
    z-index: 12;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(128, 128, 128, 0.9);
  }
  
  .as-modal-content {
    position: relative;
    background-color: white;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
    padding: 0;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    -webkit-animation-name: displayModal;
    -webkit-animation-duration: 0.2s;
    animation-name: displayModal;
    animation-duration: 0.2s;
  }
  
  .as-modal-medium {
    width: 60%;
  }
  
  .as-modal-small {
    width: 40%;
  }
  
  .as-modal-x-small {
    width: 25%;
  }
  
  @keyframes displayModal {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 15%;
      opacity: 1;
    }
  }
  .close {
    float: right;
    font-size: 20px;
    font-weight: bold;
  }
  .close:hover, .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .as-modal-header {
    padding: 15px;
    background-color: white;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  }
  
  .as-modal-title {
    font-weight: 600;
    font-size: 16px;
  }
  
  .as-modal-body {
    max-height: 65vh;
    padding: 15px;
    overflow: auto;
  }
  
  .as-modal-footer {
    text-align: right;
    padding: 15px;
    background-color: white;
    border-top: 1px solid rgba(128, 128, 128, 0.2);
  }

  @media only screen and (max-width: 900px) {
    /*
      This is done so that small model does not hide, it maintain atleast 60% of width
    */
    .as-modal-content{
      min-width: 60% !important;
    }
  }