//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Form Components styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-form-wrapper {
    margin-bottom: 0px;

    &::after {
        content: " ";
        clear: both;
        display: table;
    }

}

label{
    display: inline-block;
    font-weight: 500;
    font-size: $default-font-size;
    margin-bottom: 4px !important;
    color: darken($default-text-color-light, 5%);
}

.row {
    &.min-space {
        label {
            font-size: $default-font-size-for-min-space;
        }
    }
}

input, select, textarea,.select-component {
    &.as-form-component {
        width: 100%;
        padding: 5px 10px;
        font-size: $default-font-size;
        line-height: 1.5;
        color: $default-text-color;
        background-color: #ffffff;
        border: 1px solid $default-border-color;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
        box-shadow: inset 0 1px 1px transparentize(#000000, 0.925);
        -webkit-transition: border-color ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s;
        -webkit-transition: border-color ease-in-out .15s;
        transition: border-color ease-in-out .15s;
    }
    &.as-required {
        border-color: #ff0000;
        background-image: url(../../Icons/svg/exclamation-circle-red.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 15px;
        background-position-x: 98%;
        // For Select
        border: 1px solid #ff0000;
        border-radius: 6px;
    }
    &.as-success {
        border-color: $default-green-color;
        background-image: url(../../Icons/svg/check-circle-green.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 15px;
        background-position-x: 98%;
    }
    &.as-warning {
        border-color: $default-yellow-color;
        background-image: url(../../Icons/svg/exclamation-circle-orange.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 15px;
        background-position-x: 98%;
    }
}

textarea {
    &.as-form-component{
        font-family: $default-font-family;
        resize: vertical;
    }
}

.as-input-x-small{
    font-size: 11px !important;
}

.as-input-small{
    font-size: 12px !important;
}

.as-input-large{
    padding: 8px 16px !important;
    font-size: 16px !important;
}

input{
    &[type=submit], &[type=button]{
        .as-form-component {
            display: block;
            width: auto;
            padding: 5px 10px;
            font-size: $default-font-size;
            line-height: 1.5;
            color: #fff;
            background-color: $default-theme-color;
            border: 1px solid $default-theme-color;
            border-radius: 4px;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        }
    }
}

.auto-width {
    width: auto !important;
}

label, span {
    &.as-required {
        position: relative !important;
        &::after {
            content: " *" !important;
            color: #ff0000 !important;
            font-size: small;
            position: absolute !important;
            padding-left: 2px !important;
            margin-top: -5px !important;

        }
    }
}

// Checkbox and Radio Button
input {
    &[type=checkbox], &[type=radio] {
        -webkit-appearance: none;
        -moz-appearance: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        height: 21px;
        margin: 0;
        cursor: pointer;
        border: 1px solid $default-border-color;
        background: #ffffff;
        transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
        &::after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
        }
        &:checked {
            background : $default-theme-color;
            border-color : $default-theme-color;
            transition : .6s;
            &.blue {
                background : $default-blue-color;
                border-color : $default-blue-color;
            }
            &.green {
                background : $default-green-color;
                border-color : $default-green-color;
            }
            &.yellow {
                background : $default-yellow-color;
                border-color : $default-yellow-color;
            }
            &.red {
                background : $default-red-color;
                border-color : $default-red-color;
            }
            &.light {
                background : $default-border-color;
                border-color : $default-border-color;
            }
            &.purple {
                background : $default-purple-accent;
                border-color : $default-purple-accent;
            }
        }
        &:disabled {
            background : #ffffff;
            cursor: not-allowed;
            opacity: 0.6;
            + label {
                cursor: not-allowed;
                opacity: 0.6;
            }
        }
        &:hover {
            &:not(:checked) {
                &:not(:disabled) {
                    border-color: $default-theme-color;
                }
            }
        }
        &:not(.switch) {
            width: 21px;
            &::after {
                opacity: 0;
            }
            &:checked {
                opacity: 1;
                &::after {
                    opacity: 1;
                }
            }
        }
        + label {
            font-size: $default-font-size;
            line-height: 20px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 5px;
        }
        
    }
}

input {
    &[type=checkbox] {
        &:not(.switch) {
            border-radius: 2px;
            &::after {
                width: 5px;
                height: 10px;
                border: 2px solid #ffffff;
                border-top: 0;
                border-left: 0;
                left: 6px;
                top: 2px;
                transform: rotate(var(--rot, 0deg));
            }
            &:disabled {
                &:checked {
                    border-color: transparentize($default-theme-color, 0.4) !important;
                    background: transparentize($default-theme-color, 0.4) !important;
                }
            }
            &:checked {
                --rot: 45deg;
            }
        }
        &.switch {
            width: 40px;
            border-radius: 15px;
            &::after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--afterbackground, $default-border-color);
                transform: translateX(var(--tranx, 0));
            }
            &:checked {
                --afterbackground: #ffffff;
                --tranx: 19px;
                &::after {
                    background: #ffffff;
                }
            }
            &:disabled {
                &:not(:checked) {
                    &::after {
                        opacity: 0.6;
                    }
                }
            }
        }
        &:hover, &:focus {
            -webkit-box-shadow: 0px 0px 0px 8px rgba(0,0,0,0.05) !important;
            -moz-box-shadow: 0px 0px 0px 8px rgba(0,0,0,0.05) !important;
            box-shadow: 0px 0px 0px 8px rgba(0,0,0,0.05) !important;
            cursor: pointer !important;
        }
    }
    &[type=radio] {
        border-radius: 50%;
        &::after {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #ffffff;
            top: 2px;
            left: 2px;
            opacity: 0;
            transform: scale(var(--s, 0.7));
        }
        &:checked {
            --s: .5;
        }
    }
}

.as-error-message {
    font-size: smaller;
    display: none;
    color: #ff0000;
    padding: 5px 5px;
}

.as-hide-element {
    display: none;
}

input {
    &[type=checkbox] {
        &.small {
            margin-top: 2px;
            margin-right: 5px;
            height: 15px !important;
            width: 15px !important;
            padding: 0 !important;
    
            &:not(.switch) {
                width: 15px !important;
                
                &::after {
                    width: 3px;
                    height: 5px;
                    left: 4px;
                    top: 2px;
                }
            }
        }
    }
}



//  React Datepicker Input
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100% !important;

    .react-datepicker__input-container {
        position: relative;
        display: inline-block;
        width: 100%;

        button {
            margin: 1px;

            &.react-datepicker__close-icon {
                cursor: pointer;
                background-color: transparent;
                border: 0;
                outline: 0;
                padding: 0px 10px;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                content: 'x';

                &::after {
                    cursor: pointer;
                    background-color: transparent;
                    color: $default-border-color;
                    border-radius: 50%;
                    height: 16px;
                    width: 16px;
                    padding: 0;
                    margin: 0;
                    font-size: $default-font-size;
                    font-weight: normal;
                    line-height: $default-font-size;
                    text-align: center;
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            &:hover {
                &::after {
                    background-color: $default-border-color;
                    color: #ffffff;
                }
            }
        }
    }
}


.react-datepicker {
    font-family: $default-font-family !important;
    font-size: 12px !important;
    background-color: #ffffff !important;
    color: $default-text-color !important;
    border: 1px solid $default-border-color !important;
    border-radius: 5px !important;

    button {
        &.react-datepicker__navigation--previous {
            border-right-color: $default-border-color;
            &:hover {
                border-right-color: darken($default-border-color,25%);
            }
        }
        &.react-datepicker__navigation--next {
            border-left-color: $default-border-color;
            &:hover {
                border-left-color: darken($default-border-color,25%);
            }
        }
    }

    .react-datepicker__month-container {
        .react-datepicker__header {
            background-color: $default-body-background-color;
            color: $default-text-color-dark;

            .react-datepicker__current-month {
                color: $default-text-color-dark !important;
            }

            .react-datepicker__header__dropdown {
                .react-datepicker__month-dropdown-container {
                    .react-datepicker__month-read-view {
                        color: $default-text-color-dark;
                        .react-datepicker__month-read-view--down-arrow {
                            border-top-color: $default-border-color;
                            &:hover {
                                border-top-color: darken($default-border-color,25%);
                            }
                        }
                    }
                    .react-datepicker__month-dropdown {
                        background-color: $default-body-background-color;
                        .react-datepicker__month-option {
                            color: $default-text-color-dark;
                            &:hover {
                                background-color: transparentize($default-theme-color, 0.9);
                                color: $default-theme-color;
                            }
                        }
                    }
                }
                .react-datepicker__year-dropdown-container {
                    .react-datepicker__year-read-view {
                        color: $default-text-color-dark;
                        .react-datepicker__year-read-view--down-arrow {
                            border-top-color: $default-border-color;
                            &:hover {
                                border-top-color: darken($default-border-color,25%);
                            }
                        }
                    }
                    .react-datepicker__year-dropdown {
                        background-color: $default-body-background-color;
                        .react-datepicker__year-option {
                            color: $default-text-color-dark;
                            &:hover {
                                background-color: transparentize($default-theme-color, 0.9);
                                color: $default-theme-color;
                            }
                        }
                    }
                }
            }

            .react-datepicker__day-names {
                .react-datepicker__day-name {
                    color: $default-theme-color !important;
                }
            }

        }
        .react-datepicker__week {
            .react-datepicker__day {
                color: $default-text-color;
                border-radius: 5px;
                &:hover {
                    background-color: $default-text-color-light !important;
                    color: #ffffff !important;
                }
            }

            .react-datepicker__day--selected {
                background-color: $default-theme-color;
                color: #ffffff !important;
                &:hover {
                    background-color: $default-theme-color !important;
                }
            }

            .react-datepicker__day--disabled {
                background-color: transparent;
                color: $default-text-color-light !important;
                border: none !important;
                cursor: not-allowed;
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                &:hover, &:focus {
                    background-color: transparent !important;
                    color: $default-text-color-light !important;
                }
            }

            .react-datepicker__day--keyboard-selected {
                background-color: $default-text-color-light !important;
                color: #ffffff !important;
            }
        }
    }

}

.react-datepicker--time-only {
    border-color: $default-border-color !important;
}

.react-datepicker__header {
    background-color: $default-body-background-color !important;
    color: $default-text-color-dark !important;
    border-color: $default-border-color !important;
    .react-datepicker-time__header {
        color: $default-text-color-dark !important;
        font-size: $default-font-size;
    }
    
}
.react-datepicker__time {

    .react-datepicker__time-box {
        
        ul {
            &.react-datepicker__time-list {
                li {
                    &.react-datepicker__time-list-item {
                        color: $default-text-color !important;
                        &:hover {
                            background-color: transparentize($default-theme-color, 0.9) !important;
                            color: $default-text-color-dark !important;
                        }
                    }
                    &.react-datepicker__time-list-item--keyboard-selected {
                        background-color: $default-text-color-light !important;
                        color: #ffffff !important;
                        &:hover {
                            background-color: $default-text-color-light !important;
                            color: #ffffff !important;
                        }
                    }
                    &.react-datepicker__time-list-item--selected {
                        background-color: $default-theme-color !important;
                        color: #ffffff !important;
                        &:hover {
                            background-color: $default-theme-color !important;
                            color: #ffffff !important;
                        }
                    }
                }
            }
        }
    }
}

.react-datepicker-popper {
    z-index: 2 !important;
}

//  For Time Container Inside DatePicker
.react-datepicker__input-time-container {
    margin: 0 !important;
    padding: 5px 10px 10px !important;
    .react-datepicker-time__caption {
        color: $default-text-color-dark !important;
    }
    .react-datepicker-time__input-container {
        margin: 0 !important;
        padding-left: 15px !important;
        .react-datepicker-time__input {
            margin: 0 !important;
            input {
                &.react-datepicker-time__input {
                    border: 1px solid $default-border-color !important;
                    border-radius: 5px !important;
                    padding: 5px 5px !important;
                    color: $default-text-color !important;
                    cursor: pointer !important;
                }
            }
        }
    }
}


 //  Display tooltip
 .tooltip-container {
    position: relative;
    &:has(.show-tooltip:focus) {
        --tooltip-container-space: 5px;
        &::before {
            content: attr(data-tooltip);
            position: absolute;
            background-color: $default-text-color;
            color: #fff;
            white-space: nowrap;
            padding: var(--tooltip-container-space) calc(var(--tooltip-container-space) * 2);
            border-radius: calc(var(--tooltip-container-space) * 0.5);
            border: 1px solid $default-text-color;
            bottom: 0;
            bottom: calc(var(--tooltip-container-space) * -4);
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }
        &::after {
            content: '';
            position: absolute;
            padding: 0;
            bottom: var(--tooltip-container-space);
            margin: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 0;
            width: 0;
            border: var(--tooltip-container-space) solid transparent;
            border-bottom-color: $default-text-color;
            z-index: 1;
        }
    }
    // .show-tooltip {
    // }
 }


 .input-with-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    .input-wrapper {
        flex: 1;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 10px;
        font-size: $default-font-size;
        background-color: $default-text-color-light;
        border: 2px solid transparent;
        border-radius: 5px;
        color: #fff;
        font-weight: bold;

        &.as-input-x-small {
            font-size: 12px;
        }

        &.green {
            background-color: $default-green-color;
        }

        &.red {
            background-color: $default-red-color;
        }

        &.yellow {
            background-color: $default-yellow-color;
        }

        &.theme {
            background-color: $default-theme-color;
        }

        &.show {
            display: none;
            &.on-large-screen {
                @media only screen and (max-width: 1200px) {
                    display: block;
                }
            }
            &.on-medium-screen {
                @media only screen and (max-width: 992px) {
                    display: block;
                }
            }
            &.on-small-screen {
                @media only screen and (max-width: 768px) {
                    display: block;
                }
            }
            &.on-x-small-screen {
                @media only screen and (max-width: 480px) {
                    display: block;
                }
            }
        }
    }
 }


.checkbox-input-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &.end {
        justify-content: flex-end;
    }
    
    .input-group-divider {
        height: 20px;
        width: 1px;
        background-color: transparentize($default-border-color, 0.5);
        // background-color: transparentize($default-border-color, 0.5);
    }
}

.label-input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    &.vertical {
        flex-direction: column;
    }

    label {
        margin: 0 !important;
    }
}

.switch-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    label {
        margin: 0 !important;

        &.active {
            background-color: unset !important;
            color: $default-theme-color;
        }
    }
}

.label-action-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    margin: 0;
    padding: 0;

    .label-wrapper {
        flex: 1;
    }

    .action-wrapper {
        position: absolute;
        right: 0;
        bottom: 2px;

        button {
            &.label-action-btn {
                margin-top: 0;
                margin-bottom: 0;
                padding: 3px 4px;
                font-weight: 500;
                font-size: 0.9rem;
                line-height: 0;
                color: $default-theme-color;
                background-color: transparentize($default-theme-color, 0.85);
                border: 1px solid transparentize($default-theme-color, 0.85);
                border-radius: 3px;

                &:hover {
                    color: #fff;
                    background-color: transparentize($default-theme-color, 0.25);
                }
            }
        }

        .switch-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

            label {
                margin: 0 !important;

                &.active {
                    background-color: unset !important;
                    color: $default-theme-color;
                }
            }
        }

        input {
            &.switch {
                margin: 0 !important;
                padding: 0 !important;
                width: 30px;
                height: 16px;
                border-radius: 15px;

                &::after {
                    width: 10px;
                    height: 10px;
                }
                &:checked {
                    --tranx: 15px;
                }
            }
        }
    }
}


.input-action-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-wrapper {
        flex: 1;
    }

    .action-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}