//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Toast/Snackbar styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-toast{
    position: fixed;
    right: 0;
    margin: 5px 0;
    padding: 15px 50px 15px 15px;
    max-width: 400px;
    min-width: 300px;
    min-height: auto;
    height: auto;
    overflow: hidden;
    background: transparentize($default-text-color, 0.8);
    color: transparentize(#000000, 0.2);
    text-align: center;
    border: none;
    border-radius: 5px;
    box-shadow: 0 5px 15px transparentize(#000000, 0.65);
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: $default-toast-z-index;
    text-align: left;
    color: #ffffff;
}

.as-toast-remove {
    background: none;
    border: none;
    box-shadow: none;
    user-select: none;
    outline: none;
    color: transparentize($color: #ffffff, $amount: 0.5);
    font-size: large;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &:hover {
        color: #ffffff;
    }
}

.as-toast-show {
    opacity: 1;
    visibility: visible;
    animation: showToast 0.5s ease forwards;
    -webkit-animation: showToast 0.5s ease forwards;
    -moz-animation: showToast 0.5s ease forwards;
}

@keyframes showToast {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-15px);
    }
}
@-webkit-keyframes showToast {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-15px);
    }
}
@-moz-keyframes showToast {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-15px);
    }
}

.as-toast-hide {
    display: none;
    animation: hideToast 0.5s ease forwards;
}
@keyframes hideToast {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(100%);
    }
}
@-webkit-keyframes hideToast {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(100%);
    }
}
@-moz-keyframes hideToast {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(100%);
    }
}

.as-toast-success{
    background: transparentize($default-green-color, 0.14);
    // border-left: 5px solid $default-green-color;
    // background-image: url(../../Icons/svg/check-circle-green.svg);
    // background-repeat: no-repeat;
    // background-position: left;
    // background-position-y: center;
    // background-size:25px;
    // background-position-x: 10px;
}
// .as-toast-success::before {
//     content: "Success";
//     font-size: large;
//     color: $default-green-color;
//     display: block;
//     text-align: left;
//     padding-bottom: 10px;
// }

.as-toast-info{
    background: transparentize($default-light-blue-color, 0.14);
    color: $default-text-color-dark;
}

.as-toast-warning{
    background: transparentize($default-yellow-color, 0.14);
}
  
.as-toast-error {
    background: transparentize($default-red-color, 0.14);
}