@import './variables.scss';

.as-actionbar {
    box-sizing: border-box;
    position: fixed;
    margin: 0;
    top: 0;
    right: 0;
    border: none;
    height: 100%;
    width: 40px;
    background: $default-sidebar-background-color;
    box-shadow: 0px 0px 4px 0px #cccccc;
    z-index: $default-actionbar-z-index;

    ul {
        &.as-actions {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: center;

            li {
                &.as-actions-lists {
                    position: relative;
                    padding: 15px 0px;
                    text-align: center;
                    cursor: pointer;
                    transition: all .4s ease-in-out;
                }
                &:first-child {
                    padding-top: 20px;
                    padding-bottom: 50px;
                }
                &:hover {
                    color: $default-theme-color;
                }

                button {
                    &.as-actions-dropdown-button {
                        background-color: transparent;
                        color: $default-text-color;
                        border: none;
                        padding: 5px;
                        cursor: pointer;
                        outline: none;

                        &:hover {
                            color: $default-theme-color;
                        }
                    }
                }

                .as-dropdown-content {
                    box-sizing: border-box;
                    border: 1px solid $default-border-color;
                    border-right: 2px solid $default-theme-color;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    z-index: $default-actionbar-dropdown-z-index;
                    right: 50px;
                    top: -30px;
                    border-radius: 5px;
                    padding: 10px;
                    -webkit-animation-name: displayDropDown;
                    -webkit-animation-duration: .2s;
                    animation-name: displayDropDown;
                    animation-duration: .2s;

                    &:before {
                        content: '';
                        float: right;
                        margin-right: -30px;
                        margin-top: 0px;
                        height: 0;
                        width: 0;
                        border: 10px solid transparent;
                        border-left-color: $default-theme-color;
                    }

                    a {
                        border-radius: 5px;

                        &:hover {
                            color: $default-text-color-dark;
                            background-color: $default-body-background-color;
                        }
                    }

                    @keyframes displayDropDown {
                        0%{
                            right: 40px;
                            opacity: 0;
                        }
                        50%{
                            right: 80px;
                            opacity: 0.5;
                        }
                        100%{
                            right: 40px;
                            opacity: 1;
                        }
                    }
            
                    @-webkit-keyframes displayDropDown {
                        0%{
                            right: 40px;
                            opacity: 0;
                        }
                        50%{
                            right: 80px;
                            opacity: 0.5;
                        }
                        100%{
                            right: 40px;
                            opacity: 1;
                        }
                    }

                }
            }
        }
    }
}



@media only screen and (max-width: 425px) {

    .as-actionbar {
        position: fixed !important;
        right: unset !important;
        left: 0 !important;
        margin-left: 0 !important;
        bottom: 0 !important;
        height: auto !important;
        width: auto !important;
        padding: 0 !important;
        top: unset !important;
        box-shadow: none !important;
        overflow: visible;
        background: $default-sidebar-background-color !important;

        ul {
            &.as-actions {

                li {
                    box-sizing: border-box;

                    &:first-child {
                        padding: unset !important;
                        margin: unset !important;
                        padding: 15px 5px 15px 4px !important;
                        border-top: 1px solid lighten($default-border-color, 15%);
                    }

                    button {
                        &.as-actions-dropdown-button {
                            margin: 0;
                            bottom: 0 !important;
                            color: $default-theme-color !important;
                            border: none;
                            padding: 5px;
                            cursor: pointer;
                            outline: none;
                            font-size: 20px;
                            height: 40px;
                            width: 40px;
                            line-height: 5px;
                            border-radius: 50%;
                            background: $default-sidebar-background-color;
                            box-shadow: 0px 4px 10px darken($default-sidebar-background-color, 20%), 
                                        -0px -4px 10px lighten($default-sidebar-background-color, 0%);
                            z-index: $default-actionbar-btn-sm-screen-z-index;
    
                            &:hover {
                                color: $default-theme-color;
                            }
                        }
                    }
    
                    .as-dropdown-content {
                        box-sizing: border-box;
                        border: 1px solid $default-border-color;
                        border-bottom: 2px solid $default-theme-color;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                        z-index: $default-actionbar-dropdown-z-index;
                        right: unset;
                        left: 5px;
                        top: -250px;
                        border-radius: 5px;
                        padding: 10px;
                        -webkit-animation-name: displayDropDown;
                        -webkit-animation-duration: .2s;
                        animation-name: displayDropDown;
                        animation-duration:  .2s;
    
                        &:after {
                            content: ' ';
                            float: left;
                            margin-right: 0px;
                            margin-bottom: -40px;
                            margin-top: 10px;
                            height: 0;
                            width: 0;
                            border: 10px solid transparent;
                            border-top-color: $default-theme-color;
                        }

                        &:before {
                            content: '';
                            border: none !important;
                        }
    
                        a {
                            border-radius: 5px;
    
                            &:hover {
                                color: $default-text-color-dark;
                                background-color: $default-body-background-color;
                            }
                        }
    
                        @keyframes displayDropDown {
                            0%{
                                top: -250px;
                                opacity: 0;
                            }
                            50%{
                                top: -280px;
                                opacity: 0.5;
                            }
                            100%{
                                top: -250px;
                                opacity: 1;
                            }
                        }
                
                        @-webkit-keyframes displayDropDown {
                            0%{
                                bottom: 40px;
                                opacity: 0;
                            }
                            50%{
                                bottom: 80px;
                                opacity: 0.5;
                            }
                            100%{
                                bottom: 40px;
                                opacity: 1;
                            }
                        }
    
                    }
                }
            }
        }
    }
    

}