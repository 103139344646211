@import "variables.scss";

.ts-detail-container-bg {
    --ts-spacing: 15px;
    background-color: #fafafa;
    border-radius: 10px;
    padding: var(--ts-spacing);

    .ts-detail-container {
        background-color: #ffffff;
        border-radius: 5px;
        padding: var(--ts-spacing);
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        flex-direction: column;
        gap: calc(var(--ts-spacing) * 0.75);
        overflow: hidden;
    
        .ts-details-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: calc(var(--ts-spacing) * 0.5);
    
            .ts-data {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 2px;
    
                span {
                    &.title {
                        font-size: 12px;
                        color: $default-text-color-light;
                        text-transform: uppercase;
                    }
                    &.value {
                        font-size: 14px;
                        color: $default-text-color-dark;
                        text-transform: uppercase;

                        &.vehicle-number {
                            font-size: 18px;
                            font-weight: bold;
                            font-family: 'Courier New', Courier, monospace;
                            color: $default-green-color;
                        }
                    }
                }

                &:nth-child(2) {
                    justify-content: flex-end;
                    align-items: flex-end;
                }
            }

            &.align-center {
                align-items: center;
            }

            &.location-detail-container {
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: var(--ts-spacing) calc(var(--ts-spacing) * 3);
                gap: 10px;

                .location-wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    align-items: flex-start;

                    .location-detail {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        gap: 5px;
    
                        .icon-wrapper {
                            --_location-icon-size: 35px;
                            width: var(--_location-icon-size);
                            height: var(--_location-icon-size);
                            background-color: transparentize($default-border-color, 0.8);
                            border-radius: var(--_location-icon-size);
                            display: flex;
                            justify-content: center;
                            align-items: center;
    
                            .icon {
                                color: $default-text-color-light;
                                font-size: large;
                            }
    
                            &.pick-up {
                                background-color: transparentize($default-green-color, 0.8);
                                .icon {
                                    color: $default-green-color;
                                }
                            }
                        }
                        span {
                            &.title {
                                font-size: 12px;
                                color: $default-text-color-light;
                                text-transform: uppercase;
                            }
                            &.location {
                                font-size: 14px;
                                color: $default-text-color-dark;
                                text-transform: uppercase;
                                text-align: center;
                            }
                        }
                    }

                    .location-line {
                        align-self: center;
                        flex: 1;
                        height: 1px;
                        background-image: linear-gradient(to right, $default-green-color 33%, rgba(255,255,255,0) 0%);
                        background-position: bottom;
                        background-size: 10px 2px;
                        background-repeat: repeat-x;
                    }
                }

                .estimitated-time-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 2px;
                    span {
                        &.title {
                            font-size: 12px;
                            color: $default-text-color-light;
                            text-transform: uppercase;
                        }
                        &.value {
                            font-size: 14px;
                            color: $default-text-color-dark;
                            text-transform: uppercase;
    
                            &.vehicle-number {
                                font-size: 18px;
                                font-weight: bold;
                                font-family: 'Courier New', Courier, monospace;
                                color: $default-green-color;
                            }
                        }
                    }
                }
            }
        }
    
        .ts-details-wrapper-divider {
            width: 100%;
            height: 1px;
            background-image: linear-gradient(to right, transparentize($default-border-color, 0.5) 33%, rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 10px 2px;
            background-repeat: repeat-x;
            position: relative;
    
            &::before, &::after {
                position: absolute;
                content: '';
                height: var(--ts-spacing);
                width: var(--ts-spacing);
                background-color: #fafafa;
                border-radius: 50%;
            }
    
            &::before {
                left: calc(-1.5 * var(--ts-spacing));
                transform: translateY(-50%);
            }
            &::after {
                right: calc(-1.5 * var(--ts-spacing));
                transform: translateY(-50%);
            }
        }
    
    }
}

.ts-progress-item {
    margin-bottom: 5px;
    transition: all 0.5s ease-in-out;

    &:last-child {
        margin-bottom: unset;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparentize($default-border-color, 0.9);
        border-radius: 5px;
        padding: 10px 15px;
        cursor: pointer;

        .title-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            flex: 1;

            .header-icon-wrapper {
                --_ts-header-icon-size: 25px;
                border-radius: 50%;
                height: var(--_ts-header-icon-size);
                width: var(--_ts-header-icon-size);
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid transparentize($default-text-color-light, 0.5);
    
                .icon {
                    color: transparentize($default-text-color-light, 0.5);
                    font-size: calc(var(--_ts-header-icon-size) * 0.5);
                    line-height: calc(var(--_ts-header-icon-size) * 0.5);
                    margin: 0;
                    padding: 0;
                }
            }
    
            span {
                &.title {
                    font-weight: 500;
                    color: $default-text-color-light;
                    font-size: 14px;
                }
            }
        }


        button {
            &.ts-collpsible-btn {
                --_ts-collpsible-btn-size: 31px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparentize($default-border-color, 0.8);
                border: 1px solid transparent;
                color: $default-text-color-light;
                border-radius: 50%;
                height: var(--_ts-collpsible-btn-size);
                width: var(--_ts-collpsible-btn-size);
                font-size: calc(var(--_ts-collpsible-btn-size) * 0.5);
                line-height: calc(var(--_ts-collpsible-btn-size) * 0.5);
                padding: 0;
                margin: 0;
                cursor: pointer;

                &:hover {
                    background-color: transparentize($default-border-color, 0.5);
                    border-color: transparentize($default-border-color, 0.5);
                }
            }
        }

        &.complete {
            .title-wrapper {
                .header-icon-wrapper {
                    border-color: transparentize($default-theme-color, 0.5);
                    background-color: transparentize($default-theme-color, 0.9);
        
                    .icon {
                        color: $default-theme-color;
                    }
                }
        
                span {
                    &.title {
                        color: $default-theme-color;
                    }
                }
            }
        }

        &.visible-content {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .body {
        padding: 10px 15px;
        background-color: transparentize($default-border-color, 0.98);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
