//  ==========================================================================
//  Created Date    :   March 30, 2021
//  Created By      :   Himal Poudel
//  Description     :   This file consists Pagination styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";


table {
    tbody {
        tr {
            &.dynamic-pagination-table-row {
                width: 100%;
                border-bottom: none;
        
                td {
                    width: 100%;
                    border-bottom: 0px !important;

                    &:nth-child(1) {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.dynamic-pagination-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dynamic-pagination-container__left-content, .dynamic-pagination-container__right-content {
        display: flex;
        align-items: center;
        gap: 10px;

        button {
            &.dynamic-pagination-container__btn {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px; 
                white-space: nowrap;
                width: auto;
                padding: 3px 5px;
                border: 2px solid transparent;
                border-radius: 15px;
                color: $default-theme-color;
                
                &.disable {
                    color: $default-border-color;
                }

                &.parent-btn {
                    background-color: transparentize($default-theme-color, 0.85);
                    color: $default-theme-color;

                    &.disable {
                        background-color: transparentize($default-border-color, 0.85);
                        color: $default-border-color;
                    }
                }
            }
        }
    }

    .dynamic-pagination-container__left-content {
        justify-content: flex-start;
    }

    .dynamic-pagination-container__right-content {
        justify-content: flex-end;
        white-space: nowrap;
    }
}