//  ==========================================================================
//  Created Date    :   June 14, 2021
//  Created By      :   Himal Poudel
//  Description     :   This file consists Dashboard styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.dashboard-info-card-wrapper {
    padding: 5px !important;
    &.flex-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 5px;
    }
}


.pax-exchange-rate-container {
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    gap: 5px;
    padding-left: 5px;
    padding-right: 5px;

    .dashboard-info-card-wrapper {
        padding: 0 !important;
        margin: 0 !important;
        flex: 1 !important;
        width: 100%;
        align-self: stretch;

        &:first-child {
            flex: 2 !important;
        }
    }

    @media only screen and (max-width: 992px) {
        flex-direction: column;
    }
}


.exchange-rate-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    overflow: hidden;

    .exchange-rate-fixed-content {
        align-self: stretch;
        background: $default-green-color;
        background: linear-gradient(to bottom, $default-green-color 0%, darken($default-green-color, 10%) 100%);
        padding: 10px 30px 10px 15px;
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-right: -20px;
        z-index: 1;

        span {
            white-space: nowrap;
            color: rgba(255, 255, 255, 0.9);
            text-transform: uppercase;

            strong {
                font-weight: bold;
                color: #fff;
                text-shadow: none;
                font-size: 14px;
            }
        }
    }

    .exchange-rate-data-container {
        width: 100%; /* Adjust the width as needed */
        overflow: hidden; /* Hide the overflowing content */
        position: relative;
        padding-left: 20px;
        
        ul {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            width: 100%;
            transform: translateX(100%);
            animation: marquee 25s linear infinite;
    
            &:hover {
                animation-play-state: paused;
                -o-animation-play-state: paused;
                -moz-animation-play-state: paused;
                -webkit-animation-play-state: paused;
            }

            li {
                list-style: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                white-space: nowrap;

                .name-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 3px;

                    span {
                        color: $default-text-color-light;
                    }
                }

                span {
                    &.value {
                        color: $default-text-color-dark;
                        font-weight: bold;
                    }
                }
            }

            .exchange-rate-list-divider {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: transparentize($default-border-color, 0.5);
            }
        }

        @keyframes marquee {
            0% {
                transform: translateX(100%);
            }
            100% {
                transform: translateX(-100%);
            }
        }
    }
}


.dashboard-info-card {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid transparentize($default-border-color, 0.8);
    border-radius: 10px;
    box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
    padding: 20px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex: 0 1 120px; /*  No stretching: */
    flex: 1 1 120px; /*  Stretching: */
    height: 180px;
    transition: all .5s ease-in-out;

    .card-icon-wrapper {
        background: transparentize($default-theme-color, 0.9);
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        margin: 0;

        .card-icon {
            position: absolute;
            font-size: 16px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $default-theme-color;
        }

        &.due-in {
            background: transparentize($default-dashboard-due-in-color, 0.9);
            .card-icon {
                color: $default-dashboard-due-in-color;
            }
        }

        &.due-out {
            background: transparentize($default-dashboard-due-out-color, 0.9);
            .card-icon {
                color: $default-dashboard-due-out-color;
            }
        }

        &.reserved {
            background: transparentize($default-reserved-color, 0.9);
            .card-icon {
                color: $default-reserved-color;
            }
        }

        &.arrival {
            background: transparentize($default-dashboard-due-in-color, 0.9);
            .card-icon {
                color: $default-dashboard-due-in-color;
            }
        }

        &.departure {
            background: transparentize($default-dashboard-due-out-color, 0.9);
            .card-icon {
                color: $default-dashboard-due-out-color;
            }
        }

        &.pick-drop {
            background: transparentize($default-dashboard-pick-drop-color, 0.9);
            .card-icon {
                color: $default-dashboard-pick-drop-color;
            }
        }

        &.occupancy {
            background: transparentize($default-dashboard-occupancy-color, 0.9);
            .card-icon {
                color: $default-dashboard-occupancy-color;
            }
        }

        &.room-position {
            background: transparentize($default-dashboard-room-position-color, 0.9);
            .card-icon {
                color: $default-dashboard-room-position-color;
            }
        }

        &.walk-in {
            background: transparentize($default-dashboard-due-in-color, 0.9);
            .card-icon {
                color: $default-dashboard-due-in-color;
            }
        }

        &.guest-message {
            background: transparentize($default-blue-color, 0.9);
            .card-icon {
                color: $default-blue-color;
            }
        }

        &.guest-complaint {
            background: transparentize($default-yellow-color, 0.9);
            .card-icon {
                color: $default-yellow-color;
            }
        }

        &.vip-guest {
            background: transparentize($default-purple-accent, 0.9);
            .card-icon {
                color: $default-purple-accent;
            }
        }
    }

    .content {
        display: block;
        padding-bottom: 10px;

        span {
            &.title {
                font-size: 13px;
                color: $default-text-color-dark;
                // white-space: nowrap;
            }
        }

    }

    .data {
        // padding: 15px 0 0;
        span {
            font-size: 25px;
            color: $default-text-color-dark;
            font-family: 'NexaBold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

            &.due-in {
                color: $default-dashboard-due-in-color;
            }

            &.due-out {
                color: $default-dashboard-due-out-color;
            }

            &.reserved {
                color: $default-reserved-color;
            }

            &.occupancy {
                color: $default-dashboard-occupancy-color;
            }

            &.arrival {
                color: $default-dashboard-due-in-color;
            }

            &.departure {
                color: $default-dashboard-due-out-color;
            }

            &.pick-drop {
                color: $default-dashboard-pick-drop-color;
            }
            
            &.occupancy-percent {
                color: $default-text-color-light;
                font-size: 13px;
                padding-left: 5px;
            }

            &.room-position {
                color: $default-dashboard-room-position-color;
            }

            &.walk-in {
                color: transparentize($default-dashboard-due-in-color, 0.5);
                color: $default-text-color-light;

                .walk-in-seperator {
                    &::after {
                        content: '/';
                        color: transparentize($default-text-color-light, 0.6);
                        transform: rotate(45deg) !important;
                        -moz-transform: rotate(45deg) !important;
                        -ms-transform: rotate(45deg) !important;
                        -o-transform: rotate(45deg) !important;
                        -webkit-transform: rotate(45deg) !important;
                    }
                }

                .walk-in-total {
                    color: $default-dashboard-due-in-color;
                }
            }

            &.guest-message {
                color: $default-blue-color;
            }
    
            &.guest-complaint {
                color: $default-yellow-color;
            }
    
            &.vip-guest {
                color: $default-purple-accent;
            }

        }

        .occupancy-denoter {
            margin-left: 10px;
            font-size: 12px;
            color: $default-dashboard-occupancy-color;
        }

    }

    .vip-data {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5px;

        .vip-data-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            span {
                &.label {
                    color: $default-text-color-light;
                }
                &.value {
                    color: $default-text-color-dark;
                    font-weight: 500;
                }
            }
        }
    }

    &.chart-content {
        display: block;
        height: unset !important;
    }

    &.list-display-content {
        // flex: 1 1 140px; /*  Stretching: */
        flex: 1;
        height: unset;
        justify-content: flex-start;

        .list-display-content-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .title-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;

                .card-icon-wrapper {
                    background: transparentize($default-theme-color, 0.9);
                    position: relative;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    padding: 0;
                    margin: 0;
            
                    .card-icon {
                        position: absolute;
                        font-size: 16px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: $default-theme-color;
                    }
            
                    &.due-in {
                        background: transparentize($default-dashboard-due-in-color, 0.9);
                        .card-icon {
                            color: $default-dashboard-due-in-color;
                        }
                    }
            
                    &.due-out {
                        background: transparentize($default-dashboard-due-out-color, 0.9);
                        .card-icon {
                            color: $default-dashboard-due-out-color;
                        }
                    }
            
                    &.occupancy {
                        background: transparentize($default-dashboard-occupancy-color, 0.9);
                        .card-icon {
                            color: $default-dashboard-occupancy-color;
                        }
                    }
            
                    &.room-position {
                        background: transparentize($default-dashboard-room-position-color, 0.9);
                        .card-icon {
                            color: $default-dashboard-room-position-color;
                        }
                    }
    
                    &.pax-count {
                        background: transparentize($default-dashboard-pax-count-color, 0.9);
                        .card-icon {
                            color: $default-dashboard-pax-count-color;
                        }
                    }
                }
            }

            .action-wrapper {
                label {
                    &.as-radio-group-label {
                        position: relative;
                        label {
                            &.total-pax-count {
                                font-size: 10px;
                                line-height: 10px;
                                background-color: $default-dashboard-pax-count-color;
                                border-radius: 10px;
                                color: #ffffff;
                                font-weight: 500;
                                display: inline;
                                padding: 2px 5px;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }


            span {
                &.title {
                    padding-left: 10px;
                    font-size: 14px;
                    color: $default-text-color-dark;
                }
            }

        }

        .list-display-content-body {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 15px 0 0;
            max-height: 40vh;
            overflow: auto;

            ul {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 15px;

                li {
                    box-sizing: border-box;
                    list-style: none;
                    padding: 10px;
                    background: transparentize($default-dashboard-pax-count-color, 0.9);
                    border: 1px solid transparentize($default-dashboard-pax-count-color, 0.9);
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;

                    .pax-icon-wrapper {
                        width: 35px;
                        height: 35px;
                        background-color: transparentize($default-dashboard-pax-count-color, 0.9);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        justify-self: stretch;
                        border-radius: 2px;

                        .icon {
                            color: $default-dashboard-pax-count-color;
                            font-size: 16px;
                        }
                    }

                    .pax-title-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-direction: column;
                        gap: 3px;
                        padding-right: 10px;

                        span {
                            &.title {
                                color: $default-text-color-dark;
                                font-size: 12px;
                            }
                            &.value {
                                color: $default-dashboard-pax-count-color;
                                font-size: 18px;
                                font-weight: bold;
                            }
                        }
                    }
                }

            }
        }

        &.room-status-content {
            height: auto;
            width: auto;

            .room-status-chart-container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 0 0 0;
            }
        }
    }

}

.dashboard-list-card {
    --card-background-color: #fff;
    --card-padding: 10px 20px;
    --header-footer-min-height: 50px;
    box-sizing: border-box;
    background: var(--card-background-color);
    border: 1px solid transparentize($default-border-color, 0.8);
    border-radius: 10px;
    box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
    transition: all .5s ease-in-out;
    overflow: hidden;
    padding-bottom: 20px;
    .header {
        padding: var(--card-padding);
        background: var(--card-background-color);
        min-height: var(--header-footer-min-height);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid transparentize($default-border-color, 0.5);

        span {
            &.title {
                color: $default-text-color-dark;
            }
        }

        .as-radio-group-transparent {
            display: flex !important;
            justify-content: flex-start !important;
            align-items: center !important;
            input {
                &.as-radio-group-btn{
                    &:checked {
                        + label {
                            cursor: pointer !important;
                            &.as-radio-group-label {
                                color: $default-text-color !important;
                                background-color: var(--card-background-color) !important;

                                &.arrival-color {
                                    color: $default-dashboard-due-in-color !important;
                                }
                                &.departure-color {
                                    color: $default-dashboard-due-out-color !important;
                                }
                                &.stayover-color {
                                    color: $default-dashboard-occupancy-color !important;
                                }
                            }
                        }
                    }
                }
            }
            label {
                cursor: pointer !important;
                &.as-radio-group-label {
                    background: transparent !important;
                    color: $default-border-color !important;
                    padding: 0 12px 0 0 !important;
                }
            }
        }

    }
    .body {
        padding: var(--card-padding);
        background: var(--card-background-color);
        width: 100%;
        min-height: 300px;
        max-height: 300px;
        overflow: hidden;

        &:hover {
            overflow-y: auto;
        }

        table {
            &.dashboard-tbl {
                table-layout: fixed;
                width: 100%;

                tbody,thead {
                    tr {
                        td, th {
                            padding: 10px 0;
                            color: $default-text-color-light;
                            text-align: left;
                            overflow-wrap: break-word;

                            &:first-child {
                                color: $default-text-color-dark;
                            }
                            &:nth-child(2) {
                                color: $default-text-color-dark;
                            }
                            &:nth-child(3) {
                                color: $default-text-color-dark;
                                text-align: left;
                            }
                            &:nth-child(4) {
                                color: $default-text-color-dark;
                                text-align: left;
                            }                            
                            &:last-child {
                                text-align: right;
                                color: $default-text-color-light;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        &:nth-child(odd) {
                            td {
                                border-top: 1px solid transparentize($default-border-color, 0.64);
                                border-bottom: 1px solid transparentize($default-border-color, 0.64);
                            }
                        }
                    }
                }

                &.exp-arr, &.exp-dept, &.stayover, &.todays_arrival, &.todays_departure, &.rsvn, &.special-event {
                    tbody,thead {
                        tr {
                            td, th {
                                padding: 10px 0;
                                color: $default-text-color-dark;

                                &:last-child {
                                    color: $default-text-color-light;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                &.todays_arrival, &.todays_departure {
                    tbody, thead {
                        tr {
                            td, th {
                                &:nth-child(5) {
                                    color: $default-text-color-light;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                &.special-event {
                    tbody, thead {
                        tr {
                            td, th {
                                &:nth-child(6) {
                                    color: $default-text-color-light;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                &.todays-pick-up-drop {
                    tbody,thead {
                        tr {
                            td, th {
                                padding: 10px 5px;
                                color: $default-text-color-dark;
                                &:nth-child(6), &:last-child {
                                    text-align: center;
                                }
                            }
                            &.pick-up-row {
                                td, th {
                                    &:last-child {
                                        border-right: 6px solid transparentize($default-green-color, 0.6);
                                    }
                                }
                            }
                            &.drop-row {
                                td, th {
                                    &:last-child {
                                        border-right: 6px solid transparentize($default-red-color, 0.6);
                                    }
                                }
                            }
                            &.passed-time {
                                td, th {
                                    opacity: 0.3;
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    .footer {
        padding: var(--card-padding);
        padding-bottom: 0;
        background: var(--card-background-color);
        min-height: var(--header-footer-min-height);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid transparentize($default-border-color, 0.5);
    }
}


//  For Transaction Pages
tr {
    &.departure-date {
        th, td {
            color: $default-dashboard-due-out-color;
        }
    }
    &.day-use {
        th, td {
            background-color: transparentize($default-dashboard-due-out-color, 0.85);
            &:first-child {
                border-left: 4px solid $default-dashboard-due-out-color;
            }
        }
    }
}


//  Guest Information Search Style
.gi-card {
    --gi-card-padding: 20px;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid transparentize($default-border-color, 0.8);
    border-radius: 10px;
    box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
    padding: 0;
    padding-bottom: calc(var(--gi-card-padding) / 2);
    overflow: hidden;

    .top-header {
        padding: var(--gi-card-padding);
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-bottom: 1px solid transparentize($default-border-color, 0.8);
        span {
            &.title {
                color: $default-text-color-light;
                font-size: 14px;
                span {
                    &.value {
                        color: $default-text-color-dark;
                    }
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--gi-card-padding);
        --icon-wrapper-size: 40px;
        --icon-size: 16px;

        .icon-title-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .icon-wrapper {
                width: var(--icon-wrapper-size);
                height: var(--icon-wrapper-size);
                background-color: transparentize($default-theme-color, 0.9);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: var(--icon-wrapper-size);
                margin-right: calc(var(--gi-card-padding) / 2);
                .icon {
                    font-size: var(--icon-size);
                    color: $default-theme-color;
                }

                &.stay-information {
                    background-color: transparentize($default-dashboard-pax-count-color, 0.9);
                    .icon {
                        color: $default-dashboard-pax-count-color;
                    }
                }
                &.in-house-guest {
                    background-color: transparentize($default-in-house-color, 0.9);
                    .icon {
                        color: $default-in-house-color;
                    }
                }
                &.room-rate-charge {
                    background-color: transparentize($default-vacant-color, 0.9);
                    .icon {
                        color: $default-vacant-color;
                    }
                }
                &.room-information {
                    background-color: transparentize($default-dashboard-room-position-color, 0.9);
                    .icon {
                        color: $default-dashboard-room-position-color;
                    }
                }
                &.instructions {
                    background-color: transparentize($default-blue-color, 0.9);
                    .icon {
                        color: $default-blue-color;
                    }
                }
                &.quick-links {
                    background-color: transparentize($default-yellow-color, 0.9);
                    .icon {
                        color: $default-yellow-color;
                    }
                }
            }
            .title-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                span {
                    &.title {
                        color: $default-text-color-dark;
                        font-size: 15px;
                    }
                    &.description {
                        color: $default-text-color-light;
                        font-size: 11px;
                        padding-top: 2px;
                    }
                }
            }
        }
        .header-action-wrapper {
            span {
                &.header-action-title {
                    font-size: 25px;
                    white-space: nowrap;
                    color: $default-text-color-dark;
                    padding-right: calc(var(--gi-card-padding) / 2);
                    font-family: 'NexaBold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'Roboto', sans-serif;

                    &.in-house-guest-title {
                        color: $default-in-house-color;
                    }
                    &.room-rate-title {
                        color: $default-vacant-color;
                    }
                    &.stay-information-title {
                        color: $default-dashboard-pax-count-color;
                    }
                }
            }


            .header-action-title-wrapper {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: column;

                &.one-line {
                    flex-wrap: nowrap;
                    flex-direction: row;
                }

                span {
                    &.title {
                        color: $default-text-color-light;
                        font-size: 11px;
                        text-align: right;
                        span {
                            &.value {
                                color: $default-text-color-dark;
                                font-weight: bold;
                                font-size: 12px;
                            }
                        }
                        &:last-child {
                            padding-top: 2px;
                        }
                    }
                }

                .dnd-tag-wrapper {
                    --dnd-icon-wrapper-size: 20px;
                    padding: 4px calc(var(--dnd-icon-wrapper-size) * 0.25 + 4px) 4px 4px;
                    border-radius: var(--dnd-icon-wrapper-size);
                    background-color: $default-red-color;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin-right: 10px;
    
                    .icon-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #ffffff;
                        height: var(--dnd-icon-wrapper-size);
                        width: var(--dnd-icon-wrapper-size);
                        border-radius: var(--dnd-icon-wrapper-size);
                        margin-right: calc(var(--dnd-icon-wrapper-size) * 0.25);
                        .icon {
                            font-size: calc(var(--dnd-icon-wrapper-size) * 0.5);
                            color: $default-red-color;
                        }
                        svg {
                            width: 70%;
                            height: auto;
                            &.dnd-icon {
                                path {
                                    fill: $default-red-color !important;
                                }
                            }
                        }
                    }
                    span {
                        color: #ffffff;
                        text-transform: uppercase;
                        font-weight: 500;
                    }
                }
            }

            .input-wrapper {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .input-group {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    margin: 0 5px;
                    label {
                        white-space: nowrap;
                        margin-right: 5px;
                        padding: 5px 0;
                    }
                    input {
                        &.filter-input {
                            background-color: transparent;
                            border: 1px solid transparent;
                            border-bottom: 1px solid transparentize($default-border-color, 0.64);
                            border-radius: 0;
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        &.show-border {
            border-bottom: 1px solid transparentize($default-border-color, 0.8);
        }
    }

    .body {
        min-height: 80px;
        padding: var(--gi-card-padding);

        .arrival-departure-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            position: relative;
            --icon-wrapper-size: 30px;
            --icon-size: 14px;

            .arrival-date-wrapper, .departure-date-wrapper {
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 0;
                .icon-wrapper {
                    width: var(--icon-wrapper-size);
                    height: var(--icon-wrapper-size);
                    border: 1px solid transparentize($default-text-color, 0.9);
                    border-radius: var(--icon-wrapper-size);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparentize($default-text-color, 0.9);
                    margin-right: 10px;
                    .icon {
                        color: $default-text-color;
                        font-size: var(--icon-size);
                    }
                }
                .data-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    span {
                        &.title {
                            font-size: small;
                            color: $default-text-color-light;
                        }
                        &.value {
                            font-size: 14px;
                            color: $default-text-color-dark;
                        }
                    }
                }
            }

            .arrival-date-wrapper {
                .icon-wrapper {
                    background-color: transparentize($default-green-color, 0.9);
                    border-color: transparentize($default-green-color, 0.9);
                    .icon {
                        color: $default-green-color;
                    }
                }
            }
            .departure-date-wrapper {
                .icon-wrapper {
                    background-color: transparentize($default-text-color-light, 0.9);
                    border-color: transparentize($default-text-color-light, 0.9);
                    .icon {
                        color: $default-text-color-light;
                    }
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 20%;
                border-left: 2px dotted transparentize($default-green-color, 0.9);
                border-collapse: collapse;
                border-spacing: 0;
                left: calc(var(--icon-wrapper-size) / 2);
            }
        }

        .multiple-icon-detail-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            --icon-wrapper-size: 30px;
            --icon-size: 14px;
            .icon-detail-wrapper {
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: transparentize($default-border-color, 0.9);
                border-radius: 20px;
                margin: 4px 8px 4px 0;
                padding: 2px calc(var(--icon-wrapper-size) / 2) 2px 2px;
                .icon-wrapper {
                    width: var(--icon-wrapper-size);
                    height: var(--icon-wrapper-size);
                    background-color: #fff;
                    border: 1px solid transparentize($default-border-color, 0.9);
                    border-radius: var(--icon-wrapper-size);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;
                    .icon {
                        color: $default-dashboard-pax-count-color;
                        font-size: var(--icon-size);
                    }
                }
                .data-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    span {
                        &.title {
                            font-size: small;
                            color: $default-text-color-light;
                        }
                        &.value {
                            font-size: 14px;
                            color: $default-text-color-dark;
                        }
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .display-data-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            span {
                &.title {
                    font-size: small;
                    color: $default-text-color-light;
                    padding-bottom: 2px;
                }
                &.value {
                    font-size: 14px;
                    color: $default-text-color-dark;
                }
            }
        }

        ul {
            &.in-house-guest-list {
                padding: 0;
                margin: 0;
                li {
                    list-style: none;
                    padding: 10px 15px;
                    border-bottom: 1px solid transparentize($default-border-color, 0.5);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-sizing: border-box;
                    --list-icon-wrapper-size: 25px;
                    --list-icon-size: 14px;
                    .list-title-icon-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        // flex-direction: column;
                        .list-icon-wrapper {
                            width: var(--list-icon-wrapper-size);
                            height: var(--list-icon-wrapper-size);
                            border-radius: var(--list-icon-wrapper-size);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: transparentize($default-text-color-light, 0.9);
                            margin-right: calc(var(--list-icon-wrapper-size) / 2);
                            .list-icon {
                                color: $default-text-color-light;
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                    &:hover {
                        background-color: transparentize($default-border-color, 0.9);
                    }
                }
            }
        }

        &.in-house-body-content {
            max-height: 30vh;
            overflow-y: auto;
        }

        .reg-rsvn-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 0;
            padding-bottom: 0;
            span {
                &.title {
                    color: $default-text-color-light;
                    font-size: small;
                    text-align: right;
                }
                &.value {
                    color: $default-text-color-dark;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }

        &.no-padding {
            &.left {
                padding-left: 0;
            }
            &.right {
                padding-right: 0;
            }
            &.top {
                padding-top: 0;
            }
            &.bottom {
                padding-bottom: 0;
            }
        }
        
    }

    .footer {
        padding: calc(var(--gi-card-padding) / 2);
        padding-bottom: calc(var(--gi-card-padding) / 4);
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

// .seperator-border {
//     &.left {
//         border-left: 1px solid transparentize($default-border-color, 0.8);
//     }
//     &.right {
//         border-right: 1px solid transparentize($default-border-color, 0.8);
//     }
// }

.seperator {
    width: 100%;
    border-top: 1px solid transparentize($default-border-color, 0.8);
    margin: 10px 0;
}


.read-only-information-wrapper {
    label {
        display: block;
        font-size: small;
        color: $default-text-color-light;
        text-transform: capitalize;
    }
    span {
        display: block;
        font-size: 14px;
        color: $default-text-color-dark;
        text-transform: uppercase;
    }

    .section-title-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .section-title-icon-wrapper {
            --section-title-wrapper-size: 25px;
            --section-title-icon-size: 12px;
            width: var(--section-title-wrapper-size);
            height: var(--section-title-wrapper-size);
            border-radius: var(--section-title-wrapper-size);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparentize($default-theme-color, 0.9);
            margin-right: calc(var(--section-title-wrapper-size) / 2);
            .icon {
                font-size: var(--section-title-icon-size);
                color: $default-theme-color;
            }
        }
        span {
            &.section-title {
                font-size: 14px;
                color: $default-text-color-light;
                text-transform: unset;
            }
        }
    }
}