 
 @import 'variables.scss';

 ul {
    &.as-sortable {
        display: block; 
        list-style: none; 
        background: transparent; 
        padding: 20px 5px;
        
        li {
            box-shadow: 1px 1px 4px transparentize(#000000, 0.6);
            display: block;
            margin: 2px 0px; 
            padding: 10px;
            border-radius: 4px;
            cursor: move;
            background: #fff;
            margin:5px;
            color: $default-theme-color;
        }
     }
 }