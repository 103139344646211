//  ==========================================================================
//  Created Date    :   January 6, 2021
//  Created By      :   Himal Poudel
//  Description     :   This file consists Wizard Form styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

//  Wizard Form used in Reservation and Check-in Page
.as-wizard {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid $default-border-color;
    border-radius: 5px;
    padding: 0 10px;

    &.borderless {
        border: none;
    }

    .as-wizard-header {
        width: 100%;
        border-bottom: 1px solid transparentize($default-border-color, 0.5);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 10px 0px;

        button {
            &.as-wizard-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 15px;
                text-align: left;
                // cursor: pointer;
                white-space: nowrap;
                text-decoration: none;
                user-select: none;
                -webkit-font-smoothing: antialiased;
                -webkit-user-drag: none;
                -webkit-user-select: none;
                position: relative;
                overflow: hidden;
                border: none;
                color: $default-text-color-light;
                background: transparent;
                margin: 0;
                outline: none;

                .as-wizard-btn-indicator {
                    min-width: 19px;
                    min-height: 19px;
                    max-width: 19px;
                    max-height: 19px;
                    background: lighten($default-text-color-light, 30%);
                    border: 2px solid $default-text-color-light;
                    border-radius: 50%;
                    float: left;
                    margin: 0 10px 0 0;
                }

                svg {
                    min-width: 19px !important;
                    min-height: 19px !important;
                    max-width: 19px !important;
                    max-height: 19px !important;
                    margin: 0 10px 0 0 !important;
                }

                span {
                    font-size: $default-wizard-button-font-size;
                    font-weight: 500;
                    text-align: left;
                    white-space: nowrap;
                    text-decoration: none;
                    user-select: none;
                    -webkit-font-smoothing: antialiased;
                    -webkit-user-drag: none;
                    -webkit-user-select: none;
                }

                &.as-wizard-btn-focused {
                    color: $default-theme-color;
                    border-bottom: 2px solid $default-theme-color;
                    margin-bottom: -2px;
                    position: relative;

                    .as-wizard-btn-indicator {
                        background: lighten($default-theme-color, 50%);
                        border-color: $default-theme-color;
                    }

                    span {
                        color: $default-theme-color;
                    }

                }

                &.as-wizard-btn-error {
                    color: $default-red-color;
                    // border-bottom: 1px solid $default-red-color;
                    // margin-bottom: -1px;

                    span {
                        color: $default-red-color;
                    }

                    &.as-wizard-btn-focused {
                        border-bottom: 2px solid $default-red-color;
                        margin-bottom: -2px;
                        position: relative;
                    }

                }

                &.as-wizard-btn-completed {
                    color: $default-theme-color;
                    // border-bottom: 1px solid $default-theme-color;
                    // margin-bottom: -1px;

                    span {
                        color: $default-theme-color;
                    }

                    &.as-wizard-btn-focused {
                        border-bottom: 2px solid $default-theme-color;
                        margin-bottom: -2px;
                        position: relative;
                    }

                    &:disabled {
                        color: $default-border-color !important;
                        border: none !important;

                        span {
                            color: $default-text-color-light !important;
                        }

                        svg {
                            path {
                                fill: $default-text-color-light !important;
                            }
                        }
                    }

                }

            }
        }

    }

    .as-wizard-body {
        padding: 15px 5px;
        min-height: 68vh;
        max-height: 68vh;
        overflow: auto;
    }

    .as-wizard-footer {
        // border-top: 1px solid transparentize($default-border-color, 0.5);
        padding: 10px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        button {
            padding: 5px 10px;
            font-size: 12px;
            height: 26px;
            border: none;
            border-radius: 13px;
            text-align: center;
            cursor: pointer;
            white-space: nowrap;
            text-decoration: none;
            user-select: none;
            -webkit-font-smoothing: antialiased;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            position: relative;
            overflow: hidden;

            &.as-go-to-prev-step-btn {
                color: $default-theme-color;
                background: transparent;
                box-shadow:  none;

                span {
                    margin: 0 0 0 10px;
                }

                &:disabled {
                    border: 1px solid transparent !important;

                    span {
                        color: $default-text-color-light !important;
                    }

                }
            }

            &.as-go-to-next-step-btn {
                color: #ffffff;
                background: $default-theme-color;
                background: linear-gradient(140deg, lighten($default-theme-color, 5%), $default-theme-color, darken($default-theme-color, 5%));
                box-shadow:  0px 4px 8px transparentize($default-theme-color, 0.6);

                span {
                    margin: 0 10px 0 5px;
                }
            }

            &.as-form-extra-btn {
                color: $default-theme-color;
                background: transparent;
                box-shadow:  none;

                &:disabled {
                    border: 1px solid transparent !important;
                    color: $default-text-color-light !important;
                }

                &.cancel {
                    color: $default-red-color;
                }

                &.light {
                    color: $default-text-color-light;
                }

                &.normal {
                    color: $default-text-color;
                }

                &.gray {
                    color: #ffffff;
                    background: $default-text-color;
                    background: linear-gradient(140deg, lighten($default-text-color, 5%), $default-text-color, darken($default-text-color, 5%));
                    box-shadow:  0px 4px 8px transparentize($default-text-color, 0.6);

                    span {
                        margin: 0 5px 0 8px;
                    }
                }

                &.confirm {
                    color: #ffffff;
                    background: $default-green-color;
                    background: linear-gradient(140deg, lighten($default-green-color, 5%), $default-green-color, darken($default-green-color, 5%));
                    box-shadow:  0px 4px 8px transparentize($default-green-color, 0.6);
                    margin-left: 5px;

                    span {
                        margin: 0 5px 0 8px;
                    }
                }

            }

        }
    }

}