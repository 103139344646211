//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Dropdown styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-dropdown {
    position: relative;
}

.as-dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px transparentize(#000000, 0.8);
    z-index: 1;
    right: 0;
    transition: all .2s ease-in-out;
    &.drop-left {
        left: 0;
        right: auto;
    }
    a {
        color: $default-text-color;
        padding: 10px 14px;
        text-decoration: none;
        display: block;
        text-align: left;
        right: auto;
        &:hover {
            background-color: $default-body-background-color;
            color: $default-text-color-dark;
            transition: all .1s ease-in-out;
        }
    }
}

.as-divider {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $default-border-color;
    margin: .5rem 0;
    padding: 0;
}

.as-show-dropdown {
    display: block;
}

.as-hide-dropdown {
    display: none;
}


//  This is for Search Dropdown
.as-search-dropdown-wrapper {
    position: relative;

    .as-search-dropdown {
        width: 100%;
        position: absolute;
        z-index: 12 ;

        .as-search-dropdown-content {
            display: none;
            margin-top: 5px;
            background: #ffffff;
            border: 1px solid $default-border-color;
            border-radius: 5px;
            box-shadow: 0px 5px 10px 0px transparentize(#000000, 0.8);

            &.as-show-search-dropdown {
                display: block;
            }

            .as-search-dropdown-action {
                padding: 5px 10px;
                border-bottom: 1px solid $default-border-color;
                button {
                    &.as-search-action {
                        border: 1px solid $default-border-color;
                        border-radius: 5px;
                        background: transparent;
                        padding: 5px 10px;
                        color: $default-theme-color;
                        text-align: center;
                        outline: none;
                        cursor: pointer;
                        
                        &:hover, &:focus {
                            background: transparentize($default-border-color, 0.9);
                        }
                    }
                }
            }

            .as-search-dropdown-result {
                max-height: 200px;
                overflow-y: overlay;
                overflow-x: hidden;
                padding: 5px 0;

                //  Start Scroller
                &::-webkit-scrollbar {
                    cursor: pointer;
                    width: 0px;
                }
                &::-webkit-scrollbar-track {
                    background: transparent !important;
                }
                &::-webkit-scrollbar-thumb {
                    background: $default-body-background-color;
                    border-radius: 2px;

                    &:hover {
                        background: $default-border-color;
                    }

                }
                //  End Scroller

                button {
                    &.as-search-result {
                        position: relative;
                        display: inline-flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding: 5px 10px;
                        margin: 0;
                        text-align: left;
                        text-transform: capitalize;
                        color: $default-text-color;
                        background: transparent;
                        align-items: center;
                        cursor: pointer;
                        font-size: 14px;

                        &:hover, &:focus {
                            background: transparentize($default-theme-color, 0.80);
                        }

                        .as-result-quantity {
                            float: right;
                            max-width: 80px;
                            -webkit-appearance: textfield !important;
                            -moz-appearance: textfield !important;
                            appearance: textfield !important;

                            &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                                -webkit-appearance: none !important;
                            }
                            
                        }

                        &.as-btn-search-dropdown-option-focus{
                            background: transparentize($default-theme-color, 0.8);
                        }
                    }
                }

            }
        }
    }
}