@import "variables.scss";

.reservation-list-row-container {
    --_spacing: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    flex-wrap: wrap;

    &:last-child {
        margin-bottom: unset;
    }

    .guest-info {
        padding: var(--_spacing);
        padding-right: calc(var(--_spacing) * 0.75);
        width: 25%;
        min-width: 300px;
        align-self: stretch;
        border-width: 0;
        border-left-width: 6px;
        border-style: solid;
        border-color: #fff;

        &.green {
            border-color: transparentize($default-green-color, 0.5);
        }
        &.red {
            border-color: transparentize($default-red-color, 0.5);
        }
        &.yellow {
            border-color: transparentize($default-yellow-color, 0.5);
        }
        &.gray {
            border-color: transparentize($default-gray-color, 0.5);
        }

        .guest-name-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .vip-badge-container{
                width: 35px;
                height: 35px;

                svg {
                    filter: drop-shadow(0px 1px 1px #00000020);
                    path, rect {
                        fill: rgb(234, 180, 21);
                    }
                }
            }

            span{
                &.guest-name {
                    font-size: clamp(1.25rem, 2vw, 1.5rem);
                    color: $default-text-color-dark;
                    overflow-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-weight: 500;
                }
            }
        }

        span {
            &.rsvn-number {
                display: block;
                color: $default-text-color-light;
                margin-top: 5px;
                font-size: 14px;
            }
        }

        .button-container {
            display: flex;
            gap: 5px;
            flex-shrink: 0;
            padding-top: 10px;

            button {
                margin: 0;
            }
        }

        .status-container {
            padding-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 5px;

            span, small {
                &.day-use {
                    color: $default-dashboard-due-out-color;
                }
            }
        }
    }

    .stay-info {
        flex: 1;
        padding: var(--_spacing);
        padding-left: calc(var(--_spacing) * 0.75);
        border-left: 1px solid transparentize($default-border-color, 0.8);
        background-color: transparentize($default-border-color, 0.98);
        align-self: stretch;

        span {
            &.label {
                display: block;
                color: $default-text-color-light;
            }
            &.data {
                display: block;
                color: $default-text-color-dark;
                font-weight: 600;
                overflow-wrap: break-word;
            }
        }

        &.day-use {
            border-left: 4px solid transparentize($default-dashboard-due-out-color, 0.8);
            background-color: transparentize($default-dashboard-due-out-color, 0.98);
        }
    }
}

@media only screen and (max-width: 560px) {
    .reservation-list-row-container {
        .guest-info {
            border-left-width: 0;
            border-top-width: 6px;
            min-width: 100%;
        }

        .stay-info {
            padding-top: calc(var(--_spacing) * 0.75);
            border-left: 0px;
            border-top: 1px solid transparentize($default-border-color, 0.5);

            &.day-use {
                border-left: unset;
                border-top: 4px solid transparentize($default-dashboard-due-out-color, 0.8);
            }
        }
    }
}