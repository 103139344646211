//  ==========================================================================
//  Created Date    :   May 1, 2021
//  Created By      :   Himal Poudel
//  Description     :   This file consists Room Status styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   15 Nov, 2024
//  Modified By     :   Kalyan Kafle
//  Modification    :   In page filter added
//  ==========================================================================


@import "variables.scss";

//  FOR NEW UI
.room-status-section-wrapper {
    overflow: auto;

    .room-status-header {
        position: sticky;
        top: 0;
        background: $default-body-background-color;
    }
    .room-status-body {
        max-height: 54vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.status-wrapper {
    position: relative;

    label {
        &.as-room-status-check-label {
            position: relative;
            background: $default-vacant-color;
            box-shadow: 0px 4px 10px #00000040;
            // padding: 20px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            // align-items: center;
            transition: all 0.2s ease-in-out;
            padding: 10px 20px 0px 20px;
            min-height: 60px;
            align-items: flex-start;

            span {
                color: #ffffff;

                &.room-number {
                    font-size: 16px;
                    white-space: nowrap;
                    width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    -ms-text-overflow: ellipsis;
                }

                &.room-category {
                    white-space: nowrap;
                }

                &.checked-indicator {
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    z-index: 5 !important;
                    font-size: 20px;
                    color: $default-text-color-dark;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: rgb(243, 240, 240);
                        height: 19px;
                        width: 19px;
                        z-index: -1;
                        border-radius: 50%;
                        box-shadow: 0px 4px 10px #00000080;
                    }

                }
            }


            .inspected-room {
                position: absolute;
                right: 5px;
                bottom: 5px;
                color: #ffffff;
            }

            &:hover {
                transform: scale(1.1);
            }

            .do-not-disturb {
                position: absolute;
                right: 20px;
                bottom: 5px;

                color: #fff;

            }
            &:hover {
                transform: scale(1.1);
            }

            .vip-badge-container{
                position: absolute;
                right: 32px;
                bottom: 3px;

                svg {
                    height: 15px;
                    width: 20px;

                    filter: drop-shadow(0px 1px 1px #00000020);
                    path, rect {
                        fill: rgb(245, 245, 245);
                    }
                }
            }
            &:hover {
                transform: scale(1.1);
            }

            .bedding-names {
                height: 15px;
                width: 150px;
                position: absolute;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                left: 10px;
                bottom: 5px;
                padding: 0;
                padding-left: 10px;
                color: #fff;
                font-size: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                -ms-text-overflow: ellipsis;
            }

        }

        .room-status-menu {
            position: absolute;
            top: 10px;
            right: 10px;
            background: #fff;
            padding: 5px;
    
            ul {
                li {
                    list-style: none;
                    display: block;
                    padding: 5px;
                }
            }
        }

    }

    .toogle-dropdown {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
        color: #fff;
        background: $default-green-color;
        box-shadow: inset 5px 5px 10px darken($default-green-color, 10%), 
            inset -5px -5px 10px lighten($default-green-color, 10%);
        transition: all 0.2s ease-in-out;
    }

    &.in-house {
        label {
            &.as-room-status-check-label {
                background: $default-red-color;
            }
        }
        .toogle-dropdown {
            background: $default-red-color;
            box-shadow: inset 5px 5px 10px darken($default-red-color, 20%), 
                inset -5px -5px 10px lighten($default-red-color, 10%);
        }
    }

    &.reserved {
        label {
            &.as-room-status-check-label {
                background: $default-reserved-color;
            }
        }
        .toogle-dropdown {
            background: $default-reserved-color;
            box-shadow: inset 5px 5px 10px darken($default-reserved-color, 10%), 
                inset -5px -5px 10px lighten($default-reserved-color, 20%);
        }

        &.in-house {
            label {
                &.as-room-status-check-label {
                    background: $default-in-house-color;
                }
            }
            .toogle-dropdown {
                background: $default-in-house-color;
                box-shadow: inset 5px 5px 10px darken($default-in-house-color, 20%), 
                    inset -5px -5px 10px lighten($default-in-house-color, 10%);
            }
        }

        &.departing {
            label {
                &.as-room-status-check-label {
                    background: $default-departing-color;
                }
            }
            .toogle-dropdown {
                background: darken($default-departing-color, 35%);
                box-shadow: inset 5px 5px 10px darken($default-departing-color, 40%), 
                    inset -5px -5px 10px lighten($default-departing-color, 20%);
            }
        }
        
    }

    &.dirty {
        label {
            &.as-room-status-check-label {
                background: $default-dirty-color;
                
            }
        }
        .toogle-dropdown {
            background: $default-dirty-color;
            box-shadow: inset 5px 5px 10px darken($default-dirty-color, 10%), 
                inset -5px -5px 10px lighten($default-dirty-color, 10%);
        }
    }

    &.in-house {

        &.dirty {
            label {
                &.as-room-status-check-label {
                    background: $default-dirty-color;
                    background: linear-gradient(320deg, $default-in-house-color, darken($default-dirty-color, 5%), $default-dirty-color);
                }
            }
            .toogle-dropdown {
                background: darken($default-dirty-color, 35%);
                box-shadow: inset 5px 5px 10px darken($default-dirty-color, 40%), 
                    inset -5px -5px 10px lighten($default-dirty-color, 20%);
            }
        }

        &.departing {
            label {
                &.as-room-status-check-label {
                    background: $default-departing-color;
                }
            }
            .toogle-dropdown {
                background: darken($default-departing-color, 35%);
                box-shadow: inset 5px 5px 10px darken($default-departing-color, 40%), 
                    inset -5px -5px 10px lighten($default-departing-color, 20%);
            }
        }
    }

    &.departing {
        label {
            &.as-room-status-check-label {
                background: $default-departing-color;
            }
        }
        .toogle-dropdown {
            background: darken($default-departing-color, 35%);
            box-shadow: inset 5px 5px 10px darken($default-departing-color, 40%), 
                inset -5px -5px 10px lighten($default-departing-color, 20%);
        }
    }

    &.mgmt-block {
        label {
            &.as-room-status-check-label {
                background: $default-management-block-color;
            }
        }
        .toogle-dropdown {
            background: $default-management-block-color;
            box-shadow: inset 5px 5px 10px darken($default-management-block-color, 10%), 
                inset -5px -5px 10px lighten($default-management-block-color, 10%);
        }
    }

    &.oos {
        label {
            &.as-room-status-check-label {
                background: $default-out-of-service-color;
            }
        }
        .toogle-dropdown {
            background: $default-out-of-service-color;
            box-shadow: inset 5px 5px 10px darken($default-out-of-service-color, 10%), 
                inset -5px -5px 10px lighten($default-out-of-service-color, 10%);
        }
    }

    &.ooo {
        label {
            &.as-room-status-check-label {
                background: $default-out-of-order-color;
            }
        }
        .toogle-dropdown {
            background: $default-out-of-order-color;
            box-shadow: inset 5px 5px 10px darken($default-out-of-order-color, 10%), 
                inset -5px -5px 10px lighten($default-out-of-order-color, 10%);
        }
    }

    .as-room-status-check-btn {
        position: absolute;
        // top: -1000000px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:focus {
            ~ {

                label {
                    &.as-room-status-check-label {
                        &::before {
                            position: absolute;
                            content: '';
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            border-radius: 10px;
                            border: 6px solid #fff;
                            background: transparent;
                            z-index: 2;
                        }
                        &::after {
                            position: absolute;
                            content: '';
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            border-radius: 10px;
                            border: 6px double $default-theme-color;
                            background: transparent;
                            z-index: 3;
                        }
                    }
                }

            }

        }

        &.checked {

            &:focus {
                ~ {

                    label {
                        &.as-room-status-check-label {
                            &::after {
                                border: 6px double $default-green-color;
                            }
                        }
                    }
    
                }
            }

            ~ {

                label {
                    &.as-room-status-check-label {
                        transform: scale(0.9);

                        &:hover {
                            transform: scale(1);
                        }

                        &::after, &::before {
                            transform: scale(1);
                        }
                    }
                }

                .toogle-dropdown {
                    right: 20px;
                }

            }

        }

    }


    .as-dropdown-content {
        box-sizing: border-box;
        border: 1px solid $default-border-color;
        border-top: 2px solid $default-theme-color;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: $default-actionbar-dropdown-z-index;
        right: 0px;
        top: 0px;
        border-radius: 5px;
        padding: 10px;
        -webkit-animation-name: displayProfileDropDown;
        -webkit-animation-duration: .4s;
        animation-name: displayProfileDropDown;
        animation-duration: .4s;

        &:before {
            content: '';
            float: right;
            margin-top: -30px;
            margin-right: 10px;
            height: 0;
            width: 0;
            border: 10px solid transparent;
            border-bottom-color: $default-theme-color;
        }

        button {
            border: 2px solid transparent;
            background: transparent;
            color: $default-text-color;
            border-radius: 5px;
            text-align: left;
            padding: 5px;
            margin: 2px;
            display: block;
            width: 100%;
            cursor: pointer;
            outline: none;

            &:hover, &:focus {
                color: $default-text-color-dark;
                background-color: transparentize($default-text-color-dark, 0.9);
            }
        }

        @keyframes displayProfileDropDown {
            0%{
                transform: translateY(50px);
                opacity: 0;
            }
            100%{
                transform: translateY(0px);
                opacity: 1;
            }
        }

        @-webkit-keyframes displayProfileDropDown {
            0%{
                transform: translateY(50px);
                opacity: 0;
            }
            100%{
                transform: translateY(0px);
                opacity: 1;
            }
        }

    }

}

input {

    &.as-search-room {
        width: 100%;
        padding: 5px 10px 5px 40px;
        margin-bottom: 10px;
        border: none;
        border-bottom: 1px solid $default-border-color;
        outline: none;
        border-radius: 0;
        background-color: $default-body-background-color;
        color: $default-text-color;
        font-size: 14px;
        background: url(../../Icons/search_icon.png) no-repeat;
        background-position: 15px;
        background-size: 14px;
        box-shadow: none;
        transition: all 0.2s ease-in-out;

        &:hover, &:focus {
            border-color: $default-theme-color;
        }

        &::placeholder {
            color: $default-text-color-light;
        }

        &:-moz-placeholder {
            color: $default-text-color-light;
        }
    }

}

.room-status {
    padding: 0 !important;
    .as-reserved-status {
        color: lighten($default-gray-color, 10%) !important;
    }
    .as-in-house-dirty-status {
        color: darken($default-red-color, 25%) !important;
    }
    .as-status-detail {
        span {
            span {
                padding: 0 !important;
                color: $default-text-color-dark !important;
            }
        }
    }
}

.room-status-list-page-filter-container{
    background-color: #fff;
    border-radius: 5px;
    padding: 8px 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    .filter-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        &.vertical {
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
        }

        label {
            margin: 0 !important;
            white-space: nowrap;
        }
        input {
            &.as-form-component {
                flex: 1;
            }
        }
    }
    
    .filter-divider {
        height: 20px;
        width: 1px;
        background-color: transparentize($default-border-color, 0.5);
    }

}

.room-status-checkbox-container{
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    align-content: center;
}