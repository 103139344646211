//  ==========================================================================
//  Created Date    :   November 21, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Report styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-report-body-wrapper {
    width: 100%;
    min-height: 74vh;
    max-height: 74vh;
    overflow: auto;
    background-color: #ffffff;
    
    table {
        width: 100%;
        display: table;
        border-collapse: separate;
        border-spacing: 0;
        font-size: $default-report-font-size;
        margin: 0;
        padding: 0;

        thead {
            tr {
                th, td {
                    background-color: darken($default-theme-color, 15%);
                    color: #ffffff;
                    font-size: $default-report-font-size;
                    padding: $default-report-table-head-padding;
                    border-bottom: 1px solid $default-border-color;
                    z-index: $default-floating-container-z-index;

                    table {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        display: table;
                        border-spacing: 0;
                        border-collapse: collapse;

                        thead, tbody {
                            tr {
                                th, td {
                                    border: 0;
                                }
                            }
                        }
                    }

                }

                &.as-hidden-row {
                    display: none;
                }

            }
        }
        tbody {
            tr {
                th, td {
                    font-size: $default-report-font-size;
                    padding: $default-report-table-body-padding;

                    table {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        display: table;
                        border-spacing: 0;
                        border-collapse: collapse;

                        thead, tbody {
                            tr {
                                th, td {
                                    border: 0;
                                }
                            }
                        }
                    }

                }

                &.as-hidden-row {
                    display: none;
                }
                
            }
        }
        tfoot {
            tr {
                th, td {
                    font-size: $default-report-font-size;
                    padding: $default-report-table-body-padding;

                    table {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        display: table;
                        table-layout: fixed;
                        border-spacing: 0;
                        border-collapse: collapse;

                        thead, tbody {
                            tr {
                                th, td {
                                    border: 0;
                                }
                            }
                        }
                    }

                }
            }

            &.as-hidden-row {
                display: none;
            }
            
        }

        &.as-table-sticky {
            thead {
                tr {
                    --cell-height: 50px;
                    th, td {
                        position: sticky;
                        height: var(--cell-height);
                        min-height: var(--cell-height);
                        max-height: var(--cell-height);
                        top: -1px;
                    }

                    //  First child of tr contains hidden row for filters. So we start from second child
                    &:nth-child(2) {
                        th, td {
                            top: calc(var(--cell-height) - 1px);
                        }
                    }
                    &:nth-child(3) {
                        th, td {
                            top: calc(var(--cell-height) * 2 - 1px);
                        }
                    }
                    &:nth-child(4) {
                        th, td {
                            top: calc(var(--cell-height) * 3 - 1px);
                        }
                    }

                    //  If first child of thead is tr with the className "as-hidden-row",
                    //  set sticky position of it to -1px and next sibling also -1px
                    //  else set it to cell height
                    
                    //  First child of tr contains hidden row for filters. So we start sticky from second child
                    &.as-hidden-row {
                        ~ {
                            tr {
                                &:nth-child(2) {
                                    th, td {
                                        top: -1px;
                                    }
                                }
                                &:nth-child(3) {
                                    th, td {
                                        top: calc(var(--cell-height) - 1px);
                                    }
                                }
                                &:nth-child(4) {
                                    th, td {
                                        top: calc(var(--cell-height) * 2 - 1px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            tbody {
                table {
                    thead {
                        tr {
                            td, th {
                                position: unset !important;
                                vertical-align: middle;
                                background-color: unset;
                            }
                        }
                    }
                }
            }
        }

    }


    &.report-container-with-sticky-cell {
        table {
            thead, tbody {
                tr {
                    td, th {
                        &.sticky-report-cell {
                            --_sticky-left: 0px;
                            position: sticky !important;
                            left: var(--_sticky-left) !important;
                            
                            &.header {
                                z-index: 5;
                            }
                            &.body {
                                background-color: #fff;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.as-hidden-row {
    display: none;
}

.as-table-wrapper {
    width: 100%;
    max-height: 72vh;
    overflow: auto;
    // padding: 20px 0px;
    
    table {

        &.as-table-sticky {
            thead {
                tr {
                    th, td {
                        position: sticky;
                        top: 0px;
                        z-index: 3;
                    }
                }
            }
        }

    }

    &.header-with-input {
        table {
            &.as-table-sticky {
                thead {
                    tr {
                        th, td {
                            position: sticky;
                            top: 0px;
                            z-index: 3;
                            box-shadow: 1px 1px 1px $default-border-color;
                        }
                        --sticky-cell-height: 60px;
                        &:nth-child(1) {
                            th, td {
                                height: var(--sticky-cell-height);
                                max-height: var(--sticky-cell-height);
                                overflow: hidden;
                                text-size-adjust: auto;
                            }
                        }
                        &:nth-child(2) {
                            th, td {
                                height: var(--sticky-cell-height);
                                max-height: var(--sticky-cell-height);
                                overflow: hidden;
                                text-size-adjust: auto;
                                position: sticky;
                                top: var(--sticky-cell-height);
                                z-index: 3;
                                box-shadow: 1px 1px 1px $default-border-color;
                            }   
                        }
                    }
                }
            }
        }
    }

}

table {
    thead, tbody, tfoot {
        tr {
            &.as-primary-highlight {
                th, td {
                    background-color: lighten($default-theme-color, 48%) !important;
                }
            }
            &.as-secondary-highlight {
                th, td {
                    background-color: lighten(gray, 48%) !important;
                }
            }
        }
    }

    tr {
        &.as-primary-highlight {
            th, td {
                background-color: lighten($default-theme-color, 48%) !important;
            }
        }
        // &.as-secondary-highlight {
        //     th, td {
        //         background-color: lighten(gray, 48%) !important;
        //     }
        // }
    }
}

tr {
    &.as-primary-highlight {
        background-color: lighten($default-theme-color, 48%) !important;
        th, td {
            background-color: lighten($default-theme-color, 48%) !important;
        }
    }
    &.as-secondary-highlight {
        &.dark {
            th, td {
                background-color: lighten($default-border-color, 5%) !important;
                color: $default-text-color-dark;
            }
            &.transparent {
                th, td {
                    background-color: transparentize($default-text-color-dark, 0.74) !important;
                }
            }
        }
        &.light {
            th, td {
                background-color: lighten($default-border-color, 15%) !important;
            }
            &.transparent {
                th, td {
                    background-color: transparentize($default-border-color, 0.8) !important;
                }
            }
        }
        &.red {
            th, td {
                background-color: lighten($default-red-color, 35%) !important;
                color: darken($default-red-color, 35%) !important;
            }
            &.transparent {
                th, td {
                    background-color: transparentize($default-red-color, 0.8) !important;
                }
            }
        }
        &.yellow {
            th, td {
                background-color: lighten($default-yellow-color, 35%) !important;
                color: darken($default-yellow-color, 35%) !important;
            }
            &.transparent {
                th, td {
                    background-color: transparentize($default-yellow-color, 0.8) !important;
                }
            }
        }
        &.green {
            th, td {
                background-color: lighten($default-green-color, 35%) !important;
                color: darken($default-green-color, 35%) !important;
            }
            &.transparent {
                th, td {
                    background-color: transparentize($default-green-color, 0.8) !important;
                }
            }
        }
        &.blue {
            th, td {
                background-color: lighten($default-blue-color, 35%) !important;
                color: darken($default-blue-color, 35%) !important;
            }
            &.transparent {
                th, td {
                    background-color: transparentize($default-blue-color, 0.8) !important;
                }
            }
        }
        &.theme {
            th, td {
                background-color: lighten($default-theme-color, 35%) !important;
                color: darken($default-theme-color, 35%) !important;
            }
            &.transparent {
                th, td {
                    background-color: transparentize($default-theme-color, 0.8) !important;
                }
            }
        }
    }
}

//  Media Queries for Print
@media print {
    table {
        thead, tbody, tfoot {
            tr {
                &.as-hidden-row {
                    display: block;
                }
            }
        }
    }
}