@import 'variables.scss';

.popover-keyboard-container {
    width: 650px;
    &.numeric {
        width: 300px;
    }

    .onscreen-keyboard-style {
        background-color: lighten($default-border-color, 5%) !important;
        font-size: 16px;
        font-weight: 500;
        color: $default-text-color-dark;
    }
}

.hg-row {
    &:last-child {
        .hg-button {
            flex: 1;
        }
    }
}