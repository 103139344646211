//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Mixins used on different files.
//                      When there are same block of styles for many classes,
//                      we use mixins as centralized styles and use them in
//                      every block of classes.
//                      (Editor can update existing or add new mixins.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "../variables.scss";

//  For Buttons
@mixin btnProp {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    font-size: .875rem;
    letter-spacing: .25px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    box-shadow: 1px 1px 4px 0px $default-border-color;
}

//  For Loader
@mixin loaderProp {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}