//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Loader styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-loader-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 5px;
}

.as-loader-overlay-inner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.as-loader-overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    div {
        width: 10px;
        height: 10px;
        background-color: transparentize($default-theme-color, 0.5);
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: dot-bounce 1s infinite ease-in-out both;
        animation: dot-bounce 1s infinite ease-in-out both;
    }
    .dot1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    .dot2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
}

.as-page-loader-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $default-text-color;
    position: fixed;
    z-index: $default-page-loader-z-index;
    .as-loader-overlay-inner {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .as-loader-overlay-content {
            position: fixed;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            div {
                background-color: #ffffff;
            }
        }
        .as-loader-text {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            font-size: large;
            font-weight: bold;
        }
    }
}

@-webkit-keyframes dot-bounce {
    0%, 80%, 100% {
        -webkit-transform: scale(0); 
    }
    40% {
        -webkit-transform: scale(1.0);
    }
}

@keyframes dot-bounce {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.as-loader-from-top-overlay {
    position: fixed;
    top: 15px;
    left: 100px;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $default-loader-from-top-z-index;
    -webkit-animation: slideFromTop .4s ease-in-out;
    animation: slideFromTop .4s ease-in-out;
    
    div {
        &.as-loader-from-top-overlay-content {
            padding: 5px 15px;
            background-color: transparentize($default-theme-color, 0.8);
            color: $default-text-color-dark;
            border: 1px solid $default-theme-color;
            border-radius: 5px;
            font-size: 12px;

            &.info {
                background-color: transparentize($default-blue-color, 0.8);
                border-color: $default-blue-color;
            }
            &.warning {
                background-color: transparentize($default-yellow-color, 0.8);
                border-color: $default-yellow-color;
            }
            &.error {
                background-color: transparentize($default-red-color, 0.8);
                border-color: $default-red-color;
            }
            &.gray {
                background-color: transparentize($default-gray-color, 0.8);
                border-color: $default-gray-color;
            }
        }
    }

}


@keyframes slideFromTop {
    0%{
        top: 0px;
        opacity: 0;
    }
    25% {
        top: 5px;
        opacity: 0.2;
    }
    50%{
        top: 10px;
        opacity: 0.5;
    }
    75% {
        top: 12px;
        opacity: 0.7;
    }
    100%{
        top: 15px;
        opacity: 1;
    }
}

@-webkit-keyframes slideFromTop {
    0%{
        top: 0px;
        opacity: 0;
    }
    25% {
        top: 5px;
        opacity: 0.2;
    }
    50%{
        top: 10px;
        opacity: 0.5;
    }
    75% {
        top: 12px;
        opacity: 0.7;
    }
    100%{
        top: 15px;
        opacity: 1;
    }
}


//  Loader with progress bar
.as-progress-bar-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparentize(#ffffff, 0.4);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    z-index: $default-alert-z-index;
    -webkit-animation: popFromMiddle .4s ease-in-out;
    animation: popFromMiddle .4s ease-in-out;
    
    div {
        &.as-progress-bar-loader-content {
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // flex-direction: column;
            text-align: center;
            animation: popFromMiddle 0.3s ease-in-out;
            -webkit-animation: popFromMiddle 0.3s ease-in-out;
            -moz-animation: popFromMiddle 0.3s ease-in-out;
            position: relative;

            span {
                &.as-progress-bar-loader-value {
                    font-weight: 600;
                    font-size: 16px;
                    color: $default-text-color-dark;
                }
                &.as-progress-bar-loader-message {
                    color: $default-text-color-dark;
                }
            }
        }
    }

}

@keyframes popFromMiddle {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes popFromMiddle {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@-moz-keyframes popFromMiddle {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.continuous-loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .continuous-loader {
        svg {
            &.aegis-icon {
                width: 25px;
                height: 25px;
                path {
                    fill: transparent;
                    stroke-width: 15;
                    stroke-dasharray: 768;
                    stroke-dashoffset: 768;
                    stroke: $default-theme-color;
                    animation: animateLoaderIcon 1.5s infinite;
                }
            }
        }

    }

    @keyframes animateLoaderIcon {
        0% {
            stroke-dashoffset: 768;
        }
        50% {
            stroke-dashoffset: 1536;
            fill: transparent;
        }
        100% {
            stroke-dashoffset: 1536;
            fill: $default-theme-color;
        }
    }
    
    @keyframes animateLoaderIndicatorPoint {
        0% {
            box-shadow: 5px 0 transparent, 10px 0 transparent;
        }
        50% {
            box-shadow: 5px 0 $default-theme-color, 10px 0 transparent;
        }
        100% {
            box-shadow: 5px 0 $default-theme-color, 10px 0 $default-theme-color;
        }
    }

}