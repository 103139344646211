@import "variables.scss";

svg {
    &.as-local-svg {
        &.grid-icon {
            g {
                path {
                    fill: $default-text-color-light !important;
                }
            }
        }
    }
}


.sl-data-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .icon-wrapper {
        // --_data-icon-size: 25px;
        // width: var(--_data-icon-size);
        // height: var(--_data-icon-size);
        // border-radius: calc(var(--_data-icon-size) * 2);
        // background-color: transparentize($default-border-color, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }

    .data {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        label {
            font-size: 12px;
            line-height: 12px;
            color: $default-text-color-light;
            white-space: nowrap;
        }

        span {
            &.value {
                font-size: 14px;
                line-height: 14px;
                color: $default-text-color-dark;
                font-weight: 500;
                white-space: nowrap;
                text-transform: uppercase;
                min-width: 150px;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;

                &.meal-plan {
                    min-width: 100px;
                    max-width: 100px;
                }
                &.company {
                    min-width: 250px;
                    max-width: 250px;
                }
            }
        }
    }
}


.scrollable-list-container {

    .scrollable-list {
        max-width: 100%;
        overflow: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
        transition: all 0.2s ease-in-out;
        position: relative;

        .scrollable-list-data {
            --_bg-c: #fff;
            --_list-container-padding: 15px;
            border: 0px solid transparent;
            box-shadow: 0px 2px 10px transparentize(#000000, 0.94);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            background-color: rgba(255, 255, 255, 0.85);

            .scrollable-list-fixed-container {
                position: sticky;
                align-self: stretch;
                left: 0;
                width: 25vw;
                min-width: 250px;
                background-color: var(--_bg-c);
                padding: var(--_list-container-padding);
                margin: 0;
                border-left: 6px solid rgba(255, 255, 255, 0.95);
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                background-color: rgba(255, 255, 255, 0.95);
                backdrop-filter: blur(10px);
                -webkit-backdrop-filter: blur(10px);
                box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.06);

                .fixed-content {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;

                    .option-container {
                        button {
                            --_opt-btn-size: 30px;
                            width: var(--_opt-btn-size);
                            height: var(--_opt-btn-size);
                            margin: 0;
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid transparent;
                            border-radius: calc(var(--_opt-btn-size) * 2);
                            background-color: transparentize($default-border-color, 0.65);
                            color: $default-text-color-light;
                        }
                    }

                    .guest-detail-container, .transportation-schedule-detail-container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-direction: column;
                        gap: 2px;

                        span {
                            &.guest-name, &.schedule-status {
                                font-size: clamp(1rem, 2vw, 1.25rem);
                                color: $default-text-color-dark;
                                overflow-wrap: break-word;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-clamp: 2;
                                -webkit-box-orient: vertical;
                                font-weight: 500;
                            }

                            &.reservation-number, &.reservation-status {
                                // background-color: transparentize($default-green-color, 0.9);
                                // border: 1px solid transparentize($default-green-color, 0.9);
                                // padding: 5px 6px;
                                color: $default-green-color;
                                white-space: nowrap;
                                border-radius: 4px;
                                font-size: 1rem;
                                font-weight: bold;

                                span {
                                    font-size: 1.1rem;
                                }

                                &.reservation-status {
                                    color: $default-red-color;
                                }
                            }
                        }

                        .active-status {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 4px 8px;
                            background-color: transparentize($default-text-color-light, 0.25);
                            border-radius: 15px;
                            
                            &.active {
                                background-color: transparentize($default-green-color, 0.25);
                            }

                            span {
                                color: #ffffff;
                                font-size: 11px;
                                font-weight: 500;
                            }
                        }
                    }
                }

                &.checked-in {
                    border-left: 6px solid transparentize($default-red-color, 0.5);
                }

                &.partial-checked-in {
                    border-left: 6px solid transparentize($default-yellow-color, 0.5);
                }

                &.arriving-today {
                    border-left: 6px solid transparentize($default-green-color, 0.5);
                }

                &.upcoming-reservation {
                    border-left: 6px solid transparentize($default-gray-color, 0.5);
                }

                &.direct-schedule {
                    border-left: 6px solid transparentize($default-green-color, 0.5);
                }

                &.requested {
                    border-left: 6px solid transparentize($default-yellow-color, 0.5);
                }

                &.cancelled {
                    border-left: 6px solid transparentize($default-red-color, 0.5);
                }

            }

            .scrollable-container {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                align-self: stretch;
                gap: 5px;

                .information-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 30px;
                    flex-wrap: nowrap;
                    width: auto;
                    padding: var(--_list-container-padding);
                }

                .room-list-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;
                    width: 100%;
                    padding: 0 var(--_list-container-padding) calc(var(--_list-container-padding) * 0.75);

                    .icon {
                        color: $default-text-color-light;
                    }
                    
                    span {
                        font-size: 12px;
                        line-height: 12px;
                        margin: 0;
                        padding: 0;
                        color: $default-text-color-light;

                        &.room {
                            color: $default-text-color-dark;
                            white-space: nowrap;
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
