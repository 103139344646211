//  ==========================================================================
//  Created Date    :   July 12, 2022
//  Created By      :   Himal Poudel
//  Description     :   This file consists POS's Event Position styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

:root {
    --confirmed_color: #6be598;
    --provisional_color: #1f8ffb;
    --waitlisted_color: #f7af32;
}

.as-event-booking-filter-wrapper {

    background-color: $default-body-background-color;
    border-radius: 10px 10px 0 0;
    padding-top: 5px;

    .filter-container {
        background: rgb(255, 255, 255);
        padding: 0 0 0 10px;
        border-radius: 5px;
        border: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        span {
            white-space: nowrap;
            color: $default-text-color-light;
        }
    
        .input-fields {
            color: $default-text-color;
            outline: none;
            border-radius: 5px;
            border: none;
            box-shadow: none !important;
            margin: 0;
        }
    }
}


.as-event-booking-calendar {
    width: 100%;
    max-height: 62vh;
    overflow: auto;

    table {
        &.as-event-position-table {
            display: table;
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0px;
            background: #ffffff;
            text-align: center;
            font-size: $default-hotel-position-font-size;

            colgroup {
                col {
                    &.active-date {
                        background: lighten($default-theme-color, 45%);
                    }
                }
            }
    
            thead {
                tr {
                    th, td {
                        height: 80px;
                        padding: 8px;
                        border: 1px solid lighten($default-border-color, 16%);
                        box-shadow: 2px 1px 1px lighten($default-border-color, 16%);
                        background-color: #ffffff;
                        position: sticky;
                        position: -webkit-sticky;
                        top: 0;
                        z-index: 2;
                    }
    
                    &.header-row {
                        th, td {
                            &.column-title {
                                position: sticky;
                                position: -webkit-sticky;
                                left: -1px;
                                z-index: 3;

                                span {
                                    display: block;
                                }
                            }
    
                            &.column-time {
                                span {
                                    &.month {
                                        display: block;
                                        margin: 0 auto;
                                        color: $default-text-color;
                                        font-weight: bolder;
                                        font-size: 12px;
                                        padding: 5px 0 0;
                                        white-space: nowrap;
                                    }
                                    &.day {
                                        display: block;
                                        padding: 0 0 5px;
                                        margin: 0 auto;
                                        color: $default-text-color-light;
                                        font-weight: bold;
                                        font-size: 10px;
                                        white-space: nowrap;
                                    }
                                    &.time {
                                        display: block;
                                        margin: 0 auto;
                                        color: $default-text-color-dark;
                                        font-weight: bolder;
                                        font-size: 12px;
                                        padding: 5px 0 0;
                                        white-space: nowrap;
                                    }
                                }
                            }

                            &.active-time {
                                background-color: lighten($default-theme-color, 45%);
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    th, td {
                        padding: 10px;
                        border: 1px solid lighten($default-border-color, 16%);
                        overflow: visible;
                        
                        &.active-time {
                            background: transparent;
                            color: $default-text-color-dark;
                        }

                        &:first-child, &:last-child {
                            overflow: hidden;
                        }
                        
                        &:nth-child(1) {
                            background: #ffffff;
                            position: sticky !important;
                            position: -webkit-sticky !important;
                            left: -1px !important;
                            z-index: 1 !important;
                        }

                        &.event-position-wrapper {
                            position: relative;

                            .booking-status-bar {
                                position: absolute;
                                background: $default-border-color;
                                margin: 0;
                                width: 104%;
                                min-height: 22px;
                                max-height: 22px;
                                top: 50%;
                                left: 50%;
                                -moz-transform: translate(-50%, -50%);
                                -webkit-transform: translate(-50%, -50%);
                                -o-transform: translate(-50%, -50%);
                                -ms-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);

                                &.confirmed {
                                    background: var(--confirmed_color);
                                }
                                &.wait-listed {
                                    background: var(--waitlisted_color);
                                }
                                &.provisional {
                                    background: var(--provisional_color);
                                }

                                &.starts {
                                    width: 60%;
                                    top: 50%;
                                    left: 50%;
                                    -moz-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -webkit-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -o-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -ms-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    margin-left: 4px;
                                }

                                &.ends {
                                    width: 60%;
                                    top: 50%;
                                    left: -15%;
                                    -moz-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -webkit-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -o-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    -ms-transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    transform: translate(0%, -50%) skew(-25deg, 0deg);
                                    margin-right: 6px;
                                }

                                &.starts-ends {
                                    width: 50%;
                                    top: 50%;
                                    left: 25%;
                                    -moz-transform: translate(-25%, -50%) skew(-25deg, 0deg);
                                    -webkit-transform: translate(-25%, -50%) skew(-25deg, 0deg);
                                    -o-transform: translate(-25%, -50%) skew(-25deg, 0deg);
                                    -ms-transform: translate(-25%, -50%) skew(-25deg, 0deg);
                                    transform: translate(-25%, -50%) skew(-25deg, 0deg);
                                    margin-left: 4px;
                                }
                            }
                        }

                    }

                    &.outlet-row, &.area-row {
                        th, td {
                            padding: 10px 5px 10px 10px;
                            color: $default-text-color-dark;
                            // cursor: pointer !important;

                            &.outlet-name, &.area-name {
                                text-align: left;
                            }

                        }
                    }

                    &.outlet-row {
                        th, td {
                            background: darken(#ffffff, 5%);
                        }
                    }

                    &.area-row {
                        th, td {
                            background: darken(#ffffff, 2%);
                        }
                    }

                }
            }

        }

    }

    &.full-screen {
        max-height: 85vh;
    }
}


.event-calendar-full-screen {
    position: relative;

    .as-event-calendar-full-screen-overlay {
        position: fixed;
        z-index: $default-modal-z-index;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        backdrop-filter: blur(10px);

        .full-screen-content {
            border-radius: 10px;
            overflow: hidden;

            background-color: #ffffff;
            background-color: transparentize(#ffffff, 0.1);
            padding-left: $default-sidebar-width;
            border: 1px solid transparent;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.2);
            animation: showFullscreen 0.5s ease-in-out;

            @media only screen and (max-width: 560px) {
                padding-left: $default-sidebar-width-small;
            }

            @keyframes showFullscreen {
                from {
                    top: -300px;
                    opacity: 0;
                }
                to {
                    top: 15%;
                    opacity: 1;
                }
            }

            @-webkit-keyframes showFullscreen {
                from {
                    top: -300px;
                    opacity: 0;
                }
                to {
                    top: 15%;
                    opacity: 1;
                }
            }
        }
    }
}

.status-indicator-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding: 15px;

    .icon-confirmed {
        color: var(--confirmed_color);
    }

    .icon-provisional {
        color: var(--provisional_color);
    }

    .icon-waitlisted {
        color: var(--waitlisted_color);
    }
}