//  ==========================================================================
//  Created Date    :   April 20, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Multiple Tabs styles.
//@extend               (Both Horizontal and Vertical Tabs)
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

//  Multiple Tabs   (Horizontal)
.as-multiple-tab {
    box-sizing: border-box;
    // overflow: hidden;
    border: none;
    background-color: transparent;
    // position: relative;

    .as-multiple-tab-header {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid $default-border-color;


        button {
            &.as-multiple-tab-button {
                font-family: $default-font-family;
                background-color: transparent;
                outline: none;
                border: none;
                border-bottom: 2px solid transparent;
                cursor: pointer;
                padding: 0 15px 5px;
                margin: 0 5px -1px;
                font-size: 14px;
                color: $default-text-color-light;
                transition: all .4s;

                &.as-multiple-tab-active-button {
                    color: $default-theme-color;
                    font-weight: 600;
                    border-bottom: 2px solid $default-theme-color;

                    &:hover {
                        color: $default-theme-color;
                        border-bottom-color: $default-theme-color;
                    }

                    &:focus {
                        color: darken($default-theme-color, 5%);
                        border-bottom-color: darken($default-theme-color, 5%);
                    }
                }

                &:hover {
                    color: $default-text-color-light;
                    border-bottom-color: $default-text-color-light;
                }

                &:focus {
                    color: $default-text-color;
                    border-bottom-color: $default-text-color;
                }

            }
        }

    }

    .as-multiple-tab-body {
        display: none;
        height: auto;
        margin-top: -1px;
        padding: 10px 0px;
        transition: all 0.4s;
        // max-height: 76vh;
        // overflow: auto;

        &.as-multiple-tab-active-content {
            display: block;
        }

    }
}


//  Vertical Tabs
.as-vt-tab {
    display: table;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid $default-border-color;
    border-radius: 5px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    min-height: 200px;
}

.as-vt-tab-button-wrapper {
    display: table-cell;
    width: 25% !important;
    background-color: $default-body-background-color;
    vertical-align: top;

    button {

        &.as-tab-button {
            display: block;
            background-color: transparent;
            color: $default-text-color-light;
            padding: 15px;
            width: 100%;
            border: none;
            border-bottom: 1px solid $default-border-color;
            outline: none;
            text-align: left;
            cursor: pointer;
            transition: all 0.4s;
            font-size: 15px;

                &.as-tab-button-active {
                    color: $default-theme-color;
                    background-color: #ffffff;
                }

        }
    }
}

.as-vt-tab-content-wrapper {
    display: table-cell;
    width: 75% !important;
    padding: 15px;
    background-color: transparent;
    vertical-align: top;
}

.as-vt-tabcontent {
    max-height: 80vh;
    overflow: auto;
    display: none;
}

.as-vt-tabcontent-active {
    display: block;
}


//  Used in POS
ul {
    li {
        &.as-outlet-list {
            display: inline-block;
            list-style: none;
            padding: 0px;
            text-align: center;
            position: relative;

            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
        }
    }
}

.as-tab-content-message {
    color: $default-text-color-light;
    font-size: $default-font-size;
}

.as-tab-content-message-large {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: transparentize($default-text-color-light, 0.64);
    font-size: 40px;
    text-align: center;
}



//  For extra extra small screens with width less than 560px.
@media only screen and (max-width: 560px) {

    .as-multiple-tab {
    
        .as-multiple-tab-header {
            width: 100%;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid $default-border-color;
    
            button {
                &.as-multiple-tab-button {
                    width: 100%;
                    outline: none;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    padding: 10px 0;
                    margin: 5px;
                    font-size: 12px;
                    color: $default-text-color-light;
    
                    &.as-multiple-tab-active-button {
                        background-color: $default-theme-color;
                        color: #ffffff;
                        border-bottom: none;
                    }
    
                    &:focus {
                        color: #ffffff;
                        background-color: $default-text-color-light;
                        border: none;
                    }
    
                }
            }
    
        }
    
        .as-multiple-tab-body {
            margin-top: 0;
            padding: 10px 0px;
    
            &.as-multiple-tab-active-content {
                display: block;
            }
    
        }
    }

}