.scrollablesidebar {
    position: fixed;
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    top: 60px;
    height: 100vh;
    overflow-y: hidden;
    background-color: #f8f8f8;
    padding-top: 15px;
    padding-bottom: 100px;
    box-shadow: 0px 0px 0px 0px;
    z-index: 8;
  }
  .scrollablesidebar:hover {
    overflow-y: overlay;
  }
  
  ul.menulist, ul.subMenuList {
    padding: 0;
    margin: 0;
  }
  ul lsvg.menuGroup, ul lsvg.subMenuGroup, ul lsvg.thirdSubMenuGroup {
    list-style: none;
  }
  ul li a.listLink {
    display: block;
    text-decoration: none;
    color: gray;
    width: 100%;
    font-weight: normal;
    padding: 0px 20px;
    line-height: 40px;
    border-radius: 0 20px 20px 0;
    transition: 0.2s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  ul li a.listLink:hover {
    color: dodgerblue;
    transition: color ease-in-out;
  }
  ul li a.listLink:focus, ul li a.listLink:active, ul li a.listLink.active {
    /* color: dodgerblue;
    background-color: rgba(30, 144, 255, 0.1); */
  }
  ul li a.listLink svg {
    margin-right: 15px;
  }
  ul li a.listLink svg.fa-caret-right {
    float: right;
    margin-top: 12.5px;
    margin-right: 5px;
  }
  ul span.list-category {
    display: block;
    padding: 10px 15px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    color: #9a9a9a;
  }
  ul.secondLevelUL {
    padding-left: 0;
  }
  ul.secondLevelUL li a.secondaryListItem {
    padding-left: 50px;
  }
  ul.thirdLevelUL {
    padding-left: 0;
  }
  ul.thirdLevelUL li a.secondaryListItem {
    padding-left: 65px;
  }
  
  .active {
    color: dodgerblue;
    background-color: rgba(30, 144, 255, 0.1);
  }
  
  .hidden {
    display: none !important;
    transition: display 1s ease-in-out;
  }
  
  .show {
    display: block;
    transition: display 1s ease-in-out;
  }
  
  .scrollablesidebar::-webkit-scrollbar {
    width: 4px;
  }
  .scrollablesidebar::-webkit-scrollbar-track {
    background: transparent;
  }
  .scrollablesidebar::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 2px;
  }
  .scrollablesidebar::-webkit-scrollbar-thumb:hover {
    background: gray;
  }
  
  .minimizer {
    width: 250px;
    position: fixed;
    bottom: 0;
    height: 40px;
    float: left;
    padding: 0;
    margin: 0;
    border-top: 1px solid #cccccc;
    z-index: 9;
    background-color: #f8f8f8;
  }
  
  .minimize-btn {
    float: right;
    bottom: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0px;
    border: none;
    outline: none;
    background-color: transparent;
    color: gray;
    font-size: 20px;
    font-weight: bolder;
    transition: all 0.8s;
    cursor: pointer;
  }
  .minimize-btn:hover {
    background-color: rgba(30, 144, 255, 0.1);
    color: dodgerblue;
    transition: background-color ease-in-out;
  }
  .minimize-btn:hover .bouncing {
    animation: bounceLeft 2s infinite;
  }
  
  @keyframes bounceLeft {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(-10px);
    }
    60% {
      transform: translateX(-10px);
    }
  }
  .maximizer {
    width: 40px;
    position: fixed;
    bottom: 0;
    height: 40px;
    float: left;
    padding: 0;
    margin: 0;
    z-index: 8;
    display: none;
  }
  
  .maximize-btn {
    bottom: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 10px 0px 10px 10px;
    margin-left: -15px;
    background-color: #919191;
    border: 1px solid #919191;
    border-radius: 0 50% 50% 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    outline: none;
    color: white;
    font-size: 18px;
    font-weight: bolder;
    transition: all 0.5s;
    cursor: pointer;
    opacity: 0.8;
  }
  .maximize-btn:hover {
    margin-left: 0;
    padding-right: 10px;
    background-color: dodgerblue;
    color: white;
    border-color: dodgerblue;
    transition: color background-color border-color padding-right margin-left 0.5s ease-in-out;
  }