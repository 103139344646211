/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

*.unselectable,.as-dropdown,a {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scroll-behavior: smooth;
}


/* Use spinner class where you want to spin object in circle. */
@keyframes spinner {
  to { transform: rotate(360deg); }
}
.spinner {
  animation: spinner 1s linear infinite;
}
/* Use is where you want to display none */

.display-none{
  display: none !important;
}

/* Use shake class where you want to shake the object */
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.shake{
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s !important;

  /* Shake for a second */
  animation-iteration-count: 1 !important;
}

/* For focusing element */

button:focus{
  border: 2px solid dodgerblue;
}

.body-overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.98);
  z-index: 4;
  cursor: pointer;
  overflow: hidden;
}


button{
  margin:1px;
}

/* Mega Table Body To Have Min Width showable */
.as-mega-table-body{
  min-height: 50vh;
}

/* Setting minimum hight for tab content */
.as-hz-tabcontent{
  min-height: 25vh;
}

/*Disabled Form Input*/
input[type="text"]:disabled,
input[type="number"]:disabled,
input[type="tel"]:disabled,
input[type="email"]:disabled,
textarea:disabled,
input:disabled{
  background:hsl(0,0%,95%) !important;
}

/*
FOR UPPER CASE OF TEXT INPUT
*/
.uppercase { 
  text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: capitalize !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: capitalize !important;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: capitalize !important;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: capitalize !important;
}
::placeholder { /* Recent browsers */
  text-transform: capitalize !important;
}

/* Date picker to take col size */
.react-datepicker-wrapper{
  width: 100% !important;
}

/*css of mega table filter element.*/
.as-mega-table-filter-row>td{
  text-align: left !important;
  font-weight: 100 !important;
}

.display-none {
  display: none !important;
}

.block {
  display: block !important;
}