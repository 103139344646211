.topmenubar {
    position: fixed;
    z-index: 10;
    width: 100%;
    float: right;
    top: 0;
    font-size: 14px;
    color: #727272;
    background: white;
    padding: 0px 25px;
    box-shadow: 0px 0px 8px -4px;
    height: 60px;
    transition: all 0.4s ease-in-out;
  }
  
  ul.topmenubar-item {
    display: block;
    margin: 0;
    padding: 0;
    float: left;
    height: auto;
    width: auto;
    border: none;
    outline: none;
  }
  ul li.topmenubar-li {
    list-style: none;
    display: inline-block;
    padding: 5px 15px;
    margin: 0;
    transition: all 0.4s ease-in-out;
  }
  ul li.topmenubar-li a.topmenubar-links {
    text-decoration: none;
    font-size: 16px;
    line-height: 3;
    color: gray;
  }
  ul li.topmenubar-li a.topmenubar-links i {
    text-decoration: none;
    font-size: 16px;
    line-height: 3;
    color: gray;
  }
  ul li.topmenubar-li.brand-item {
    line-height: 3;
    transition: all 0.4s ease-in-out;
  }
  
  .item-left {
    padding: 0;
  }
  
  .float-right {
    float: right !important;
  }
  
  .company-text {
    color: dodgerblue;
    font-weight: 600;
  }
  
  .notification {
    margin-left: -10px;
    width: 5px;
    height: 5px;
    min-width: 5px;
    border-radius: 50%;
    line-height: 5px;
    font-size: smaller;
    padding: 2px 4px;
  }
  
  .search-box {
    width: 500px;
    height: 40px;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    padding: 5px 15px 5px 40px;
    outline: none;
    color: #6e6e6e;
    background-color: #eeeeee;
    background: url(/public/Images/Icons/search_icon.png) no-repeat left center #eeeeee;
    background-size: 15px;
    background-position-x: 15px;
    transition: all 0.4s ease-in-out;
  }
  .search-box:hover {
    box-shadow: 0px 0px 4px dodgerblue;
    cursor: pointer;
  }
  .search-box:focus {
    border: 1px solid #bdbdbd;
    color: #727272;
  }
  .search-box:focus::-webkit-input-placeholder, .search-box:focus:-ms-input-placeholder, .search-box:focus::placeholder {
    color: #bdbdbd;
  }