@import "variables.scss";

.financial-container {
    --_card-spacing: 1rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: var(--_card-spacing);
    padding: var(--_card-spacing) 0;

    .info-card-content {
        box-sizing: border-box;
        border-radius: var(--_card-spacing);
        box-shadow: none;
        display: grid;
        gap: var(--_card-spacing);
        grid-template-columns: 1fr 1fr 1fr;
    }

    .profit-margin-chart {
        box-sizing: border-box;
        background-color: #ffffff;
        box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
        border: 2px solid transparentize($default-purple-accent, 0.5);
        border-radius: calc(var(--_card-spacing) * 0.5);
        padding: var(--_card-spacing);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        backdrop-filter: blur(9px);
        -webkit-backdrop-filter: blur(9px);


        .total-collection-container {
            background-color: transparentize($default-purple-accent, 0.2);
            width: 100%;
            padding: 15px 20px;
            border-radius: calc(var(--_card-spacing) * 0.5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            backdrop-filter: blur(9px);
            -webkit-backdrop-filter: blur(9px);
            span {
                color: #ffffff;
                &.title {
                    font-size: 1.25rem;
                }
                &.value {
                    font-size: 1.25rem;
                }
            }
        }
    }
}


.acc-dashboard-info-card {
    --card-spacing: 1rem;
    --__info-icon-wrapper-size: 40px;

    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: calc(var(--card-spacing) * 0.5);
    box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
    padding: 15px 15px 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    overflow: hidden;

    &.green {
        border-left: calc(var(--card-spacing) * 0.5) solid transparentize($default-green-color, 0.5);
        .icon-wrapper {
            background-color: transparentize($default-green-color, 0.15);
        }
    }
    &.red {
        border-left: calc(var(--card-spacing) * 0.5) solid transparentize($default-red-color, 0.5);
        .icon-wrapper {
            background-color: transparentize($default-red-color, 0.15);
        }
    }
    &.blue {
        border-left: calc(var(--card-spacing) * 0.5) solid transparentize($default-blue-color, 0.5);
        .icon-wrapper {
            background-color: transparentize($default-blue-color, 0.15);
        }
    }
    &.purple {
        border-left: calc(var(--card-spacing) * 0.5) solid transparentize($default-purple-accent, 0.5);
        .icon-wrapper {
            background-color: transparentize($default-purple-accent, 0.15);
        }
    }
    &.yellow {
        border-left: calc(var(--card-spacing) * 0.5) solid transparentize($default-yellow-color, 0.5);
        .icon-wrapper {
            background-color: transparentize($default-yellow-color, 0.15);
        }
    }

    .icon-wrapper {
        width: var(--__info-icon-wrapper-size);
        height: var(--__info-icon-wrapper-size);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: calc(var(--__info-icon-wrapper-size) * 2);
        margin-bottom: calc(var(--__info-icon-wrapper-size) * 0.5);

        .icon {
            font-size: 14px;
            color: #ffffff;
        }
    }

    .details-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        
        span {
            &.value {
                color: $default-text-color-dark;
                font-weight: bold;
                font-size: large;
            }
            &.title {
                color: $default-text-color-light;
                margin-top: calc(var(--__info-icon-wrapper-size) * 0.1);
                font-size: 14px;
            }
        }
    }

    .comparison-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: calc(var(--__info-icon-wrapper-size) * 0.25);

        .percentage-wrapper {
            padding: 4px 8px;
            border-radius: 16px;
            background-color: transparentize($default-text-color, 0.90);
            margin-right: 4px;

            span {
                &.percentage {
                    color: $default-text-color;
                    font-weight: 500;
                }
            }

            &.negative {
                background-color: transparentize($default-red-color, 0.90);
                span {
                    &.percentage {
                        color: darken($default-red-color, 5%);
                    }
                }
            }
            &.positive {
                background-color: transparentize($default-green-color, 0.90);
                span {
                    &.percentage {
                        color: darken($default-green-color, 5%);
                    }
                }
            }
        }
        span {
            &.description {
                color: $default-text-color-light;
            }
        }
    }
}


.pl-receivable-summary-container {
    --_card-spacing: 1rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--_card-spacing);

    .pl-summary-container {
        box-sizing: border-box;
        border-radius: calc(var(--_card-spacing) * 0.5);
        box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
        overflow: hidden;
    }

    .receivable-summary-container {
        box-sizing: border-box;
        background-color: #ffffff;
        box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
        border-radius: calc(var(--_card-spacing) * 0.5);
        overflow: hidden;
    }
}


.as-ac-dashboard-card {
    --master-padding: 20px;
    --card-background-color: #ffffff;
    --header-footer-min-height: 50px;
    box-sizing: border-box;
    background-color: var(--card-background-color);
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding-bottom: 0;
    overflow: hidden;
}

.acc-dashboard-info-card-header {
    --master-padding: 20px;
    padding-bottom: 10px;
    min-height: var(--header-footer-min-height);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .acc-dashboard-title-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
            &.title {
                color: $default-text-color-dark;
            }
        }
        .as-radio-group-transparent {
            display: flex !important;
            justify-content: flex-start !important;
            align-items: center !important;
            input {
                &.as-radio-group-btn{
                    &:checked {
                        + label {
                            cursor: pointer !important;
                            &.as-radio-group-label {
                                color: $default-text-color !important;
                                background-color: var(--card-background-color) !important;
                            }
                        }
                    }
                }
            }
            label {
                cursor: pointer !important;
                &.as-radio-group-label {
                    background: transparent !important;
                    color: $default-border-color !important;
                    padding: 0 12px 0 0 !important;
                }
            }
        }
    
        &.with-icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .icon-wrapper {
                --title-icon-wrapper-size: 30px;
                width: var(--title-icon-wrapper-size);
                height: var(--title-icon-wrapper-size);
                background-color: transparentize($default-theme-color, 0.9);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: var(--title-icon-wrapper-size);
                margin-right: calc(var(--title-icon-wrapper-size) * 0.4);
                .title-icon {
                    color: $default-theme-color;
                    font-size: calc(var(--title-icon-wrapper-size) * 0.5);
                }
    
                &.green {
                    background-color: transparentize($default-green-color, 0.9);
                    .title-icon {
                        color: $default-green-color;
                    }
                }
                &.purple {
                    background-color: transparentize($default-purple-accent, 0.9);
                    .title-icon {
                        color: $default-purple-accent;
                    }
                }
                &.yellow {
                    background-color: transparentize($default-yellow-color, 0.9);
                    .title-icon {
                        color: $default-yellow-color;
                    }
                }
                &.red {
                    background-color: transparentize($default-red-color, 0.9);
                    .title-icon {
                        color: $default-red-color;
                    }
                }
            }
        }
    }
    
    .acc-dashboard-filter-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 2;
    
        .input-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
    
            .input-group {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                margin: 0 5px;
                label {
                    white-space: nowrap;
                    margin-right: 5px;
                    padding: 5px 0;
                }
                input {
                    &.filter-input {
                        background-color: transparent;
                        border: 1px solid transparent;
                        border-bottom: 1px solid transparentize($default-border-color, 0.64);
                        border-radius: 0;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}