@import "variables.scss";
@import "mixins/mixins.scss";

.touch-order-settlement-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    .container {
        &:nth-child(1) {
            flex: 3;
        }
        &:nth-child(2) {
            flex: 4;
        }
        &:nth-child(3) {
            flex: 1;
            border-left: 1px solid transparentize($default-border-color, 0.9);
            border-right: 1px solid transparentize($default-border-color, 0.9);
        }
    }
}

.display-data-wrapper {
    &.settlement-body {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 15px;
        column-gap: 10px;

        div {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            span {
                &.title {
                    font-size: 12px;
                    color: $default-text-color-light;
                    padding-bottom: 2px;
                }
                &.value {
                    font-size: 13px;
                    color: $default-text-color-dark;
                }
            }
        }
    }
}


.settlement-button-container {
    .settlement-modes {
        display: grid !important;
        grid-template-columns: 1fr;
        gap: 8px;
    
        input {
            &.as-radio-group-btn{
                
                &:checked {
                    + label {
                        &.as-radio-group-label {
                            color: transparentize(#ffffff, 0) !important;
                            background-color: $default-theme-color !important;
                            
                            .icon {
                                color: #ffffff !important;
                            }
    
                            small {
                                color: $default-text-color-dark !important;
                            }
    
                            &.small {
                                padding: 2px 5px !important;
                                font-size: 11px !important;
                            }
    
                            &.active-blue {
                                background-color: transparentize($default-blue-color, 0.8) !important;
                                border-color: $default-blue-color !important;
                                color: $default-text-color-dark !important;
                                .icon {
                                    color: $default-blue-color !important;
                                }
                            }
                            &.active-green {
                                background-color: transparentize($default-green-color, 0.8) !important;
                                border-color: $default-green-color !important;
                                color: $default-text-color-dark !important;
                                .icon {
                                    color: $default-green-color !important;
                                }
                            }
                            &.active-red {
                                background-color: transparentize($default-red-color, 0.8) !important;
                                border-color: $default-red-color !important;
                                color: $default-text-color-dark !important;
                                .icon {
                                    color: $default-red-color !important;
                                }
                            }
                            &.active-yellow {
                                background-color: transparentize($default-yellow-color, 0.8) !important;
                                border-color: $default-yellow-color !important;
                                color: $default-text-color-dark !important;
                                .icon {
                                    color: $default-yellow-color !important;
                                }
                            }
                            &.active-purple {
                                background-color: transparentize($default-purple-accent, 0.8) !important;
                                border-color: $default-purple-accent !important;
                                color: $default-text-color-dark !important;
                                .icon {
                                    color: $default-purple-accent !important;
                                }
                            }
                            
                        }
                        small {
                            color: transparentize($default-body-background-color, 0) !important;
                        }
                    }
                }
            }
        }
    
        .as-radio-group-label {
            border: 2px solid transparent;
            background-color: transparentize($default-border-color, 0.9) !important;
            color: $default-text-color-dark !important;
            cursor: pointer !important;
            padding: 18px 10px !important;
            text-align: center;
            font-size: 14px;
    
            &.small {
                padding: 2px 5px !important;
                font-size: 11px !important;
            }
    
        }
    
    }
}

.settlement-keyboard-container {
    padding: 5px 0;
    .onscreen-keyboard-style {
        font-size: 18px;
        font-weight: 500;
        color: $default-text-color-dark;
    }
}

.keyboard-btn-style {
    padding: 10px !important;
    background-color: red !important;
}


button {
    &.btn-suggested-amount {
        background-color: transparentize($default-green-color, 0.8);
        border: 2px solid transparentize($default-green-color, 0.8);
        padding: 8px 12px;
        border-radius: 20px;
        color: darken($default-green-color, 15%);
        margin: 0 10px 0 0;
        font-weight: 500;
    }
}