//  ==========================================================================
//  Created Date    :   April 20, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists 404 Page Error styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.errorBlock {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
    border-top: 2px dashed $default-border-color;
    border-bottom: 2px dashed $default-border-color;
}

.pageErrorCode {
    font-weight: bolder;
    font-size: 80px;
    margin-bottom: 0;
}

.pageErrorMsg {
    font-size: 35px;
    margin-top: 0;
    font-weight: bold;
    color: $default-text-color;
}

.pageErrorDetail {
    color: $default-text-color-light;
    font-size: 16px;
}