@import './variables.scss';

.fo-dashboard-column {
    padding: 5px !important;
}

.info-with-count-container {
    --_info-spacing: 10px;

    box-sizing: border-box;
    background-color: #fff;
    border-radius: var(--_info-spacing);
    box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
    padding: var(--_info-spacing);
    display: grid;
    // grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    row-gap: var(--_info-spacing);
    column-gap: calc(var(--_info-spacing) * 4);
    

    .info-with-count {
        box-sizing: border-box;
        position: relative;
        border-radius: var(--_info-spacing);
        padding: var(--_info-spacing);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .icon-wrapper {
            --_info-wrapper-icon-size: 35px;
            height: var(--_info-wrapper-icon-size);
            width: var(--_info-wrapper-icon-size);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: calc(var(--_info-wrapper-icon-size) * 0.25);
            margin-right: var(--_info-spacing);

            .icon {
                color: #ffffff;
                font-size: 1.35rem;
                line-height: 0;

                path {
                    filter: drop-shadow(10px 20px 40px transparentize(#000000, 0.75));
                }
            }
        }

        .title-value-wrapper {
            flex: 1;
            box-sizing: border-box;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // flex-direction: column;
            gap: calc(var(--_info-spacing) * 0.25);
            padding: calc(var(--_info-spacing) * 0.25) 0;

            span {
                &.title {
                    color: $default-text-color-dark;
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            .value-wrapper {
                // width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: column;

                span {
                    &.value {
                        font-weight: bolder;
                        font-size: 25px;
                        color: $default-text-color-dark;
                    }
                    &.sub-value {
                        font-weight: bold;
                        font-size: 12px;
                        color: $default-text-color-light;
                    }
                }
            }
        }

        &:hover {
            background-color: transparentize($default-body-background-color, 0.8);
        }

        &.due-in {
            .icon-wrapper {
                background-color: $default-dashboard-due-in-color;
                background: linear-gradient(140deg, lighten($default-dashboard-due-in-color, 5%), $default-dashboard-due-in-color, darken($default-dashboard-due-in-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-due-in-color, 0.65)
                // .icon {
                    // color: $default-dashboard-due-in-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-due-in-color;
            //             }
            //         }
            //     }
            // }
        }
    
        &.due-out {
            .icon-wrapper {
                background-color: $default-dashboard-due-out-color;
                background: linear-gradient(140deg, lighten($default-dashboard-due-out-color, 5%), $default-dashboard-due-out-color, darken($default-dashboard-due-out-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-due-out-color, 0.65)
                // .icon {
                    // color: $default-dashboard-due-out-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-due-out-color;
            //             }
            //         }
            //     }
            // }
        }

        &.reserved {
            .icon-wrapper {
                background-color: $default-reserved-color;
                background: linear-gradient(140deg, lighten($default-reserved-color, 5%), $default-reserved-color, darken($default-reserved-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-reserved-color, 0.65)
                // .icon {
                    // color: $default-reserved-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-reserved-color;
            //             }
            //         }
            //     }
            // }
        }
    
        &.room-position {
            .icon-wrapper {
                background-color: $default-dashboard-room-position-color;
                background: linear-gradient(140deg, lighten($default-dashboard-room-position-color, 5%), $default-dashboard-room-position-color, darken($default-dashboard-room-position-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-room-position-color, 0.65)
                // .icon {
                    // color: $default-dashboard-room-position-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-room-position-color;
            //             }
            //         }
            //     }
            // }
        }
    
        &.walk-in {
            .icon-wrapper {
                background-color: $default-dashboard-due-in-color;
                background: linear-gradient(140deg, lighten($default-dashboard-due-in-color, 5%), $default-dashboard-due-in-color, darken($default-dashboard-due-in-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-due-in-color, 0.65)
                // .icon {
                    // color: $default-dashboard-due-in-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-due-in-color;
            //             }
            //         }
            //     }
            // }
        }
    
        &.expected-occupancy {
            .icon-wrapper {
                background-color: $default-dashboard-occupancy-color;
                background: linear-gradient(140deg, lighten($default-dashboard-occupancy-color, 5%), $default-dashboard-occupancy-color, darken($default-dashboard-occupancy-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-occupancy-color, 0.65)
                // .icon {
                    // color: $default-dashboard-occupancy-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-occupancy-color;
            //             }
            //         }
            //     }
            // }
        }
    
        &.do-not-disturb {
            .icon-wrapper {
                background-color: $default-dashboard-do-not-disturb-color;
                background: linear-gradient(140deg, lighten($default-dashboard-do-not-disturb-color, 5%), $default-dashboard-do-not-disturb-color, darken($default-dashboard-do-not-disturb-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-do-not-disturb-color, 0.65)
                // .icon {
                    // color: $default-dashboard-do-not-disturb-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-do-not-disturb-color;
            //             }
            //         }
            //     }
            // }
        }
    
        &.vip-guest {
            .icon-wrapper {
                background-color: $default-dashboard-vip-guest-color;
                background: linear-gradient(140deg, lighten($default-dashboard-vip-guest-color, 5%), $default-dashboard-vip-guest-color, darken($default-dashboard-vip-guest-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-vip-guest-color, 0.65)
                // .icon {
                    // color: $default-dashboard-vip-guest-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-vip-guest-color;
            //             }
            //         }
            //     }
            // }
        }
    
        &.scanty {
            .icon-wrapper {
                background-color: $default-dashboard-scanty-color;
                background: linear-gradient(140deg, lighten($default-dashboard-scanty-color, 5%), $default-dashboard-scanty-color, darken($default-dashboard-scanty-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-scanty-color, 0.65)
                // .icon {
                    // color: $default-dashboard-scanty-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-scanty-color;
            //             }
            //         }
            //     }
            // }
        }
        
        &.recent-reservation {
            .icon-wrapper {
                background-color: $default-dashboard-recent-reservation-color;
                background: linear-gradient(140deg, lighten($default-dashboard-recent-reservation-color, 5%), $default-dashboard-recent-reservation-color, darken($default-dashboard-recent-reservation-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-recent-reservation-color, 0.65)
                // .icon {
                    // color: $default-dashboard-recent-reservation-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-recent-reservation-color;
            //             }
            //         }
            //     }
            // }
        }

        &.waitlist-reservation {
            .icon-wrapper {
                background-color: $default-dashboard-waitlist-reservation-color;
                background: linear-gradient(140deg, lighten($default-dashboard-waitlist-reservation-color, 5%), $default-dashboard-waitlist-reservation-color, darken($default-dashboard-waitlist-reservation-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-waitlist-reservation-color, 0.65)
                // .icon {
                    // color: $default-dashboard-waitlist-reservation-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-waitlist-reservation-color;
            //             }
            //         }
            //     }
            // }
        }

        &.cancelled-reservation {
            .icon-wrapper {
                background-color: $default-dashboard-cancelled-reservation-color;
                background: linear-gradient(140deg, lighten($default-dashboard-cancelled-reservation-color, 5%), $default-dashboard-cancelled-reservation-color, darken($default-dashboard-cancelled-reservation-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-cancelled-reservation-color, 0.65)
                // .icon {
                    // color: $default-dashboard-cancelled-reservation-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-cancelled-reservation-color;
            //             }
            //         }
            //     }
            // }
        }

        &.no-show-reservation {
            .icon-wrapper {
                background-color: $default-dashboard-no-show-reservation-color;
                background: linear-gradient(140deg, lighten($default-dashboard-no-show-reservation-color, 5%), $default-dashboard-no-show-reservation-color, darken($default-dashboard-no-show-reservation-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-no-show-reservation-color, 0.65)
                // .icon {
                    // color: $default-dashboard-no-show-reservation-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-no-show-reservation-color;
            //             }
            //         }
            //     }
            // }
        }

        &.pick-drop {
            .icon-wrapper {
                background-color: $default-dashboard-pick-drop-color;
                background: linear-gradient(140deg, lighten($default-dashboard-pick-drop-color, 5%), $default-dashboard-pick-drop-color, darken($default-dashboard-pick-drop-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-pick-drop-color, 0.65)
                // .icon {
                    // color: $default-dashboard-pick-drop-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-pick-drop-color;
            //             }
            //         }
            //     }
            // }
        }

        &.complimentary-rooms {
            .icon-wrapper {
                background-color: $default-dashboard-complimentary-rooms-color;
                background: linear-gradient(140deg, lighten($default-dashboard-complimentary-rooms-color, 5%), $default-dashboard-complimentary-rooms-color, darken($default-dashboard-complimentary-rooms-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-complimentary-rooms-color, 0.65)
                // .icon {
                    // color: $default-dashboard-complimentary-rooms-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-complimentary-rooms-color;
            //             }
            //         }
            //     }
            // }
        }

        &.house-use-rooms {
            .icon-wrapper {
                background-color: $default-dashboard-house-use-rooms-color;
                background: linear-gradient(140deg, lighten($default-dashboard-house-use-rooms-color, 5%), $default-dashboard-house-use-rooms-color, darken($default-dashboard-house-use-rooms-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-house-use-rooms-color, 0.65)
                // .icon {
                    // color: $default-dashboard-house-use-rooms-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-house-use-rooms-color;
            //             }
            //         }
            //     }
            // }
        }

        &.house-count {
            .icon-wrapper {
                background-color: $default-dashboard-house-count-color;
                background: linear-gradient(140deg, lighten($default-dashboard-house-count-color, 5%), $default-dashboard-house-count-color, darken($default-dashboard-house-count-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-house-count-color, 0.65)
                // .icon {
                    // color: $default-dashboard-house-count-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-house-count-color;
            //             }
            //         }
            //     }
            // }
        }

        &.out-of-order {
            .icon-wrapper {
                background-color: $default-out-of-order-color;
                background: linear-gradient(140deg, lighten($default-out-of-order-color, 5%), $default-out-of-order-color, darken($default-out-of-order-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-out-of-order-color, 0.65)
                // .icon {
                    // color: $default-out-of-order-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-out-of-order-color;
            //             }
            //         }
            //     }
            // }
        }

        &.out-of-service {
            .icon-wrapper {
                background-color: $default-out-of-service-color;
                background: linear-gradient(140deg, lighten($default-out-of-service-color, 5%), $default-out-of-service-color, darken($default-out-of-service-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-out-of-service-color, 0.65)
                // .icon {
                    // color: $default-out-of-service-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-out-of-service-color;
            //             }
            //         }
            //     }
            // }
        }

        &.management-block {
            .icon-wrapper {
                background-color: $default-management-block-color;
                background: linear-gradient(140deg, lighten($default-management-block-color, 5%), $default-management-block-color, darken($default-management-block-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-management-block-color, 0.65)
                // .icon {
                    // color: $default-management-block-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-management-block-color;
            //             }
            //         }
            //     }
            // }
        }



        &.birthday {
            .icon-wrapper {
                background-color: $default-dashboard-birthday-color;
                background: linear-gradient(140deg, lighten($default-dashboard-birthday-color, 5%), $default-dashboard-birthday-color, darken($default-dashboard-birthday-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-birthday-color, 0.65)
                // .icon {
                    // color: $default-dashboard-birthday-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-birthday-color;
            //             }
            //         }
            //     }
            // }
        }

        &.anniversary {
            .icon-wrapper {
                background-color: $default-dashboard-anniversary-color;
                background: linear-gradient(140deg, lighten($default-dashboard-anniversary-color, 5%), $default-dashboard-anniversary-color, darken($default-dashboard-anniversary-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-anniversary-color, 0.65)
                // .icon {
                    // color: $default-dashboard-anniversary-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-anniversary-color;
            //             }
            //         }
            //     }
            // }
        }

        &.guest-message {
            .icon-wrapper {
                background-color: $default-dashboard-guest-message-color;
                background: linear-gradient(140deg, lighten($default-dashboard-guest-message-color, 5%), $default-dashboard-guest-message-color, darken($default-dashboard-guest-message-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-guest-message-color, 0.65)
                // .icon {
                    // color: $default-dashboard-guest-message-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-guest-message-color;
            //             }
            //         }
            //     }
            // }
        }

        &.guest-complaint {
            .icon-wrapper {
                background-color: $default-dashboard-guest-complaint-color;
                background: linear-gradient(140deg, lighten($default-dashboard-guest-complaint-color, 5%), $default-dashboard-guest-complaint-color, darken($default-dashboard-guest-complaint-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-guest-complaint-color, 0.65)
                // .icon {
                    // color: $default-dashboard-guest-complaint-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-guest-complaint-color;
            //             }
            //         }
            //     }
            // }
        }

        &.lost-items {
            .icon-wrapper {
                background-color: $default-dashboard-lost-items-color;
                background: linear-gradient(140deg, lighten($default-dashboard-lost-items-color, 5%), $default-dashboard-lost-items-color, darken($default-dashboard-lost-items-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-lost-items-color, 0.65)
                // .icon {
                    // color: $default-dashboard-lost-items-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-lost-items-color;
            //             }
            //         }
            //     }
            // }
        }

        &.found-items {
            .icon-wrapper {
                background-color: $default-dashboard-found-items-color;
                background: linear-gradient(140deg, lighten($default-dashboard-found-items-color, 5%), $default-dashboard-found-items-color, darken($default-dashboard-found-items-color, 5%));
                // box-shadow: 0px 4px 8px transparentize($default-dashboard-found-items-color, 0.65)
                // .icon {
                    // color: $default-dashboard-found-items-color;
                // }
            }
            // .title-value-wrapper {
            //     .value-wrapper {
            //         span {
            //             &.value {
            //                 color: $default-dashboard-found-items-color;
            //             }
            //         }
            //     }
            // }
        }

        .divider {
            position: absolute;
            right: calc(var(--_info-spacing) * -2);
            width: 1px;
            height: 40px;
            background-color: transparentize($default-border-color, 0.75);
            margin-left: calc(var(--_info-spacing) * 1.25);
        }

    }

    &.transparent {
        --_info-spacing: 10px;
        background-color: transparent;
        box-shadow: none;
        padding: unset;
        gap: var(--_info-spacing);

        .info-with-count {
            background-color: #ffffff;
            // box-shadow: 0px 0px 5px transparentize(#000000, 0.94);
        }
        
        &.light-transparent {
            .info-with-count {
                background-color: transparentize($default-border-color, 0.9);
                border: 1px solid transparentize($default-border-color, 0.9);
                // box-shadow: 0px 0px 5px transparentize(#000000, 0.94);
            }
        }
    }

    &.margin {
        &.top {
            margin-top: var(--_info-spacing);
        }
        &.right {
            margin-right: var(--_info-spacing);
        }
        &.bottom {
            margin-bottom: var(--_info-spacing);
        }
        &.left {
            margin-left: var(--_info-spacing);
        }
    }

}


@media only screen and (min-width: 992px) {
    .info-with-count-container {
        .info-with-count {
            &:nth-child(4), &:nth-child(8) {
                .divider {
                    all: unset;
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .info-with-count-container {
        grid-template-columns: repeat(2, 1fr);
        .info-with-count {
            &:nth-child(even) {
                .divider {
                    all: unset;
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .info-with-count-container {
        grid-template-columns: repeat(1, 1fr);
        .info-with-count {
            .divider {
                all: unset;
                display: none;
            }
        }
    }
}


.info-with-chart-container {
    --_info-spacing: 10px;

    box-sizing: border-box;
    background-color: #fff;
    border-radius: var(--_info-spacing);
    box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
    padding: var(--_info-spacing);
    display: grid;
    grid-template-columns: 1fr;
    // grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    row-gap: var(--_info-spacing);
    column-gap: calc(var(--_info-spacing) * 4);

    .chart-content {
        box-sizing: border-box;
        position: relative;
        border-radius: var(--_info-spacing);
        padding: calc(var(--_info-spacing) * 0.5);

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-content {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .icon-wrapper {
                    padding: calc(var(--_info-spacing) * 1);
                    border-radius: calc(var(--_info-spacing) * 4);
                    margin-right: calc(var(--_info-spacing) * 1);
                    background-color: transparentize($default-theme-color, 0.9);
        
                    .icon {
                        color: $default-theme-color;
                        font-size: 18px;
        
                        path {
                            filter: drop-shadow(20px 40px 80px transparentize(#000000, 0.75));
                        }
                    }
                }
        
                .title-value-wrapper {
                    box-sizing: border-box;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: calc(var(--_info-spacing) * 0.25);
                    padding: calc(var(--_info-spacing) * 0.25) 0;
        
                    span {
                        &.title {
                            display: block;
                            color: $default-text-color-dark;
                            font-weight: 500;
                            font-size: 14px;
                        }
                        &.description {
                            color: $default-text-color-light;
                            font-size: 11px;
                        }
                    }
                }

                &.room-status {
                    .icon-wrapper {
                        background-color: transparentize($default-green-color, 0.9);
                        .icon {
                            color: $default-green-color;
                        }
                    }
                }

                &.forecast {
                    .icon-wrapper {
                        background-color: transparentize(#008FFB, 0.9);
                        .icon {
                            color: #008FFB;
                        }
                    }
                }

                &.materialized {
                    .icon-wrapper {
                        background-color: transparentize(#00E396, 0.9);
                        .icon {
                            color: #00E396
                        }
                    }
                }

                &.occupancy-analysis {
                    .icon-wrapper {
                        background-color: transparentize(#FF4560, 0.9);
                        .icon {
                            color: #FF4560
                        }
                    }
                }

                &.pax-count {
                    .icon-wrapper {
                        background-color: transparentize($default-purple-accent, 0.9);
                        .icon {
                            color: $default-purple-accent;
                        }
                    }
                }
            }

            .right-content {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: calc(var(--_info-spacing) * 0.5);

                span {
                    &.count-title {
                        font-size: 25px;
                        font-weight: bolder;
                    }
                }

                .input-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .input-group {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        margin: 0 5px;
                        label {
                            white-space: nowrap;
                            margin-right: 5px;
                            padding: 5px 0;
                        }
                        input {
                            &.filter-input {
                                background-color: transparent;
                                border: 1px solid transparent;
                                border-bottom: 1px solid transparentize($default-border-color, 0.64);
                                border-radius: 0;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }

        .body {
            padding: calc(var(--_info-spacing) * 2) var(--_info-spacing) var(--_info-spacing);
            min-height: 250px;
        }
    }

    &.margin {
        &.top {
            margin-top: var(--_info-spacing);
        }
        &.right {
            margin-right: var(--_info-spacing);
        }
        &.bottom {
            margin-bottom: var(--_info-spacing);
        }
        &.left {
            margin-left: var(--_info-spacing);
        }
    }
}


.pax-type-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .pax-icon-wrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: transparentize($default-purple-accent, 0.9);
        margin-right: 5px;
        border-radius: 40px;
        height: 20px;
        width: 20px;

        .icon {
            color: transparentize($default-purple-accent, 0.4);
            font-size: 11px;
        }
    }
}


table {
    &.dashboard-tbl {
        table-layout: fixed;
        width: 100%;

        tbody,thead {
            tr {
                td, th {
                    padding: 10px 0;
                    color: $default-text-color-light;
                    text-align: left;
                    overflow-wrap: break-word;

                    &:first-child {
                        color: $default-text-color-dark;
                    }
                    &:nth-child(2) {
                        color: $default-text-color-dark;
                    }
                    &:nth-child(3) {
                        color: $default-text-color-dark;
                        text-align: left;
                    }
                    &:nth-child(4) {
                        color: $default-text-color-dark;
                        text-align: left;
                    }                            
                    &:last-child {
                        text-align: right;
                        color: $default-text-color-light;
                    }
                }
            }
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    td {
                        border-top: 1px solid transparentize($default-border-color, 0.64);
                        border-bottom: 1px solid transparentize($default-border-color, 0.64);
                    }
                }
            }
        }

        &.exp-arr, &.exp-dept, &.stayover, &.todays-arrival, &.todays-departure, &.rsvn, &.do-not-disturb,
        &.special-event, &.scanty, &.vip, &.room-detail, &.guest-message, &.guest-complaint, &.upcoming-events, &.lost-list, &.found-list {
            thead {
                tr {
                    td, th {
                        padding: 10px 0;
                        color: $default-text-color-dark;
                    }
                }
            }
            tbody {
                tr {
                    td, th {
                        padding: 10px 0;
                        color: $default-text-color-light;
                    }
                }
            }
        }

        &.guest-complaint, &.guest-message {
            tbody {
                tr {
                    td, th {
                        vertical-align: middle;
                    }
                }
            }
        }

        &.todays-pick-up-drop {
            tbody,thead {
                tr {
                    td, th {
                        padding: 10px 5px;
                        color: $default-text-color-dark;
                        &:nth-child(6), &:last-child {
                            text-align: center;
                        }
                    }
                    &.pick-up-row {
                        td, th {
                            &:last-child {
                                border-right: 6px solid transparentize($default-green-color, 0.5);
                            }
                        }
                    }
                    &.drop-row {
                        td, th {
                            &:last-child {
                                border-right: 6px solid transparentize($default-red-color, 0.5);
                            }
                        }
                    }
                    &.passed-time {
                        td, th {
                            opacity: 0.3;
                        }
                    }
                }
            }
        }
        
    }
}


.vip-container-list {
    display: inline-block;
    width: 25px;
    height: 25px;

    svg {
        filter: drop-shadow(0px 1px 1px #00000020);
        path, rect {
            fill: rgb(234, 180, 21);
        }
    }
}