//  ==========================================================================
//  Created Date    :   April 16, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Card styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";
@import "mixins/mixins.scss";

.as-card{
    background-color: #ffffff;
    border-radius: 5px;

    &.as-card-bordered {
        border: 1px solid $default-border-color;
    }

    &.as-card-shadowed {
        box-shadow: 0px 5px 10px transparentize(#000000, 0.95);
    }

    &.as-card-no-padding {
        .as-card-body {
            // padding: 0 5px 10px;
        }
    }

    .as-card-head{
        padding: 15px 15px 0px;
        margin: 0;
        position: relative;
        display: flex;
        align-items: center;

        .as-card-title{
            font-weight: normal;
            font-size: 16px;
            .fa {
                padding: 10px 10px 0px 0px;
                font-size: larger;
            }
        }
    
        .as-card-head-icon {
            position: absolute;
            font-size: 20px;
            color: $default-theme-color;
        }
    
        .as-card-head-title {
            padding: 0 0 0 35px;
            
            span {
                &.as-card-head-title-text {
                    font-size: 16px;
                    color: $default-text-color-dark;

                    div {
                        margin-left: -35px;
                    }
                }
                &.as-card-head-title-description {
                    display: block;
                    font-size: 11px;
                    padding-top: 2px !important;
                    color: $default-text-color-light;
                }
            }
    
        }

        .as-card-head-action-btn-wrapper {
            position: absolute;
            right: 15px;
        }
    }

    .as-card-body{
        margin: 0;
        padding: 15px;
        
        .as-card-body-brand {
            display: block;
            margin: 0;
            text-align: left;
            font-size: 25px;
            font-weight: bold;
        }
    }

    .as-card-foot{
        margin:0;
        padding: 15px;
        border-top: 1px solid $default-border-color;
        font-size: small;
        color: #7e847d;
        a {
            color: $default-text-color;
            text-decoration: none;
            cursor: pointer;
            .fa {
                padding-right: 5px;
            }
            &:hover {
                color: $default-theme-color;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

}

  
.align-right{
    text-align:right;
}


//  These are used for dashboard.
.as-dashboard-card{
    background-color: #ffffff;
    border-radius: 5px;

    &.as-dashboard-card-bordered {
        border: 1px solid $default-border-color;
    }

    &.as-dashboard-card-shadow {
        box-shadow: 0px 8px 16px 0px transparentize(#000000, 0.8);
    }

    .as-dashboard-card-header{
        padding: 15px 15px 0 15px;
        margin: 0;
        display: inline-block;
        position: relative;
        width: 100%;

        .as-dashboard-card-header-group {


            span {
                &.as-dashboard-card-header-text {
                    float: left;
                    font-weight: 600;
                    color: $default-text-color-dark;
                    font-size: 14px;
                }
            }

            div {
                &.as-dashboard-card-header-action {
                    float: right;
                    button {
                        &.as-dashboard-card-action-btn {
                            background: transparent;
                            outline: none;
                            border: none;
                            cursor: pointer;
                            padding: 5px;
                            font-size: 14px;
                            line-height: 14px;
                            color: $default-text-color-light;
                            margin: 0;
                            top: 0;
                            margin-right: 5px;
                            &:hover {
                                color: $default-text-color;
                            }
                        }
                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                }
            }

        }

    }

    .as-dashboard-card-body{
        margin:0;
        padding: 15px;
        width: 100%;
        overflow: hidden;

        .as-dashboard-card-data-wrapper {
            display: inline-block;
            overflow: auto !important;

            span {
                &.as-dashboard-card-brand {
                    float: left;
                    color: $default-theme-color;
                    font-size: x-large;
                    font-weight: 600;
                }
            }
            .as-dashboard-card-graphics {
                float: right;
                text-align: right;
                margin-top: 15px;
                height: 80px;
                min-height: 80px;
                max-height: 80px;
                width: 200px;
                min-width: 200px;
                max-width: 200px;
            }
        }
    }

}


//  For Minimum Space
.min-space {
    .as-card {
        .as-card-head {
            padding: 10px 10px 0px;

            .as-card-head-icon {
                font-size: 16px;
            }

            .as-card-title{
                font-size: 14px;

                .fa {
                    padding: 10px 10px 0px 0px;
                    font-size: large;
                }
            }
        
            .as-card-head-title {
                padding: 0 0 0 25px;
                
                span {
                    &.as-card-head-title-text {
                        font-size: 14px;
                    }
                    &.as-card-head-title-description {
                        font-size: 10px;
                        padding-top: 2px;
                    }
                }
        
            }

        }

        .as-card-body {
            padding: 10px;
        }

        .as-card-footer {
            padding: 10px;
        }

    }

}


.as-detailed-card {
    background: #ffffff;
    border-radius: 5px;
    padding: 15px 15px 0px;
    margin-bottom: 15px;
    transition: all 0.2s ease-in-out;
    border-left: 6px solid $default-border-color;

    &:hover {
        transform: scale(1.009);

        &.shadowed {
            box-shadow: 0px 5px 10px transparentize(#000000, 0.80);
        }

    }

    &.bordered {
        border: 1px solid $default-border-color;
        border-left: 6px solid $default-border-color;
    }

    &.shadowed {
        box-shadow: 0px 5px 10px transparentize(#000000, 0.90);
    }

    label {
        display: block;
        &.focused {
            color: $default-theme-color;
        }
    }

    span {
        font-size: $default-font-size;
        font-weight: 600;
        color: $default-text-color-dark;
        text-transform: uppercase;

        &.focused {
            color: $default-theme-color;
        }
    }

    &.theme {
        border-left-color: $default-theme-color;
    }

    &.green {
        border-left-color: $default-green-color;
    }

    &.red {
        border-left-color: $default-red-color;
    }

    &.yellow {
        border-left-color: $default-yellow-color;
    }

    &.blue {
        border-left-color: $default-blue-color;
    }

    &.light-blue {
        border-left-color: $default-light-blue-color;
    }

    &.dark {
        border-left-color: $default-text-color-dark;
    }

    .action-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        border-top: 1px solid transparentize($default-border-color, 0.5);

        button {
            &.detailed-action-btn {
                @include btnProp;
                background-color: transparent;
                box-shadow: none;
                padding: 5px;
                border: 1px solid transparent;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                width: auto;
                color: $default-text-color-light;
                font-size: 12px;
                margin: 0 5px;
                .detailed-action-btn-icon-wrapper {
                    height: 25px;
                    width: 25px;
                    background-color: transparentize($default-border-color, 0.6);
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;
                    .icon {
                        font-size: 14px;
                        color: $default-text-color-light;
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

}


.as-detailed-card-for-pop-up {

    label {
        &.focused {
            color: $default-theme-color;
        }
    }

    span {
        padding-left: 5px;
        font-size: $default-font-size;
        font-weight: 600;
        color: $default-text-color-dark;
        text-transform: uppercase !important;

        &.focused {
            color: $default-theme-color;
        }
    }

    label, span {
        cursor: pointer;
        &:hover {
            color: $default-theme-color
        }
    }

}

.as-well {
    width: 100%;
    padding: 5px 15px;
    border: 1px solid $default-border-color;
    background: lighten($default-text-color, 40%);
    border-radius: 4px;

    &.success {
        border-color: $default-green-color;
        background: lighten($default-green-color, 40%);
    }

    &.warning {
        border-color: $default-yellow-color;
        background: lighten($default-yellow-color, 40%);
    }

    &.info {
        border-color: $default-blue-color;
        background: lighten($default-blue-color, 40%);
    }

    &.error {
        border-color: $default-red-color;
        background: lighten($default-red-color, 40%);
    }

    &.light {
        border-color: $default-border-color;
        background: lighten($default-border-color, 15%);
    }

}


.display-data-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    span {
        &.title {
            font-size: small;
            color: $default-text-color-light;
            padding-bottom: 2px;
        }
        &.value {
            font-size: 14px;
            color: $default-text-color-dark;
        }
    }
}


.title-with-icon-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon-container {
        font-size: 20px;
        color: $default-theme-color;
        margin-right: 10px;
    }

    .title-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        span {
            &.title {
                font-size: 15px;
                color: $default-text-color-dark;
            }
            &.description {
                display: block;
                font-size: 11px;
                padding-top: 2px !important;
                color: $default-text-color-light;
            }
        }
    }
}