//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists React Simple Keyboard styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.simple-keyboard {
    max-width: 850px;
  }
  
  
  /* React Keyboard Styles */
  .hg-theme-default {
      background-color: $default-body-background-color !important;
  }
  
  
  .hg-theme-default .hg-button {
      background: #ffffff !important;
      border-bottom: 1px solid $default-border-color !important;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  .hg-theme-default .hg-button.hg-activeButton {
      background: $default-theme-color !important;
      color: #ffffff !important;
  }
  
  .hg-theme-default .hg-button.hg-selectedButton {
      background: rgba(5, 25, 70, .53);
      color: #fff;
  }