//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists styles used in POS Billing Page.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-billing-action-btn-wrapper {
    position: absolute;
    box-sizing: border-box;
    left: 90px;
    margin-top: 30px;
    width: 90px;
    height: auto;
    padding: 15px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    background: $default-gray-color;
    background: linear-gradient(140deg, lighten($default-gray-color, 5%), $default-gray-color, darken($default-gray-color, 5%));
    box-shadow:  0px 5px 20px transparentize($default-gray-color, 0.8);
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    -webkit-animation-name: showBillingOrderTabs;
    -webkit-animation-duration: .8s;
    animation-name: showBillingOrderTabs;
    animation-duration: .8s;
    z-index: $default-floating-container-z-index;

    @keyframes showBillingOrderTabs {
        0%{
            left: 0px;
            opacity: 0;
        }
        100%{
            left: 90px;
            opacity: 1;
        }
    }

    @-webkit-keyframes showBillingOrderTabs {
        0%{
            left: 0px;
            opacity: 0;
        }
        100%{
            left: 90px;
            opacity: 1;
        }
    }

    &.sub-window {
        left: 0;

        @keyframes showBillingOrderTabs {
            0%{
                left: -90px;
            }
            100%{
                left: 0px;
            }
        }
    
        @-webkit-keyframes showBillingOrderTabs {
            0%{
                left: -90px;
            }
            100%{
                left: 0px;
            }
        }
    }

    &::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: -25px;
        height: 25px;
        width: 15px;
        left: 0;
        border-bottom-left-radius: 12px;
        box-shadow: 0 15px 0 0 lighten($default-gray-color, 3%);
        z-index: 1;
    }
    
    &::after {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: -25px;
        height: 25px;
        width: 15px;
        left: 0;
        border-top-left-radius: 12px;
        box-shadow: 0 -15px 0 0 darken($default-gray-color, 3%);
        z-index: 1;
    }

    button {
        &.as-billing-action-btn, &.as-billing-action-btn-disabled {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: none;
            outline: none;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
            margin: 0;
            padding: 10px;
            width: 100%;
            height: auto;
            color: #ffffff;
            background: transparent;
            transition: all 0.2s ease-in-out;

            &:hover, &:focus {
                background: transparentize($color: #ffffff, $amount: 0.74);
                color: #ffffff;
                outline: none;
                border: none;
            }
            
            .as-fa-icons {
                display: block;
                font-size: 20px;
                margin-bottom: 5px;
            }

            svg {
                &.as-local-svg {
                    height: 25px;

                    path, circle, rect, line, polygon, ellipse, text {
                        fill: #ffffff !important;
                    }

                    text {
                        font-size: 100px !important;
                    }
                }
            }

            span {
                display: block;
                font-size: 12px;
            }

        }

        &.as-billing-action-btn-disabled {
            cursor: not-allowed;
            background-color: transparentize($default-text-color-light, 0.96);
            color: transparentize(#ffffff, 0.30);

            svg {
                &.as-local-svg {
                    height: 25px;

                    path, circle, rect, line, polygon, ellipse, text {
                        fill: transparentize(#ffffff, 0.30) !important;
                    }

                    text {
                        font-size: 100px !important;
                    }
                }
            }

            &:hover, &:focus {
                background-color: transparentize($default-text-color-light, 0.6);
                outline: none;
                border: none;
            }
        }
    }

}

.as-billing-content-spaced {
    padding-left: 80px;
    transition: all 0.2s ease-in-out;
}


table {
    &.as-table-small-text {
        thead, tbody, tfoot {
            tr {
                th, td {
                    font-size: small !important;

                    input {
                        &[type=checkbox] {
                            width: 15px;
                            height: 15px;

                            &::after {
                                width: 3px;
                                height: 6px;
                                border: 2px solid #ffffff;
                                border-top: 0;
                                border-left: 0;
                                left: 4px;
                                top: 1px;
                                transform: rotate(var(--rot, 0deg));
                            }
                        }
                    }

                    &:last-child {
                        text-align: center;
                    }
                }
            }
        }
    }
    &.as-table-smaller-text {
        thead, tbody, tfoot {
            tr {
                th, td {
                    font-size: smaller !important;

                    input {
                        &[type=checkbox] {
                            width: 13px;
                            height: 13px;

                            &::after {
                                width: 2px;
                                height: 4px;
                                border: 1px solid #ffffff;
                                border-top: 0;
                                border-left: 0;
                                left: 1px;
                                top: 1px;
                                transform: rotate(var(--rot, 0deg));
                            }
                        }
                    }

                    &:last-child {
                        text-align: center;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 560px) {

    .as-billing-action-btn-wrapper {
        left: 50px;
        width: 50px;
        min-height: auto;
        padding: 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        -webkit-animation-name: showBillingTabsSmallScreen;
        -webkit-animation-duration: .8s;
        animation-name: showBillingTabsSmallScreen;
        animation-duration: .8s;
    
        @keyframes showBillingTabsSmallScreen {
            0%{
                left: 0px;
                opacity: 0;
            }
            100%{
                left: 50px;
                opacity: 1;
            }
        }
    
        @-webkit-keyframes showBillingTabsSmallScreen {
            0%{
                left: 0px;
                opacity: 0;
            }
            100%{
                left: 50px;
                opacity: 1;
            }
        }

        &.sub-window {
            left: 0;
    
            @keyframes showBillingTabsSmallScreen {
                0%{
                    left: -50px;
                }
                100%{
                    left: 0px;
                }
            }
        
            @-webkit-keyframes showBillingTabsSmallScreen {
                0%{
                    left: -50px;
                }
                100%{
                    left: 0px;
                }
            }
        }
    
        button {
            &.as-billing-action-btn, &.as-billing-action-btn-disabled {
                
                .as-fa-icons {
                    font-size: 18px;
                }
    
                svg {
                    &.as-local-svg {
                        height: 20px;
    
                        text {
                            font-size: 80px !important;
                        }
                    }
                }
    
                span {
                    display: none;
                }
    
            }
    
            &.as-billing-action-btn-disabled {
                cursor: not-allowed;
                background-color: transparentize($default-text-color-light, 0.96);
                color: transparentize(#ffffff, 0.30);
    
                svg {
                    &.as-local-svg {
                        height: 20px;
    
                        text {
                            font-size: 80px !important;
                        }
                    }
                }

            }
        }
    
    }
    
    .as-billing-content-spaced {
        padding-left: 40px;
        transition: all 0.2s ease-in-out;
    }
}
