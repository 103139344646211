//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists styles used in POS Order Page.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-order-block {
    padding: 0px 5px;

    .as-order-block-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        span {
            font-size: 18px;
            color: $default-text-color-dark;
            font-weight: bold;
        }

        .as-default-btn-transparent {
            border-width: 2px;
        }

        .as-dropdown {

            .as-dropdown-content {
                box-sizing: border-box;
                border: 1px solid $default-border-color;
                border-top: 2px solid $default-theme-color;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: $default-actionbar-dropdown-z-index;
                top: 15px;
                border-radius: 5px;
                padding: 10px;
                -webkit-animation-name: showDD;
                -webkit-animation-duration: .2s;
                animation-name: showDD;
                animation-duration: .2s;

                &::before {
                    content: '';
                    float: right;
                    margin-top: -30px;
                    height: 0;
                    width: 0;
                    border: 10px solid transparent;
                    border-bottom-color: $default-theme-color;
                }

                button {
                    border: 2px solid transparent;
                    background: transparent;
                    color: $default-text-color;
                    border-radius: 5px;
                    text-align: left;
                    padding: 10px;
                    margin: 2px;
                    display: block;
                    width: 100%;
                    cursor: pointer;
                    outline: none;

                    &:hover, &:focus {
                        color: $default-text-color-dark;
                        background-color: $default-body-background-color;
                    }
                }

                @keyframes showDD {
                    0%{
                        top: 15px;
                        opacity: 0;
                    }
                    50%{
                        top: 30px;
                        opacity: 0.5;
                    }
                    100%{
                        top: 10px;
                        opacity: 1;
                    }
                }

                @-webkit-keyframes showDD {
                    0%{
                        top: 15px;
                        opacity: 0;
                    }
                    50%{
                        top: 30px;
                        opacity: 0.5;
                    }
                    100%{
                        top: 10px;
                        opacity: 1;
                    }
                }
            }

        }

    }

    .as-order-block-search {
        padding: 15px 5px;
        
    }

    .as-order-table-wrapper {
        height: 56vh;
        min-height: 56vh;
        max-height: 56vh;
        overflow: auto;
        padding: 0 5px 0px 5px !important;
        margin-bottom: 5px !important;

        .as-order-table {
            width: 100%;
            font-size: $default-order-table-font-size;
            background-color: transparent !important;
            border-collapse: separate !important;
            border-spacing: 0 5px !important;

            thead {

                tr {
                    border-radius: $default-order-table-row-radius;
                    box-shadow: 0px 1px 2px $default-border-color;

                    th, td {
                        position: sticky;
                        top: 0px;
                        height: 30px;
                        padding: 10px;
                        text-align: right;
                        vertical-align: middle;
                        background-color: $default-body-background-color;
                        color: $default-text-color-dark;
                        font-weight: bolder !important;
                        z-index: $default-order-table-header-z-index;
                        cursor: pointer;

                        &:first-child {
                            border-top-left-radius: $default-order-table-row-radius;
                            border-bottom-left-radius: 0;
                            text-align: left;
                        }

                        &:nth-child(2) {
                            text-align: left;
                        }

                        &:nth-child(3) {
                            text-align: center;
                        }

                        &:last-child {
                            border-top-right-radius: $default-order-table-row-radius;
                            border-bottom-right-radius: 0;
                        }

                        //  Checkbox used inside th/td
                        input {
                            &[type=checkbox] {
                                height: 15px !important;
                                width: 15px !important;
                                padding: 0 !important;

                                &:not(.switch) {
                                    width: 15px !important;
                                    &::after {
                                        width: 3px;
                                        height: 5px;
                                        left: 4px;
                                        top: 2px;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            tbody {

                tr {
                    border-radius: $default-order-table-row-radius;
                    background: #ffffff;
                    box-shadow: 0px 1px 4px $default-border-color;

                    th, td {
                        box-sizing: border-box;
                        text-align: right;
                        height: 30px !important;
                        padding: 10px;
                        vertical-align: middle;

                        &:first-child {
                            border-top-left-radius: $default-order-table-row-radius;
                            border-bottom-left-radius: $default-order-table-row-radius;
                            text-align: left;
                        }

                        &:nth-child(2) {
                            text-align: left;
                        }

                        &:nth-child(3) {
                            text-align: center;
                        }

                        &:last-child {
                            border-top-right-radius: $default-order-table-row-radius;
                            border-bottom-right-radius: $default-order-table-row-radius;
                        }

                        &.as-order-items-group {
                            background-color: transparentize($default-theme-color, 0.84);
                            color: $default-text-color-dark;
                            text-align: left;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        //  Checkbox used inside th/td
                        input {
                            &[type=checkbox] {
                                height: 15px !important;
                                width: 15px !important;
                                padding: 0 !important;

                                &:not(.switch) {
                                    width: 15px !important;
                                    &::after {
                                        width: 3px;
                                        height: 5px;
                                        left: 4px;
                                        top: 2px;
                                    }
                                }
                            }
                        }

                    }

                    &:hover {
                        background-color: transparentize($default-theme-color,0.98);
                        cursor: pointer;
                    }
                }
            }

            &.as-ordered-items-table {
                thead, tbody {
                    tr {
                        th, td {
                            &:first-child {
                                text-align: center !important;
                            }
                            &:nth-child(2) {
                                text-align: left !important;
                            }
                            &:nth-child(3) {
                                text-align: left !important;
                            }
                            &:nth-child(4) {
                                text-align: center !important;
                            }
                            &:nth-child(5) {
                                text-align: right !important;
                            }
                            &:nth-child(6) {
                                text-align: right !important;
                            }
                        }
                    }
                }
            }

        }

        // //  Start Scroller
        // &::-webkit-scrollbar {
        //     width: 4px;
        // }
        // &::-webkit-scrollbar-track {
        //     background: transparent;
        // }
        // &::-webkit-scrollbar-thumb {
        //     background: $default-body-background-color;
        //     border-radius: 2px;
        // }
        // &::-webkit-scrollbar-thumb:hover {
        //     background: $default-border-color;
        //     cursor: pointer !important;
        // }
        // //  End Scroller
    }

    .as-order-block-footer {
        padding: 5px;

        .as-order-calculation-block {
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            table {
                    width: 100% !important;
                    border: none !important;
                    // display: table;
                    // table-layout: fixed;

                    thead {
                        tr {
                            td, th {
                                padding: 5px;
                                color: $default-text-color;
                                text-align: right;
                                &:first-child {
                                    text-align: left;
                                }

                            }

                            &:last-child {
                                td, th {
                                    text-align: left;

                                    &:last-child {
                                        text-align: right;

                                        span {
                                            font-weight: bold;
                                            font-size: 16px;
                                            color: $default-theme-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
            }

            // .as-order-calculation-sub-total-section {
            //     display: flex;
            // }

            // span {
            //     padding: 0 10px;
            //     border-right: 1px solid transparentize($default-border-color, 0.8);

            //     &:last-child {
            //         border-right: none;
            //     }
            // }
        }

        .as-order-total-section {
            background: $default-border-color;
            background: linear-gradient(to bottom, $default-border-color 0%, darken($default-border-color, 10%) 100%) !important;
            color: #ffffff;
            padding: 10px 15px;
            width: 100%;
            font-weight: bold;
            font-size: large;
        }

        .as-order-block-actions{
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            align-content: center;

            button {
                border-width: 2px;
                padding: 5px 25px;
                box-shadow: 0px 2px 5px transparentize($default-theme-color, 0.8);
            }
        }

    }
}


.as-table-div, .as-take-away-div {

    ul {
        padding: 0;
        margin: 0;

        li {
            &.as-table-list, &.as-take-away-list {
                display: block;
                list-style: none;
            }
        }
    }
}


.as-outlet-name-pos-order {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 50%;
    min-width: 250px;
    text-align: center;
    font-size: 16px;
    transform: translateX(-50%);
    padding: 15px 15px 10px;
    color: $default-text-color-dark;
    z-index: 10;
    transition: all 0.2s ease-in-out;
    -webkit-animation-name: showOutletName;
    -webkit-animation-duration: 1s;
    animation-name: showOutletName;
    animation-duration: 1s;

    @keyframes showOutletName {
        0%{
            top: -50px;
            opacity: 0;
        }
        100%{
            top: 0px;
            opacity: 1;
        }
    }

    @-webkit-keyframes showOutletName {
        0%{
            top: -50px;
            opacity: 0;
        }
        100%{
            top: 0px;
            opacity: 1;
        }
    }

}

//  Floating Tabs
.as-order-process-wrapper {
    position: absolute;
    box-sizing: border-box;
    left: $default-sidebar-width;
    margin-top: 80px;
    width: $default-sidebar-width;
    height: auto;
    padding: 15px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    background: $default-gray-color;
    background: linear-gradient(140deg, lighten($default-gray-color, 5%), $default-gray-color, darken($default-gray-color, 5%));
    box-shadow:  0px 5px 20px transparentize($default-gray-color, 0.8);
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    animation: showOrderPageTabs .8s ease-in-out;
    -webkit-animation: showOrderPageTabs .8s ease-in-out;
    z-index: $default-floating-container-z-index;

    &.as-order-process-wrapper-touch-order {
        margin-top: 40px;
    }

    @keyframes showOrderPageTabs {
        0%{
            left: 0px;
            opacity: 0;
        }
        100%{
            left: $default-sidebar-width;
            opacity: 1;
        }
    }

    @-webkit-keyframes showOrderPageTabs {
        0%{
            left: 0px;
            opacity: 0;
        }
        100%{
            left: $default-sidebar-width;
            opacity: 1;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 10px 0;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border: none;
                outline: none;
                border-radius: 5px;
                text-align: center;
                cursor: pointer;
                margin: 0;
                padding: 10px;
                width: 100%;
                height: auto;
                color: #ffffff;
                background: transparent;
                transition: all 0.2s ease-in-out;
                position: relative;

                &:hover, &:focus {
                    background: transparentize($color: #ffffff, $amount: 0.74);
                    color: #ffffff;
                }

                &.as-active-tab {
                    background: transparentize($color: #ffffff, $amount: 0.74);
                    color: #ffffff;
                }
                
                .as-tabs-icons {
                    display: block;
                    font-size: 20px;
                    margin-bottom: 5px;
                }

                svg {

                    &.as-local-svg {
                        height: 20px;
                        width: auto;
                        margin-bottom: 5px;

                        path, circle, rect, line, polygon, ellipse {
                            fill: #ffffff !important;
                        }

                    }

                }

                span {
                    display: block;
                    white-space: nowrap;
                    font-size: 12px;

                }

                .badge {
                    --action-content-badge-size: 20px;
                    position: absolute;
                    right: 2px;
                    top: 2px;
                    width: var(--action-content-badge-size);
                    height: var(--action-content-badge-size);
                    border-radius: var(--action-content-badge-size);
                    background: darken($default-red-color, 10%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        &.text {
                            font-size: calc(var(--action-content-badge-size) * 0.5);
                            font-weight: 500;
                            color: #fff;
                        }
                    }
                }

            }

        }
    }

    &::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: -25px;
        height: 25px;
        width: 15px;
        left: 0;
        border-bottom-left-radius: 12px;
        box-shadow: 0 15px 0 0 lighten($default-gray-color, 3%);
        z-index: 1;
    }
    
    &::after {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: -25px;
        height: 25px;
        width: 15px;
        left: 0;
        border-top-left-radius: 12px;
        box-shadow: 0 -15px 0 0 darken($default-gray-color, 3%);
        z-index: 1;
    }

}

.as-order-page-header-switches {
    padding: 0px 0px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .as-default-btn-transparent {
        border-width: 2px;
    }

    .as-dropdown {

        .as-dropdown-content {
            box-sizing: border-box;
            border: 1px solid $default-border-color;
            border-top: 2px solid $default-theme-color;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: $default-actionbar-dropdown-z-index;
            top: 15px;
            border-radius: 5px;
            padding: 10px;
            -webkit-animation-name: showDD;
            -webkit-animation-duration: .2s;
            animation-name: showDD;
            animation-duration: .2s;

            &:before {
                content: '';
                float: right;
                margin-top: -30px;
                height: 0;
                width: 0;
                border: 10px solid transparent;
                border-bottom-color: $default-theme-color;
            }

            button {
                border: 2px solid transparent;
                background: transparent;
                color: $default-text-color;
                border-radius: 5px;
                text-align: left;
                padding: 10px;
                margin: 2px;
                display: block;
                width: 100%;
                cursor: pointer;
                outline: none;

                &:hover, &:focus {
                    color: $default-text-color-dark;
                    background-color: $default-body-background-color;
                }
            }

            @keyframes showDD {
                0%{
                    top: 15px;
                    opacity: 0;
                }
                50%{
                    top: 30px;
                    opacity: 0.5;
                }
                100%{
                    top: 10px;
                    opacity: 1;
                }
            }

            @-webkit-keyframes showDD {
                0%{
                    top: 15px;
                    opacity: 0;
                }
                50%{
                    top: 30px;
                    opacity: 0.5;
                }
                100%{
                    top: 10px;
                    opacity: 1;
                }
            }
        }

    }

}

.as-order-process-content {
    margin: 0 0 0 55px;
    overflow: hidden;

    .as-order-process-content-header {
        height: auto;
        padding: 0;
        margin: 0;
    }

    .as-order-process-content-body {
        height: 78vh;
        overflow: auto;
        padding: 0px;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: unset !important;
            padding: 10px !important;
            // background-color: antiquewhite;
            .title-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1;
                .icon-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    --icon-wrapper-size: 30px;
                    height: var(--icon-wrapper-size);
                    width: var(--icon-wrapper-size);
                    border-radius: var(--icon-wrapper-size);
                    background-color: transparentize($default-theme-color, 0.9);
                    overflow: hidden;
                    margin-right: calc(var(--icon-wrapper-size) * 0.5);
                    .icon {
                        font-size: calc(var(--icon-wrapper-size) * 0.5);
                        color: $default-theme-color;
                    }
                    svg {
                        width: calc(var(--icon-wrapper-size) * 0.75) !important;
                        height: auto !important;
                        &.as-local-svg {
                            path, circle, rect, line, polygon, ellipse {
                                fill: $default-theme-color !important;
                            }
                        }
                    }
                    &.red {
                        background-color: transparentize($default-red-color, 0.9);
                        .icon {
                            color: $default-red-color;
                        }
                        svg {
                            &.as-local-svg {
                                path, circle, rect, line, polygon, ellipse {
                                    fill: $default-red-color !important;
                                }
                            }
                        }
                    }
                    &.green {
                        background-color: transparentize($default-green-color, 0.9);
                        .icon {
                            color: $default-green-color;
                        }
                        svg {
                            &.as-local-svg {
                                path, circle, rect, line, polygon, ellipse {
                                    fill: $default-green-color !important;
                                }
                            }
                        }
                    }
                    &.yellow {
                        background-color: transparentize($default-yellow-color, 0.9);
                        .icon {
                            color: $default-yellow-color;
                        }
                        svg {
                            &.as-local-svg {
                                path, circle, rect, line, polygon, ellipse {
                                    fill: $default-yellow-color !important;
                                }
                            }
                        }
                    }
                }
                .title-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    span {
                        &.title {
                            font-size: 16px;
                            color: $default-text-color-dark;
                        }
                        &.description {
                            font-size: 12px;
                            color: $default-text-color-light;
                        }
                    }
                }
            }
            .action-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .input-wrapper {
                    padding: 0 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 300px;
                    input {
                        width: 100%;
                        border: none;
                        outline: none;
                        color: $default-text-color;
                        background: url(../../Icons/search_icon.png) no-repeat;
                        transition: all 0.2s ease-in-out;
                        &.search-data {
                            padding: 5px 5px 5px 30px;
                            background-position: 10px;
                            background-size: 10px;
                            background-color: transparentize($default-body-background-color, 0.5);
                            border-radius: 15px;
                            border: none;
                            box-shadow: none !important;
                            margin: 0;
                        }
                        &:hover, &:focus {
                            border-color: $default-theme-color;
                        }
                        &::placeholder {
                            color: $default-text-color-light;
                        }
                        &:-moz-placeholder {
                            color: $default-text-color-light;
                        }
                    }
                }
            }
        }

        .as-table-btn-wrapper {
            display: inline-block;
            position: relative;
        }

        .as-take-away-order-btn-wrapper {
            display: inline-block;
            position: relative;
            width: 100%;

            .as-take-away-order-btn {
                box-sizing: border-box;
                height: auto;
                min-height: 60px;
                width: 100%;
                display: block;
                padding: 10px;
                margin: 10px 5px;
                text-align: center;
                text-align: -webkit-center;
                cursor: pointer;
                text-decoration: none;
                user-select: none;
                -webkit-font-smoothing: antialiased;
                -webkit-user-drag: none;
                -webkit-user-select: none;
                font-weight: 500;
                position: relative;
                overflow: hidden;
                border: 2px solid transparent;
                border-left: 10px solid transparentize($default-red-color, 0.5);
                border-radius: 10px;
                color: $default-text-color;
                background: #fff;
                box-shadow:  0px 1px 4px transparentize(#000000, 0.8);
                transition: all 0.2s ease-in-out;
        
                &:hover, &:focus {
                    box-shadow: 0px 3px 6px $default-border-color;
                }
        
                &:focus {
                    border-color: transparentize($default-red-color, 0.5);
                }
        
                table {
                    table-layout: fixed;
                    width: 100%;
        
                    tbody {
                        tr {
                            td, th {
                                span {
                                    display: block;
        
                                    &.as-guest-name{
                                        padding-top: 2px;
                                        color: $default-text-color-light;
                                        font-style: italic;
                                    }
        
                                    &.as-order-id{
                                        font-weight: 600;
                                        color: $default-text-color-dark;
                                    }
        
                                    &.as-order-time {
                                        color: $default-text-color-dark;
                                        font-size: small;
                                    }
        
                                    &.as-success-badge {
                                        padding: 5px 0;
                                        border-radius: 15px;
                                        font-size: small;
                                        background: $default-green-color;
                                        color: #ffffff;
                                    }
        
                                    &.as-warning-badge {
                                        padding: 5px 0;
                                        border-radius: 15px;
                                        font-size: small;
                                        background: $default-yellow-color;
                                        color: #ffffff;
                                    }
        
                                    &.as-danger-badge {
                                        padding: 5px 0;
                                        border-radius: 15px;
                                        font-size: small;
                                        background: $default-red-color;
                                        color: #ffffff;
                                    }
        
        
                                }
                                &:nth-child(1) {
                                    width: 40%;
                                    text-align: left;
                                }
                                &:nth-child(2) {
                                    width: 30%;
                                    text-align: left;
                                }
                                &:nth-child(3) {
                                    width: 30%;
                                    text-align: center;
                                }
                            }
                        }
                    }
        
                }
            }

            .as-take-away-order-option-btn {
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
                height: 40px;
                width: 40px;
                text-align: center;
                border-radius: 50%;
                font-size: small;
                font-weight: normal;
                background: transparentize($color: $default-border-color, $amount: 0.5);
                border: 1px solid transparent;
                color: $default-text-color;
                box-shadow: none;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover, &:focus {
                    background: transparentize($color: $default-border-color, $amount: 0.2);
                    box-shadow: 0px 1px 2px transparentize(#000000, 0.6);
                }
            }

            .as-dropdown {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 60px;

                .as-dropdown-content {
                    padding: 5px;
                    min-width: unset !important;
                    min-width: 120px !important;
                    max-width: 120px !important;
                    border-radius: 5px;
                    border-right: 2px solid $default-theme-color;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        height: 0px;
                        width: 0px;
                        padding: 0;
                        float: right;
                        right: -12px;
                        top: 50%;
                        transform: translateY(-50%);
                        border: 5px solid transparent;
                        border-left-color: $default-theme-color;
                    }

                    input {
                        &[type=button] {
                            display: block;
                            width: 100%;
                            cursor: pointer;
                            margin: 5px 0;
                            padding: 5px;
                            border: none;
                            border-radius: 2px;
                            background: transparent;
                            color: $default-text-color;
                            outline: none;
                            text-align: left;

                            &:hover, &:focus {
                                color: $default-text-color-dark;
                                background: $default-body-background-color;
                            }

                            &.for-touch {
                                padding: 8px 5px;
                            }
                        }
                    }
                }

            }

        }

        .as-switch-outlet-div {

            ul {
                display: grid;
                grid-template-columns: repeat(auto-fill, 140px) minmax(140px, 1fr);
                
                li {
                    
                    &.as-switch-outlet-list {
                        list-style: none;
                        text-align: center;
                        position: relative;
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-direction: row;

                        button {
                            &.as-outlet-btn {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                height: 140px;
                                width: 120px;
                                padding: 15px;
                                margin: 10px;
                                text-decoration: none;
                                user-select: none;
                                -webkit-font-smoothing: antialiased;
                                -webkit-user-drag: none;
                                -webkit-user-select: none;
                                position: relative;
                                overflow: hidden;
                                border: 1px solid transparentize($default-border-color, 0.4);
                                border-radius: 5px;
                                background: #fff;
                                box-shadow: 5px 5px 10px transparentize($default-border-color, 0.6);
                                filter: unset !important;

                                div {
                                    &.as-outlet-cover {
                                        all: unset !important;
                                        padding: 15px !important;
                                        svg {
                                            &.as-local-svg {
                                                path, circle, rect, line, polygon, ellipse {
                                                    fill: $default-theme-color !important;
                                                }
                                            }
                                        }
                                    }
                                }

                                span {
                                    &.as-outlet-name {
                                        font-size: 11px;
                                        font-weight: 500;
                                        color: $default-text-color-dark
                                    }
                                }

                                &:hover {
                                    box-shadow: 5px 5px 10px $default-border-color;
                                }

                                &:focus, &:focus-within {
                                    box-shadow: 5px 5px 10px darken($default-border-color, 5%);
                                    outline: auto;
                                }

                            }
                        }
                    }
                }
            }
        }


        //  Start Scroller
        &::-webkit-scrollbar {
            cursor: pointer;
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: $default-body-background-color;
            border-radius: 2px;

            &:hover {
                background: $default-border-color;
            }

        }
        //  End Scroller

        ul {
            li {
                .as-table-area {
                    display: block;
                    padding: 20px 5px 5px;
    
                    span {
                        color: $default-text-color-dark;
                        font-weight: bold;
                    }
                }
            }
        }

    }
    

    &.touch-screen {
        margin: 0;
        padding: 0;
        .as-order-process-content-body {
            height: auto;
        }
    }

}

// .as-order-process-container {
//     min-height: 78vh;

//     .as-dashboard-card-body {
//         padding-bottom: 0;
//     }

//     &.touch-order {
//         min-height: 82vh;
//     }
// }

.as-order-content {
    // padding: 5px 0 0;
    // margin: 0;
    height: 78vh;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    flex: 1;
    width: 100%;

    &.touch-order {
        height: 82vh;
    }


    .as-order-content-header {
        height: auto;

        .as-search-table-wrapper {
            background: #ffffff;
            // box-shadow: 0px 1px 4px transparentize($default-border-color,0.5);
            padding: 5px 0;

            table {
                &.as-search-item-table-wrapper {
                    width: 100%;
                    padding: 0;
                    border: 0;
                    border-collapse: collapse;

                    tbody {
                        tr {
                            td, th {
                                padding: 0 5px 0;
                                margin: 0;
                                border-right: 1px solid transparentize($default-border-color, 0.8);

                                &:nth-child(1) {
                                    width: 25%;
                                }
                                &:nth-child(2) {
                                    width: 50%;
                                }
                                &:nth-child(3) {
                                    width: 15%;
                                }
                                &:nth-child(4) {
                                    width: 10%;
                                    border: none !important;
                                    button {
                                        width: 100%;
                                        border-width: 2px;
                                        margin: 0;
                                    }
                                }

                                input {

                                    &.as-search-item {
                                        padding-left: 40px !important;
                                        background: url(../../Icons/search_icon.png) no-repeat;
                                        background-position: 15px;
                                        background-size: 14px;
                                    }

                                    &[type=number] {
                                        -webkit-appearance: textfield !important;
                                        -moz-appearance: textfield !important;
                                        appearance: textfield !important;

                                        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                                            -webkit-appearance: none !important;
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }

        }

    }

    .as-order-content-body {
        overflow: auto;
        padding: 5px;
        flex: 1;

        .as-order-table {
            width: 100%;
            font-size: $default-order-table-font-size;
            background-color: transparent !important;
            border-collapse: separate !important;
            border-spacing: 0 5px !important;

            thead {

                tr {
                    border-radius: $default-order-table-row-radius;
                    box-shadow: 0px 1px 2px $default-border-color;

                    th, td {
                        position: sticky;
                        top: -5px;
                        height: 30px;
                        padding: 10px;
                        text-align: right;
                        vertical-align: middle;
                        background-color: $default-body-background-color;
                        color: $default-text-color-dark;
                        font-weight: bolder !important;
                        z-index: $default-order-table-header-z-index;
                        cursor: pointer;

                        &:first-child {
                            border-top-left-radius: $default-order-table-row-radius;
                            border-bottom-left-radius: 0;
                            text-align: left;
                        }

                        &:nth-child(2) {
                            text-align: left;
                        }

                        &:nth-child(3) {
                            text-align: center;
                        }

                        &:last-child {
                            border-top-right-radius: $default-order-table-row-radius;
                            border-bottom-right-radius: 0;
                        }

                        //  Checkbox used inside th/td
                        input {
                            &[type=checkbox] {
                                height: 15px !important;
                                width: 15px !important;
                                padding: 0 !important;

                                &:not(.switch) {
                                    width: 15px !important;
                                    &::after {
                                        width: 3px;
                                        height: 5px;
                                        left: 4px;
                                        top: 2px;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            tbody {

                tr {
                    border-radius: $default-order-table-row-radius;
                    background: #ffffff;
                    box-shadow: 0px 1px 4px $default-border-color;

                    &.as-item-group-row {
                        background: transparentize($default-theme-color, 0.84);
                        color: $default-text-color-dark;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        text-align: left;

                        th, td {
                            
                            &:first-child {
                                border-bottom-left-radius: 0px !important;
                            }
                            
                            &:last-child {
                                border-bottom-right-radius: 0px !important;
                            }

                        }

                        &:hover {
                            background-color: transparentize($default-theme-color,0.84);
                            cursor: pointer;
                        }
                    }

                    th, td {
                        box-sizing: border-box;
                        text-align: right;
                        height: 30px !important;
                        padding: 10px;
                        vertical-align: middle;

                        &:first-child {
                            border-top-left-radius: $default-order-table-row-radius;
                            border-bottom-left-radius: $default-order-table-row-radius;
                            text-align: left;
                        }

                        &:nth-child(2) {
                            text-align: left;
                        }

                        &:nth-child(3) {
                            text-align: center;
                        }

                        &:last-child {
                            border-top-right-radius: $default-order-table-row-radius;
                            border-bottom-right-radius: $default-order-table-row-radius;
                        }

                        //  Checkbox used inside th/td
                        input {
                            &[type=checkbox] {
                                height: 15px !important;
                                width: 15px !important;
                                padding: 0 !important;

                                &:not(.switch) {
                                    width: 15px !important;
                                    &::after {
                                        width: 3px;
                                        height: 5px;
                                        left: 4px;
                                        top: 2px;
                                    }
                                }
                            }
                        }

                        .as-quantity-div {

                            button {
                                &.as-change-quantity-btn {
                                    box-sizing: border-box;
                                    width: 25px;
                                    height: 25px;
                                    background: $default-green-color;
                                    color: #ffffff;
                                    border: none;
                                    border-radius: 50%;
                                    font-size: 14px;
                                    line-height: 0;
                                    text-align: center;
                                    padding: 0;
                                    margin: 0;
                                    cursor: pointer;
                                    box-shadow: 0px 1px 5px $default-border-color;
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;

                                    &.as-order-decrease-btn {
                                        background: #ffffff;
                                        color: $default-text-color;
                                        box-shadow: 0px 1px 5px $default-border-color;
                                    }

                                }
                            }

                            input {
                                &[type=number], &[type=text] {
                                    max-width: 60px;
                                    height: 30px;
                                    padding: 5px;
                                    border: none;
                                    border-radius: 2px;
                                    background: transparent;
                                    color: $default-text-color;
                                    border-radius: 2px;
                                    outline: none;
                                    text-align: center;
                                    -webkit-appearance: textfield !important;
                                    -moz-appearance: textfield !important;
                                    appearance: textfield !important;

                                    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                                        -webkit-appearance: none !important;
                                    }

                                    &:hover, &:focus {
                                        background: #ffffff;
                                        color: $default-theme-color;
                                    }
                                }
                            }
                        }

                    }

                    &:hover {
                        background-color: transparentize($default-theme-color,0.98);
                        cursor: pointer;
                    }
                }
            }

            &.as-ordered-items-table {
                border-spacing: 0 12px !important;
                thead, tbody {
                    tr {
                        th, td {
                            &:first-child {
                                text-align: center !important;
                            }
                            &:nth-child(2) {
                                text-align: left !important;
                            }
                            &:nth-child(3) {
                                text-align: left !important;
                            }
                            &:nth-child(4) {
                                text-align: center !important;
                            }
                            &:nth-child(5) {
                                text-align: right !important;
                            }
                            &:nth-child(6) {
                                text-align: right !important;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        &.ordered-item-row {
                            th, td {
                                position: relative;
                                .last-ordered-time-wrapper {
                                    position: absolute;
                                    left: 0;
                                    bottom: -10px;
                                    background-color: $default-green-color;
                                    width: auto;
                                    max-width: max-content;
                                    padding: 2px 10px 2px 5px;
                                    margin-top: 2px;
                                    border-bottom-left-radius: $default-order-table-row-radius;
                                    -webkit-clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
                                    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);

                                    span {
                                        font-size: 10px;
                                        font-weight: 500;
                                        white-space: nowrap;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.as-order-table-for-touch {
                font-size: $default-order-table-font-size-for-touch-screen !important;
                table-layout: fixed !important;

                thead {

                    tr {
                        border-radius: $default-order-table-row-radius;
                        box-shadow: 0px 1px 2px $default-border-color;

                        th, td {
                            position: sticky;
                            top: -5px;
                            height: 30px;
                            padding: 10px;
                            text-align: right;
                            vertical-align: middle;
                            background-color: $default-body-background-color;
                            color: $default-text-color-dark;
                            font-weight: bolder !important;
                            z-index: $default-order-table-header-z-index;
                            cursor: pointer;

                            &:first-child {
                                padding: 5px;
                                border-top-left-radius: $default-order-table-row-radius;
                                border-bottom-left-radius: 0;
                                text-align: left;
                            }

                            &:nth-child(2) {
                                text-align: left;
                            }

                            &:nth-child(3) {
                                text-align: center;
                            }

                            &:last-child {
                                border-top-right-radius: $default-order-table-row-radius;
                                border-bottom-right-radius: 0;
                            }

                            //  Checkbox used inside th/td
                            input {
                                &[type=checkbox] {
                                    height: 15px !important;
                                    width: 15px !important;
                                    padding: 0 !important;

                                    &:not(.switch) {
                                        width: 15px !important;
                                        &::after {
                                            width: 3px;
                                            height: 5px;
                                            left: 4px;
                                            top: 2px;
                                        }
                                    }
                                }
                            }

                        }
                    }
                }

                tbody {

                    tr {
                        border-radius: $default-order-table-row-radius;
                        background: #ffffff;
                        box-shadow: 0px 1px 4px $default-border-color;

                        &.as-item-group-row {
                            background: transparentize($default-theme-color, 0.84);
                            color: $default-text-color-dark;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                            text-align: left;

                            th, td {
                                
                                &:first-child {
                                    border-bottom-left-radius: 0px !important;
                                }
                                
                                &:last-child {
                                    border-bottom-right-radius: 0px !important;
                                    text-align: left;
                                }

                            }

                            &:hover {
                                background-color: transparentize($default-theme-color,0.84);
                                cursor: pointer;
                            }
                        }

                        th, td {
                            box-sizing: border-box;
                            text-align: right;
                            padding: 10px;
                            vertical-align: middle;
                            border-bottom: 1px solid transparentize($default-border-color, 0.5);

                            &:first-child {
                                border-top-left-radius: $default-order-table-row-radius;
                                border-bottom-left-radius: $default-order-table-row-radius;
                                text-align: left;
                                width: 10%;
                            }

                            &:nth-child(2) {
                                text-align: left;
                                width: 40%;
                            }

                            &:nth-child(3) {
                                text-align: center;
                                width: 35%;
                            }

                            &:last-child {
                                text-align: right;
                                border-top-right-radius: $default-order-table-row-radius;
                                border-bottom-right-radius: $default-order-table-row-radius;
                                width: 15%;
                                white-space: normal;
                            }

                            //  Checkbox used inside th/td
                            input {
                                &[type=checkbox] {
                                    height: 15px !important;
                                    width: 15px !important;
                                    padding: 0 !important;

                                    &:not(.switch) {
                                        width: 15px !important;
                                        &::after {
                                            width: 3px;
                                            height: 5px;
                                            left: 4px;
                                            top: 2px;
                                        }
                                    }
                                }
                            }

                            span {
                                &.as-kot-number {
                                    font-size: 11px;
                                    color: $default-text-color-light;
                                    display: block;
                                    padding-bottom: 2px;
                                }
                                &.as-item-name {
                                    font-size: 12px;
                                    color: $default-text-color-dark;
                                    display: block;
                                    padding-bottom: 2px;
                                }
                                &.rate {
                                    font-size: 11px;
                                    color: $default-text-color-light;
                                    display: block;
                                }
                                &.as-kitchen-note-text {
                                    font-size: 11px;
                                    color: $default-text-color;
                                    padding: 5px 0;
                                }
                            }

                            .as-quantity-div {

                                button {
                                    &.as-change-quantity-btn {
                                        box-sizing: border-box;
                                        width: 25px;
                                        height: 25px;
                                        color: $default-green-color;
                                        background: transparent;
                                        border: none;
                                        border-radius: 50%;
                                        font-size: 14px;
                                        line-height: 0;
                                        text-align: center;
                                        padding: 0;
                                        margin: 0;
                                        cursor: pointer;
                                        box-shadow: none;
                                        display: inline-flex;
                                        justify-content: center;
                                        align-items: center;

                                        &.touch-order {
                                            margin: 0;
                                            width: 40px;
                                            height: 40px;
                                            background-color: transparentize($default-text-color-light, 0.8);
                                            color: $default-text-color-light;
                                        }

                                        &.as-order-decrease-btn {
                                            background: #ffffff;
                                            color: $default-text-color-light;
                                            box-shadow: none;

                                            &.touch-order {
                                                background-color: transparentize($default-text-color-light, 0.8);
                                                color: $default-text-color-light;
                                            }
                                        }


                                    }
                                }

                                input {
                                    &[type=number] {
                                        max-width: 60px;
                                        height: unset;
                                        padding: 0;
                                        border: none;
                                        border-radius: 2px;
                                        background: transparent;
                                        color: $default-text-color;
                                        border-radius: 2px;
                                        outline: none;
                                        text-align: center;
                                        -webkit-appearance: textfield !important;
                                        -moz-appearance: textfield !important;
                                        appearance: textfield !important;

                                        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                                            -webkit-appearance: none !important;
                                        }

                                        &:hover, &:focus {
                                            background: #ffffff;
                                            color: $default-theme-color;
                                        }
                                    }
                                }
                            }

                        }

                        &:hover {
                            background-color: transparentize($default-theme-color,0.98);
                            cursor: pointer;
                        }
                    }
                }

                &.as-ordered-items-table {
                    thead, tbody {
                        tr {
                            th, td {
                                &:first-child {
                                    text-align: center !important;
                                }
                                &:nth-child(2) {
                                    text-align: left !important;
                                }
                                &:nth-child(3) {
                                    text-align: left !important;
                                }
                                &:nth-child(4) {
                                    text-align: center !important;
                                }
                                &:nth-child(5) {
                                    text-align: right !important;
                                }
                                &:nth-child(6) {
                                    text-align: right !important;
                                }
                            }
                        }
                    }
                }
            }

        }

        // &.as-order-content-body-touch-order {
            //  Start Scroller
            // &::-webkit-scrollbar {
            //     display: none;
            // }
            //  End Scroller
        // }


    }

    .as-order-content-footer {
        padding: 5px;

        .as-billing-wrapper {
            width: 100%;
            background-color: $default-body-background-color;
            padding: 15px;
            border-radius: 5px;

            .total-wrapper {
                display: grid;
                grid-gap: 0 100px;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid #ffffff;
                display: none;

                .total-text-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 3px 0;
    
                    span {
                        &.total-label {
                            color: darken($default-text-color-light, 2%);
                            font-size: 12px;
                            text-transform: uppercase;
                        }
                        &.total-value {
                            color: darken($default-text-color-light, 2%);
                            font-size: 12px;
                        }
                    }
                }

                &.visible {
                    display: block;
                    animation: showBillingDetails .2s ease-in-out;
                    -webkit-animation: showBillingDetails .2s ease-in-out;
                }

                @keyframes showBillingDetails {
                    0%{
                        display: none;
                        transform: translateY(10px);
                    }
                    100%{
                        display: block;
                        transform: translateY(0);
                    }
                }
            
                @-webkit-keyframes showBillingDetails {
                    0%{
                        display: none;
                    }
                    100%{
                        display: block;
                    }
                }
            }

            .grand-total-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                position: relative;

                span {
                    &.grand-total-label {
                        color: darken($default-text-color-light, 2%);
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                    &.grand-total-value {
                        color: $default-text-color-dark;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                }

                button {
                    &.btn-toggle-billing-details {
                        color: $default-text-color-light;
                        position: absolute;
                        left: 50%;
                        top: -30px;
                        transform: translateX(-50%);
                        width: 45px;
                        height: 30px;
                        background-color: $default-body-background-color;
                        border: 1px solid $default-body-background-color;
                        border-bottom: none;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }
            }
        }

        .as-order-action-btn-wrapper {
            padding: 15px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                text-align: center;
                cursor: pointer; 
                text-decoration: none;
                user-select: none;
                -webkit-font-smoothing: antialiased;
                -webkit-user-drag: none;
                -webkit-user-select: none;
                font-size: 14px;
                letter-spacing: 0.25px;
                font-weight: 500;
                position: relative;
                overflow: hidden;
                margin: 0 5px;
                white-space: nowrap;

                .btn-icon-wrapper {
                    height: 25px;
                    width: 25px;
                    padding: 0;
                    margin: 0;
                    background-color: transparentize($default-theme-color, 0.8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    margin-right: 10px;

                    .btn-icon {
                        color: $default-theme-color;
                        font-size: 12px;
                    }
                }

                &:disabled {
                    color: $default-text-color !important;
                    border: 2px solid $default-border-color !important;
                    background: $default-border-color !important;
                    cursor: not-allowed !important;

                    svg {
                        fill: $default-text-color !important;
                        path {
                            fill: $default-text-color !important;
                        }
                    }

                    .btn-icon-wrapper {
                        background-color: transparentize($default-border-color, 0.8);
    
                        .btn-icon {
                            color: $default-text-color;
                        }

                        svg {
                            fill: $default-text-color !important;
                            path {
                                fill: $default-text-color !important;
                            }
                        }

                    }

                    &:hover, &:focus {
                        color: $default-text-color !important;
                        border: 2px solid $default-border-color !important;
                        background: $default-border-color !important;

                        svg {
                            fill: $default-text-color !important;
                            path {
                                fill: $default-text-color !important;
                            }
                        }

                    }

                }

                &.as-btn-place-order {
                    color: #ffffff;
                    padding: 5px 10px;
                    border-radius: 5px;
                    border: 2px solid $default-green-color;
                    background: $default-green-color;
                    background: linear-gradient(140deg, lighten($default-green-color, 5%), $default-green-color, darken($default-green-color, 5%));
                    box-shadow:  0px 4px 8px transparentize($default-green-color, 0.6);

                    .btn-icon-wrapper {
                        background-color: transparentize(#ffffff, 0.8);
                        .btn-icon {
                            color: #ffffff;
                        }
                    }
                }

                &.as-btn-hold-order, &.as-btn-go-to-bill, &.as-btn-go-to-bill-settlement {
                    color: $default-text-color-dark;
                    padding: 5px 10px;
                    border-radius: 5px;
                    border: 2px solid transparent;
                    background: transparent;

                    .btn-icon-wrapper {
                        background-color: transparentize($default-text-color-light, 0.8);
                        .btn-icon {
                            color: $default-text-color-light;
                        }
                    }

                    &:disabled {
                        color: $default-text-color-light !important;
                        border: 0px !important;
                        background: transparent !important;
    
                        &:hover, &:focus {
                            color: $default-text-color-light !important;
                            border: 0px !important;
                            background: transparent !important;    
                        }

                        .btn-icon-wrapper {
                            background-color: transparentize($default-text-color-light, 0.8);
                            .btn-icon {
                                color: $default-text-color-light !important;
                            }
                        }
    
                    }

                }

                &.as-btn-go-to-bill {
                    .btn-icon-wrapper {
                        background-color: transparentize($default-green-color, 0.8);
                        .btn-icon {
                            color: $default-green-color;
                        }
                    }
                }
                
                &.as-btn-hold-order {
                    .btn-icon-wrapper {
                        background-color: transparentize($default-yellow-color, 0.8);
                        .btn-icon {
                            color: $default-yellow-color;
                        }
                    }
                }

                &.as-btn-go-to-bill-settlement {
                    .btn-icon-wrapper {
                        background-color: transparentize($default-purple-accent, 0.8);
                        .btn-icon {
                            color: $default-purple-accent;
                        }
                    }
                }

            }

            &.as-order-action-btn-wrapper-for-touch {
                flex-wrap: nowrap;
                button {
                    font-size: 14px;
                    margin: 0 5px;

                    .btn-icon-wrapper {
                        height: 20px;
                        width: 20px;
                        margin-right: 5px;
                        .btn-icon {
                            font-size: 10px;
                        }
                    }

                    &.as-btn-hold-order, &.as-btn-go-to-bill, &.as-btn-go-to-bill-settlement {
                        padding: 8px;
                        border-radius: 5px;
                        border: 2px solid transparent;
                        background: transparent;
                    }

                    &.as-btn-go-to-bill-settlement {
                        // border: 2px solid transparentize($default-purple-accent, 0.85);
                        // background: transparentize($default-purple-accent, 0.85);
                        // .btn-icon-wrapper {
                        //     background-color: transparentize($default-purple-accent, 0.85);
                        //     .btn-icon {
                        //         color: $default-purple-accent;
                        //     }
                        // }

                        border: 2px solid transparentize($default-purple-accent, 0.85);
                        background-color: $default-purple-accent;
                        color: #ffffff;
                        flex: 2;
                        .btn-icon-wrapper {
                            background-color: transparentize(#ffffff, 0);
                            margin-right: 10px;
                            .btn-icon {
                                color: $default-purple-accent;
                            }
                        }

                        &.retail {
                            flex: 2;
                        }
                    }

                    &.as-btn-go-to-bill {
                        border: 2px solid transparentize($default-green-color, 0.85);
                        background: transparentize($default-green-color, 0.85);
                    }

                    &.as-btn-hold-order {
                        border: 2px solid transparentize($default-yellow-color, 0.85);
                        background: transparentize($default-yellow-color, 0.85);
                    }

                    &.as-btn-place-order {
                        padding: 8px;
                    }
                }
            }
        }

    }
    
}

table {
    &.as-billing-details {
        box-sizing: border-box;
        display: table;
        width: 100%;
        border: none;
        border-collapse: collapse;

        tbody {
            tr {
                td, th {
                    padding: 5px 0;

                    input {
                        &[type=checkbox] {
                            margin-top: 2px;
                            margin-right: 5px;
                            height: 15px !important;
                            width: 15px !important;
                            padding: 0 !important;

                            &:not(.switch) {
                                width: 15px !important;
                                
                                &::after {
                                    width: 3px;
                                    height: 5px;
                                    left: 4px;
                                    top: 2px;
                                }
                            }
                        }
                    }

                    &:first-child {
                        width: 50%;
                        text-align: left;
                    }
                    &:last-child {
                        width: 50%;
                        text-align: right;
                    }
                }

                &.as-extra-facility {
                    td, th {
                        &:last-child {
                            color: $default-theme-color;                            
                        }
                    }
                }

                &:last-child {
                    td, th {
                        color: $default-theme-color;
                        font-weight: bolder;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

.as-order-btn-wrapper {
    width: 100%;
    display: flex;
    overflow: hidden;
    margin: 10px 0;

    button {
        box-sizing: border-box;
        padding: 8px 15px !important;
        border-width: 2px !important;
        font-size: 14px;
        width: 50%;

        // &.as-btn-place-order {
        //     width: 100%;
        // }

        span {
            margin-left: 10px !important;
        }
    }

}

input {
    &.as-search-input {
        width: 100%;
        padding: 10px 10px 10px 40px;
        margin-bottom: 10px;
        border: none;
        border-bottom: 1px solid $default-border-color;
        outline: none;
        border-radius: 0;
        background-color: $default-body-background-color;
        color: $default-text-color;
        font-size: 14px;
        background: url(../../Icons/search_icon.png) no-repeat;
        background-position: 15px;
        background-size: 14px;
        transition: all 0.2s ease-in-out;

        &:hover, &:focus {
            border-color: $default-theme-color;
        }

        &::placeholder {
            color: $default-border-color;
        }

        &:-moz-placeholder {
            color: $default-border-color;
        }
    }
}

@media only screen and (max-width: 560px) {

    .as-outlet-name-pos-order {
        position: unset;
        box-sizing: border-box;
        top: unset;
        left: unset;
        min-width: unset;
        text-align: unset;
        font-size: 14px;
        transform: unset;
        padding: 15px 15px 10px;
        color: $default-text-color-dark;
        z-index: $default-body-title-z-index;
        transition: all 0.2s ease-in-out;
        -webkit-animation-name: unset;
        -webkit-animation-duration: unset;
        animation-name: unset;
        animation-duration: unset;
    }

    .as-outlet-name {
        all: unset;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        padding: 15px;
        color: $default-text-color-dark;
    }

    //  Floating Tabs
    .as-order-process-wrapper {
        left: $default-sidebar-width-small;
        width: $default-sidebar-width-small;
        min-height: auto;
        padding: 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        -webkit-animation-name: showOrderTabsSmallScreen;
        -webkit-animation-duration: .8s;
        animation-name: showOrderTabsSmallScreen;
        animation-duration: .8s;

        @keyframes showOrderTabsSmallScreen {
            0%{
                left: 0px;
                opacity: 0;
            }
            100%{
                left: $default-sidebar-width-small;
                opacity: 1;
            }
        }

        @-webkit-keyframes showOrderTabsSmallScreen {
            0%{
                left: 0px;
                opacity: 0;
            }
            100%{
                left: $default-sidebar-width-small;
                opacity: 1;
            }
        }

        ul {

            li {

                button {

                    span {
                        display: none;
                    }

                }

            }
        }

    }

    .as-order-process-content {
        margin: 0 0 0 35px;
    }
}


//  For Kitchen Note Text
.as-kitchen-note-text {
    display: block;
    font-size: small;
    font-style: italic;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

//  For Custom Table Layout
.as-table-layout-wrapper-setup {
    margin: 0 !important;
    padding: 10 !important;
    padding-bottom: 20px !important;
    width: 100%;
    height: 65vh;
    min-height: 65vh;
    max-height: 65vh;
    background: lighten($default-border-color, 10%);
    border: 1px solid $default-border-color;
    border-radius: 5px;
    overflow: auto;
    z-index: 3;
    position: relative;

    &.white-bg {
        background: transparent;
        border: none;
        border-radius: 0px;
    }

    // &.order-page {
    //     // padding-left: 20px;  //  Uncomment if table layout is set to custom in TabletabContent.js
    // }

}

.as-table-layout-wrapper {
    margin: 0 !important;
    width: 100%;
    background: transparent;
    border: none;
    border-radius: 0px;
    overflow: auto;
    z-index: 3;
    position: relative;

    &.custom-layout {
        background: lighten($default-border-color, 10%);
        border-radius: 5px;
    }

    &:not(:has(.custom-layout)) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
        gap: 10px;
        overflow: visible;

        &.touch-order {
            grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        }
    }
    

}

//  For Custom Layout Design
div,button {

    &.as-table-btn {
        &.custom-layout {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100px;
            width: 160px;
            padding: 15px;
            margin: 10px;
            text-decoration: none;
            user-select: none;
            -webkit-font-smoothing: antialiased;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            font-weight: 500;
            position: relative;
            overflow: hidden;
            border: 1px solid transparentize($default-border-color, 0.4);
            border-radius: 5px;
            color: $default-border-color;
            background: #fff;
            box-shadow: 5px 5px 10px transparentize($default-border-color, 0.6);
            z-index: 1;

            &.selected {
                border: 2px solid transparentize($default-text-color-dark, 0.4);
                box-shadow: 5px 5px 10px transparentize($default-text-color-dark, 0.8);
            }

            &.rectangle {
                border-radius: 5px !important;
            }

            &.circle {
                border-radius: 50% !important;
            }
    
            svg {
                &.as-local-svg-table {
                    position: unset;
                    height: 35px;
    
                    path, circle, rect, line, polygon, ellipse {
                        fill: $default-text-color-light !important;
                        &.as-table-cover {
                            fill: transparentize($default-green-color, 0.5) !important;
                        }
                        
                    }
    
                }
    
            }
    
            span {
                
                &.as-table-number {
                    font-weight: 600;
                    font-size: 12px;
                    color: $default-text-color;
                    position: unset;
                    margin: 10px 0 0 0;
                    padding: 0;
                }
    
            }

            .inner-layout {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 100%;
                width: 100%;
                max-height: 165px;
                max-width: 125px;

                .hold-icon {
                    --_hold-icon-size: 25px;
                    position: absolute;
                    top: 60%;
                    left: 25%;
                    transform: translateY(-60%);
                    width: var(--_hold-icon-size);
                    height: var(--_hold-icon-size);
                    border: 2px solid #fff;
                    border-radius: var(--_hold-icon-size);
                    margin: 0;
                    padding: 0;
                    background-color: transparentize($default-yellow-color, 0.15);
                    color: #fff;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                span {
                
                    &.as-table-total-cover {
                        left: 50%;
                        transform: translateX(-50%);
                    }
        
                    &.as-table-time-taken {
                        left: 50%;
                        transform: translateX(-50%);
                    }
        
                    &.as-merged-icon {
                        right: unset;
                        left: 50%;
                        transform: translateX(-50%);
                    }
        
                }

            }
    
        }
        &.rectangle {
            border-radius: 5px !important;
        }

        &.circle {
            border-radius: 50% !important;
        }
    }
}

.as-table-and-dropdown-wrapper {
    position: relative !important;

    .as-dropdown {
        position: absolute;
        top: unset !important;
        left: unset !important;
        z-index: 3;

        .as-dropdown-content {
            padding: 5px;
            min-width: unset !important;
            min-width: 110px !important;
            max-width: 110px !important;
            border-radius: 5px;
            border-top: 2px solid $default-theme-color;
            z-index: 3;

            &:before {
                content: '';
                height: 0px;
                width: 0px;
                padding: 0;
                float: right;
                margin-top: -17px;
                margin-right: 8px;
                border: 5px solid transparent;
                border-bottom-color: $default-theme-color;
            }

            input {
                &[type=button] {
                    display: block;
                    width: 100%;
                    cursor: pointer;
                    margin: 5px 0;
                    padding: 5px;
                    border: none;
                    border-radius: 2px;
                    background: transparent;
                    color: $default-text-color;
                    outline: none;
                    text-align: left;

                    &:hover, &:focus {
                        color: $default-text-color-dark;
                        background: $default-body-background-color;
                    }

                    &.for-touch {
                        padding: 8px 5px;
                    }
                }
            }
        }
    }

    .as-table-btn {

        &.custom-layout {
            height: 165px;
            width: 125px;
            padding: 0px;
            margin: 5px 10px;
            text-align: center;
            text-align: -webkit-center;
            cursor: pointer;
            text-decoration: none;
            user-select: none;
            -webkit-font-smoothing: antialiased;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            font-weight: 500;
            position: absolute;
            overflow: hidden;
            border: 1px solid transparentize($default-border-color, 0.6);
            border-radius: 15px;
            color: $default-border-color;
            background: #fff;
            box-shadow: 4px 4px 8px transparentize($default-border-color, 0.6);
            transition: all 0.2s ease-in-out;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
    
            .as-table-icon {
                font-size: 25px;
                display: block;
                margin-bottom: 10px;
                color: $default-border-color;
            }
    
            svg {
                &.as-local-svg-table {
                    height: 30px;
    
                    path, circle, rect, line, polygon, ellipse {
                        fill: $default-text-color-light !important;
                        &.as-table-cover {
                            fill: transparentize($default-green-color, 0.5) !important;
                        }
                        
                    }
    
                }
    
            }
    
            span {
                
                &.as-table-total-cover {
                    position: absolute;
                    top: 15px;
                    left: 10px;
                    font-size: x-small;
                    display: block;
                    color: $default-text-color;
                }
    
                &.as-table-time-taken {
                    position: absolute;
                    top: 25px;
                    left: 10px;
                    font-size: x-small;
                    display: block;
                    color: $default-text-color;
                }
    
                &.as-merged-icon {
                    position: absolute;
                    bottom: 35px;
                    right: 8px;
                    font-size: small;
                    display: block;
                    font-weight: bold;
                }
    
                &.as-table-number {
                    font-weight: 600;
                    font-size: 12px;
                    color: $default-text-color;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: 2px 2px 5px 2px;
                    padding: 5px;
                    display: block;
                }
    
            }
    
            &.as-table-btn-taken {
                color: $default-red-color;
    
                span {
                    &.as-table-number {
                        color: $default-red-color;
                    }
                }
    
                svg {
                    &.as-local-svg-table {
    
                        path, circle, rect, line, polygon, ellipse {
                            fill: $default-text-color-light !important;
    
                            &.as-table-cover {
                                fill: transparentize($default-red-color, 0.5) !important;
                            }
                            
                        }
                    }
                }
    
            }

            &.as-table-btn-with-pending-bill {
                color: $default-blue-color;
    
                span {
                    &.as-table-number {
                        color: $default-blue-color;
                    }
                }
    
                svg {
                    &.as-local-svg-table {
    
                        path, circle, rect, line, polygon, ellipse {
                            fill: $default-text-color-light !important;
    
                            &.as-table-cover {
                                fill: transparentize($default-blue-color, 0.5) !important;
                            }
                            
                        }
                    }
                }
    
            }
            
            &.as-table-btn-reserved {
                color: $default-yellow-color;
    
                span {
                    &.as-table-number {
                        color: $default-yellow-color;
                    }
                }
    
                svg {
                    &.as-local-svg-table {
        
                        path, circle, rect, line, polygon, ellipse {
                            fill: $default-text-color-light !important;
        
                            &.as-table-cover {
                                fill: transparentize($default-yellow-color, 0.5) !important;
                            }
        
                            &.as-table-reserved-box {
                                fill: transparentize($default-red-color, 0.5) !important;
                            }
                            
                        }
        
                    }
        
                    text {
                        fill: #ffffff !important;
                        font-size: 3.5px !important;
                        font-weight: bold !important;
                    }
                    
                }
            }
    
            &.as-table-btn-cleaning {
                color: $default-blue-color;
    
                span {
                    &.as-table-number {
                        background-color: $default-blue-color;
                    }
                }
    
                svg {
                    &.as-local-svg-table {
        
                        path, circle, rect, line, polygon, ellipse {
                            fill: $default-text-color-light !important;
        
                            &.as-table-cover {
                                fill: transparentize($default-blue-color, 0.5) !important;
                            }
                            
                        }
        
                    }
                }
    
            }
    
            &.as-table-btn-unusable {
                border-color: $default-text-color-light;
                color: $default-text-color-light;
                box-shadow:  5px 5px 10px transparentize($default-text-color-light, 0.9);
                span {
                    &.as-table-number {
                        background-color: $default-text-color-light;
                    }
                }
    
                svg {
                    &.as-local-svg-table {
        
                        path, circle, rect, line, polygon, ellipse {
                            fill: $default-border-color !important;
        
                            &.as-table-cover {
                                fill: transparentize($default-border-color, 0.5) !important;
                            }
                            
                        }
        
                    }
                }
    
            }

            &.circle {
                span {
                    &.as-table-total-cover {
                        left: 50%;
                        transform: translateX(-50%);
                    }
        
                    &.as-table-time-taken {
                        left: 50%;
                        transform: translateX(-50%);
                    }
        
                    &.as-merged-icon {
                        right: unset;
                        left: 50%;
                        transform: translateX(-50%);
                    }
        
                }
            }

        }

    }

    .as-table-option-btn {
        box-sizing: border-box;
        position: absolute;
        top: unset !important;
        right: unset !important;
        left: unset !important;
        height: 35px;
        width: 35px;
        text-align: center;
        border-radius: 50%;
        font-size: small;
        font-weight: normal;
        background: transparentize($color: $default-border-color, $amount: 0.5);
        border: 1px solid transparent;
        color: $default-text-color;
        box-shadow: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;

        &:hover, &:focus {
            background: transparentize($default-border-color, 0.2);
        }
    }

}

//  For Combo Popup
.combo-category-wrapper {
    background-color: $default-body-background-color;
    border-radius: 10px;
    padding: 15px 0px;

    .combo-category-header {
        padding: 10px;
        width: 100%;
    }

    .combo-category-body {
        background: 'red';
        padding: 10px;
        width: 100%;

        table {
            width: 100%;
            border-collapse: collapse;
            thead {
                tr {
                    td, th {
                        padding: 5px;
                        color: $default-text-color-dark;

                        span {
                            &.combo-category-title {
                                color: $default-text-color;
                            }
                            &.combo-category-name {
                                color: $default-text-color-dark;
                                padding-left: 5px;
                            }
                        }
                    }

                    &:nth-child(2) {
                        td, th {
                            padding-bottom: 20px;
                        }
                    }

                    &.main-header {
                        td, th {
                            border-top: 1px solid $default-border-color;
                            border-bottom: 1px solid $default-border-color;
                            background-color: #fff;
                            padding: 10px 5px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td, th {
                        padding: 8px 5px;

                        .valid-icon {
                            color: $default-green-color;
                        }

                        .invalid-icon {
                            color: $default-red-color;
                        }

                        //  Checkbox used inside th/td
                        input {
                            &[type=checkbox] {
                                height: 15px !important;
                                width: 15px !important;
                                padding: 0 !important;

                                &:not(.switch) {
                                    width: 15px !important;
                                    &::after {
                                        width: 3px;
                                        height: 5px;
                                        left: 4px;
                                        top: 2px;
                                    }
                                }
                            }
                        }
                        
                    }

                    &:not(:last-child) {
                        td, th {
                            border-bottom: 1px solid $default-border-color;
                        }
                    }
                }
            }
        }
    }

}

.combo-right-container {
    width: 100%;

    .combo-right-container-header {
        table {
            &.selection-table {
                width: 100%;
                tbody {
                    tr {
                        td, th {
                            &:nth-child(1) {
                                width: 58%;
                            }
                            &:nth-child(2) {
                                width: 18%;
                            }
                            &:nth-child(3) {
                                width: 24%;
                            }
                        }
                    }
                }
            }
        }
    }

    .combo-right-container-body {
        padding-top: 20px;

        table {
            &.selected-order-table {
                width: 100%;
                border-collapse: collapse;
                thead {
                    tr {
                        td, th {
                            padding: 8px 5px;
                            color: $default-text-color-dark;
                            text-align: left;
                            border-top: 2px solid $default-border-color;
                            border-bottom: 2px solid $default-border-color;

                            &:nth-child(1), &:nth-child(4) {
                                text-align: center;
                            }

                            &:nth-child(5), &:nth-child(6) {
                                text-align: right;
                            }

                        }
                    }
                }
                tbody {
                    tr {
                        td, th {
                            padding: 8px 5px;
                            color: $default-text-color;
                            text-align: left;

                            &:nth-child(1) {
                                width: 5%;
                                text-align: center;
                            }

                            &:nth-child(2) {
                                width: 30%;
                            }

                            &:nth-child(3) {
                                width: 15%;
                            }

                            &:nth-child(4) {
                                width: 26%;
                                text-align: center;
                            }

                            &:nth-child(5), &:nth-child(6) {
                                width: 12%;
                                text-align: right;
                            }

                        }

                        &:not(:last-child) {
                            td, th {
                                border-bottom: 1px solid $default-border-color;
                            }
                        }

                        &.checked_combo_data {
                            td, th {
                                background-color: transparentize($color: $default-theme-color, $amount: 0.9);
                            }
                        }

                    }
                }
            }
        }
    }

}

