//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Alert styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparentize(#000000, 0.4);
    backdrop-filter: blur(5px);
    z-index: $default-alert-z-index;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
}

.as-alert-show {
    visibility: visible;
}

.as-alert-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 300px;
    max-width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.2);
    animation: zoomin 0.3s ease-in-out;
    -webkit-animation: zoomin 0.3s ease-in-out;
    -moz-animation: zoomin 0.3s ease-in-out;
    position: relative;

    &.animate-loader {
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            height: 4px;
            background-color: $default-text-color;
            border-bottom-left-radius: 5px;
            align-self: flex-start;
            margin: 0;
            width: 100%;
            animation: decreaseloader 5s;
        }

        @keyframes decreaseloader {
            0% {
                width: 100%;
            }
            100% {
                width: 0;
            }
        }

        @-webkit-keyframes decreaseloader {
            0% {
                width: 100%;
            }
            100% {
                width: 0;
                border-bottom-right-radius: 5px;
            }
        }

    }
}

.as-alert-success-icon {
    width: 80px;
    height: 80px;
    background-color: $default-green-color;
    border: 5px solid #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -65px;

    svg {
        font-size: xx-large;
    }

}

.as-alert-warning-icon {
    width: 80px;
    height: 80px;
    background-color: $default-yellow-color;
    border: 5px solid #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -65px;

    svg {
        font-size: xx-large;
    }

}

.as-alert-error-icon {
    width: 80px;
    height: 80px;
    background-color: $default-red-color;
    border: 5px solid #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -65px;

    svg {
        font-size: xx-large;
    }

}

.as-alert-info-icon {
    width: 80px;
    height: 80px;
    background-color: $default-light-blue-color;
    border: 5px solid #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -65px;

    svg {
        font-size: xx-large;
    }
    
}

.as-alert-success-icon,
.as-alert-warning-icon,
.as-alert-error-icon,
.as-alert-info-icon {
    i {
        color: #ffffff;
        font-size: 40px;
    }
}

.as-alert-title {
    padding: 15px;
    text-align: center;
    font-size: 25px;
    color: $default-text-color;
    text-transform: capitalize;
}

.as-alert-success-title {
    padding: 15px;
    text-align: center;
    font-size: 25px;
    color: $default-green-color;
    text-transform: capitalize;
}

.as-alert-warning-title {
    padding: 15px;
    text-align: center;
    font-size: 25px;
    color: $default-yellow-color;
    text-transform: capitalize;
}

.as-alert-error-title {
    padding: 15px;
    text-align: center;
    font-size: 25px;
    color: $default-red-color;
    text-transform: capitalize;
}

.as-alert-info-title {
    padding: 15px;
    text-align: center;
    font-size: 25px;
    color: $default-light-blue-color;
    text-transform: capitalize;
}

.as-alert-message {
    padding: 0px 10px 10px;
    text-align: center;
    color: transparentize($default-text-color, 0.2);
    max-width: 100%;
    word-break: break-word;
    overflow: hidden;
}

.as-alert-extra-content {
    display: block;
    text-align: center;
    width: 100%;
    word-break: break-word;
    overflow: hidden;
}

.as-alert-buttons {
    padding-top: 20px;
    width: 250px;
    text-align: center;

    button {
        cursor: pointer;
        padding: 8px 10px !important;
        margin: 5px 0px !important;
        width: 100% !important;
    }
}

.as-alert-buttons-extra-action {
    padding-top: 20px;
    width: 250px;
    display: block;
    text-align: center;

    button {
        cursor: pointer;
        padding: 8px 10px !important;
        margin: 5px 0px !important;
        width: 100%;

    }
}


@keyframes zoomin {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes zoomin {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@-moz-keyframes zoomin {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}