@import url(variables.scss);

.guest-information-container {
    display: grid;
    grid-template-columns: 1fr 5fr;
    height: 100%;
    --_spacing: 1rem;

    .tab-buttons {
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        flex-direction: column;
        gap: 2px;

        button {
            &.tab-btn {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: var(--_spacing);
                padding: calc(var(--_spacing) * 0.75) var(--_spacing);
                border-radius: 5px;
                border: 1px solid transparentize($default-border-color, 0.9);
                background-color: transparentize($default-border-color, 0.9);
                color: $default-text-color-dark;
                text-align: left;
                position: relative;

                &.active {
                    background-color: transparentize($default-border-color, 0.85);
                    border-right: 4px solid $default-theme-color;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;

                    &::after {
                        top: 50%;
                        transform: translateY(-50%);
                        content: '';
                        height: 0;
                        width: 0;
                        border: 6px solid transparent;
                        border-left-color: $default-theme-color;
                        position: absolute;
                        right: -14px;
                    }
                }

                &:hover {
                    background-color: transparentize($default-border-color, 0.65);
                    border-color: transparentize($default-border-color, 0.65);

                    &.active {
                        border-right-color: $default-theme-color;
                    }
                }
            }
        }
    }

    .tab-content {
        padding-left: var(--_spacing);
    }
}