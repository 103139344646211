@import 'variables.scss';

.purchase-details-data-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    .purchase-type-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        background-color: transparentize($default-border-color, 0.9);
        padding: 15px;
        border-radius: 10px;

        .icon-wrapper {
            --inv-purchase-type-icon-wrapper-size: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: var(--inv-purchase-type-icon-wrapper-size);
            width: var(--inv-purchase-type-icon-wrapper-size);
            border-radius: 10px;
            background-color: transparentize($default-text-color-light, 0.9);
            // margin-right: calc(var(--inv-purchase-type-icon-wrapper-size) * 0.25);
            margin-bottom: calc(var(--inv-purchase-type-icon-wrapper-size) * 0.25);

            .icon {
                color: $default-text-color-light;
                font-size: calc(var(--inv-purchase-type-icon-wrapper-size) * 0.5);
            }
            
            &.cash {
                background-color: transparentize(#53c0c0, 0.9);
                .icon {
                    color: #53c0c0;
                }
            }

            &.credit {
                background-color: transparentize(#ee5f83, 0.9);
                .icon {
                    color: #ee5f83;
                }
            }
        }

        .purchase-type {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            span {
                &.type {
                    color: $default-text-color-light;
                    font-size: 12px;
                }
                &.value {
                    padding-top: 2px;
                    color: $default-text-color-dark;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}


table {
    &.inv-dashboard-display-table {
        thead, tbody, tfoot {
            tr {
                th, td {
                    font-size: small;
                    color: $default-text-color-light;
                    border-bottom: 1px solid transparentize($default-border-color, 0.5) !important;
                }
            }
        }
        thead {
            tr {
                th, td {
                    border-top: 0px !important;
                }
            }
        }

        &.vendor {
            thead, tbody, tfoot {
                tr {
                    th, td {
                        &:nth-child(3), &:nth-child(4) {
                            color: $default-text-color-dark;
                        }
                    }
                }
            }
            tbody {
                tr {
                    th, td {
                        border-bottom: 1px solid transparent !important;
                    }
                }
            }
        }

        &.receipt {
            thead, tbody, tfoot {
                tr {
                    th, td {
                        &:nth-child(3), &:nth-child(4) {
                            color: $default-text-color-dark;
                        }
                    }
                }
            }   
        }
        &.today-issue {
            thead, tbody, tfoot {
                tr {
                    th, td {
                        &:nth-child(4), &:nth-child(5) {
                            color: $default-text-color-dark;
                        }
                    }
                }
            }   
        }
        &.current-stock {
            thead {
                tr {
                    th, td {
                        text-align: left !important;
                        &:nth-child(3) {
                            text-align: right !important;
                        }
                        &:nth-child(4) {
                            text-align: center !important;
                        }
                    }
                }
            }
            thead, tbody, tfoot {
                tr {
                    th, td {
                        vertical-align: middle;
                        text-align: left;
                        &:nth-child(1) {
                            width: 40%;
                        }
                        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                            color: $default-text-color-dark;
                            width: 22%;
                        }
                        &:nth-child(4) {
                            width: 16%;
                        }
                    }
                }
            }   
        }
        &.recent-request {
            thead, tbody, tfoot {
                tr {
                    th, td {
                        &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                            color: $default-text-color-dark;
                        }
                    }
                }
            }   
        }
        &.item-expiry {
            tbody {
                tr {
                    th, td {
                        .expiry-item-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .item-details-wrapper {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                flex-direction: column;
                                span {
                                    &.batch-number-uom {
                                        font-size: 11px;
                                        color: $default-text-color-light;
                                        padding-bottom: 2px;
                                    }
                                    &.item-name {
                                        font-size: 14px;
                                        color: $default-text-color-dark;
                                    }
                                }
                                .expiry-date-wrapper {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                    padding-top: 5px;
                                    span {
                                        &.expiry-date {
                                            font-size: 11px;
                                            color: $default-text-color-light;
                                        }
                                    }
                                    .remaining-days-line {
                                        margin: 0 5px 0;
                                        width: 30px;
                                        height: 1px;
                                        border-top: 1px dashed transparentize($default-red-color, 0.8);
                                    }
                                    .remaining-days-wrapper {
                                        background-color: transparentize($default-red-color, 0.9);
                                        padding: 2px 6px;
                                        border-radius: 10px;
                                        span {
                                            &.remaining-days {
                                                color: darken($default-red-color, 10%);
                                                font-weight: 500;
                                            }
                                        }

                                    }
                                }
                            }
                            .other-details-wrapper {
                                display: flex;
                                justify-content: flex-end;
                                align-items: flex-end;
                                flex-direction: column;
                                .stock-container {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
    
                                    .stock-icon-wrapper {
                                        --stock-icon-wrapper-size: 35px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: var(--stock-icon-wrapper-size);
                                        width: var(--stock-icon-wrapper-size);
                                        border-radius: 10px;
                                        background-color: transparentize($default-red-color, 0.9);
                                        margin-right: calc(var(--stock-icon-wrapper-size) * 0.25);
    
                                        .icon {
                                            color: $default-red-color;
                                            font-size: calc(var(--stock-icon-wrapper-size) * 0.5);
                                        }
                                    }
                                    .stock-wrapper {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: flex-start;
                                        flex-direction: column;
                                        span {
                                            &.stock-title {
                                                font-size: 12px;
                                                color: $default-text-color-light;
                                            }
                                            &.stock {
                                                font-size: 14px;
                                                color: $default-text-color-dark;
                                                font-weight: 500;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.item-ageing {
            thead, tbody, tfoot {
                tr {
                    th, td {
                        &:nth-child(3) {
                            color: $default-text-color-dark;
                        }
                    }
                }
            }   
        }
    }
}