//  ==========================================================================
//  Created Date    :   April 18, 2020
//  Created By      :   Himal Poudel
//  Description     :   This file consists Breadcrumb styles.
//                      (Editor can update existing or add new classes.)
//  Last Modified   :   //  Modified Date
//  Modified By     :   //  Modifier Name
//  Modification    :   //  Describe what you modified.
//  ==========================================================================


@import "variables.scss";

.as-breadcrumb {
    display: inline-block;
    background-color: transparent;
    width: 100%;
    padding: 0;
    margin-bottom: 0;
}

.as-breadcrumb-list {
    display: inline;
    list-style: none;
    cursor: pointer;
    &:not(:last-of-type)::after {
        content: "/";
        margin: 0 10px;
        color: transparentize($default-text-color, 0.4);
    }
}

.as-breadcrumb-link {
    color: $default-text-color;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        text-decoration: underline;
        color: $default-theme-color;
    }
    &.as-breadcrumb-active {
        color: $default-theme-color;
    }
}


.breadcrumb {
    clear: both;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;

    ul {
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
            display: inline;
            list-style: none;

            button {
                &.breadcrumb-btn {
                    margin: 0;
                    user-select: none;
                    background: transparent;
                    color: $default-text-color-light;
                    border: 1px solid transparent;
                    cursor: pointer;

                    &.active {
                        color: $default-text-color-dark;
                    }

                    &:not(.active):hover, &:not(.active):focus {
                        text-decoration: underline;
                        color: $default-theme-color;
                    }
                }
            }

            &:not(:last-of-type)::after {
                content: '>';
                // margin: 0 5px;
                color: transparentize($default-text-color, 0.4);
            }
        }
    }

    button {
        &.breadcrumb-close-btn {
            padding: 5px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $default-red-color;
            border: 2px solid $default-red-color;
            background: transparent;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}