@import "variables.scss";

.as-pos-dashboard-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    button {
        &.as-pos-dashboard-btn {
            width: 100%;
            margin: 0;
            padding: 15px;
            cursor: pointer;
            user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            position: relative;
            overflow: hidden;
            border: 1px solid transparent;
            border-bottom-color: transparentize($default-border-color, 0.9);
            border-radius: 5px;
            background: #ffffff;
            // box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: all 0.1s ease-in-out;

            .outlet-svg-wrapper {
                --svg-wrapper-size: 60px;
                --svg-size: 40px;
                width: var(--svg-wrapper-size);
                height: var(--svg-wrapper-size);
                background: transparentize($default-menu-category-highlight-color, 0.94);
                border-radius: var(--svg-wrapper-size);
                margin-right: calc(var(--svg-wrapper-size) / 4);
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: var(--svg-size);
                    height: auto;
                    path, circle, rect, line, polygon, ellipse {
                        fill: lighten($default-menu-category-highlight-color, 10%) !important;
                    }
                }
            }
            
            .text-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                span {
                    &.as-btn-text {
                        color: $default-text-color-dark;
                        font-weight: 500;
                        text-transform: capitalize;
                        display: block;
                        white-space: nowrap;
                    }
                    &.as-btn-description {
                        color: $default-text-color-light;
                        font-size: 11px;
                        margin-top: 2px;
                        text-align: left;
                    }
                }
            }

            &:disabled {
                &:hover, &:focus {
                    .outlet-svg-wrapper {
                        background: transparentize($default-text-color-light, 0.94);
                        svg {
                            width: var(--svg-size);
                            height: auto;
                            path, circle, rect, line, polygon, ellipse {
                                fill: $default-text-color-light !important;
                            }
                        }
                    }
                    span {
                        &.as-btn-text {
                            color: $default-text-color-light;
                        }
                    }
                }
            }
        
            &:hover, &:focus {
                background: transparentize($default-menu-category-highlight-color, 0.8);
                .outlet-svg-wrapper {
                    background: #ffffff;
                }
            }

            &:last-child {
                margin-bottom: 0;
                border-bottom-color: transparent;
            }

        }
    }
}


.as-outlet-list-div {
    ul {
        margin: 0;
        padding: 0;
        li {
            &.as-outlet-list {
                button {
                    &.as-pos-tab-outlet-btn {
                        --btn-height: 180px;
                        --btn-width: 160px;
                        height: var(--btn-height);
                        width: var(--btn-width);
                        margin: 10px;
                        padding: 0;
                        border-radius: 10px;
                        cursor: pointer;
                        user-select: none;
                        -webkit-user-drag: none;
                        -webkit-user-select: none;
                        position: relative;
                        overflow: hidden;
                        border: 1px solid transparentize($default-border-color, 0.5);
                        background: #ffffff;
                        box-shadow: 0px 2px 10px transparentize(#000000, 0.94);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        transition: all 0.1s ease-in-out;

                        .outlet-svg-wrapper {
                            --svg-wrapper-size: 80px;
                            --svg-size: 50px;
                            width: var(--svg-wrapper-size);
                            height: var(--svg-wrapper-size);
                            background: transparentize($default-text-color-light, 0.94);
                            border-radius: var(--svg-wrapper-size);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: calc(var(--btn-height) / 4);
                            svg {
                                width: var(--svg-size);
                                height: auto;
                                path, circle, rect, line, polygon, ellipse {
                                    fill: lighten($default-text-color-light, 10%) !important;
                                }
                            }
                        }

                        .running-order-container {
                            --btn-height: 220px;
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            .running-order {
                                background-color: transparentize($default-border-color, 0.94);
                                padding: 5px 15px;
                                border-bottom: 1px solid transparentize($default-border-color, 0.8);
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                span {
                                    color: $default-text-color-light;
                                    font-size: 12px;
                                }
                            }
                            span {
                                &.outlet-name {
                                    position: unset;
                                    background-color: transparentize($default-border-color, 0.9);
                                    color: $default-text-color;
                                    font-weight: 500;
                                    font-size: 12px;
                                    text-transform: capitalize;
                                    display: block;
                                    padding: 10px;
                                }
                            }
                        }

                        span {
                            &.outlet-name {
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                background-color: transparentize($default-border-color, 0.9);
                                color: $default-text-color;
                                font-weight: 500;
                                font-size: 12px;
                                text-transform: capitalize;
                                display: block;
                                padding: 10px;
                            }
                        }
                        
                        &:hover {
                            span {
                                &.outlet-name {
                                    background-color: transparentize($default-menu-category-highlight-color, 0.9);
                                    color: $default-text-color-dark;
                                }
                            }
                        }

                        &:focus {
                            span {
                                &.outlet-name {
                                    background-color: transparentize($default-menu-category-highlight-color, 0.8);
                                    color: $default-text-color-dark;
                                }
                            }
                        }

                        &.opened, &.clocked-in-btn {
                            .outlet-svg-wrapper {
                                background: transparentize($default-menu-category-highlight-color, 0.94);
                                svg {
                                    path, circle, rect, line, polygon, ellipse {
                                        fill: lighten($default-menu-category-highlight-color, 10%) !important;
                                    }
                                }
                            }
                        }

                        &.closed, &.clocked-out-btn {
                            .outlet-svg-wrapper {
                                background: transparentize($default-text-color-light, 0.94);
                                svg {
                                    path, circle, rect, line, polygon, ellipse {
                                        fill: lighten($default-text-color-light, 10%) !important;
                                    }
                                }
                            }
                            span {
                                &.outlet-name {
                                    background-color: transparentize($default-text-color, 0.9);
                                    color: $default-text-color;
                                }
                            }
                            &:focus, &:hover {
                                span {
                                    &.outlet-name {
                                        background-color: transparentize($default-text-color, 0.8);
                                        color: $default-text-color-dark;
                                    }
                                }
                            }
                        }

                        &.clocked-in-btn, &.clocked-out-btn {
                            --btn-height: 220px;
                            .outlet-svg-wrapper {
                                margin-bottom: calc(var(--btn-height) / 2.5);
                            }
                            .as-clock-in-out-info-wrapper {
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                .as-clocked-in-info, .as-clocked-out-info {
                                    background-color: transparentize($default-border-color, 0.94);
                                    padding: 5px 15px;
                                    border-bottom: 1px solid transparentize($default-border-color, 0.8);
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    span {
                                        color: $default-text-color-light;
                                        font-size: 12px;
                                    }
                                }
                                span {
                                    &.outlet-name {
                                        position: unset;
                                        background-color: transparentize($default-border-color, 0.9);
                                        color: $default-text-color;
                                        font-weight: 500;
                                        font-size: 12px;
                                        text-transform: capitalize;
                                        display: block;
                                        padding: 10px;
                                    }
                                }
                            }

                            &:hover {
                                .as-clock-in-out-info-wrapper {
                                    .as-clocked-in-info, .as-clocked-out-info {
                                        background-color: transparentize($default-border-color, 0.9);
                                        span {
                                            color: $default-text-color;
                                        }
                                    }
                                    span {
                                        &.outlet-name {
                                            background-color: transparentize($default-menu-category-highlight-color, 0.9);
                                            color: $default-text-color-dark;
                                        }
                                    }
                                }
                            }
    
                            &:focus {
                                .as-clock-in-out-info-wrapper {
                                    .as-clocked-in-info, .as-clocked-out-info {
                                        background-color: transparentize($default-border-color, 0.8);
                                        span {
                                            color: $default-text-color;
                                        }
                                    }
                                    span {
                                        &.outlet-name {
                                            background-color: transparentize($default-menu-category-highlight-color, 0.8);
                                            color: $default-text-color-dark;
                                        }
                                    }
                                }
                            }
                        }

                        &.clocked-out-btn {
                            .as-clock-in-out-info-wrapper {
                                .as-clocked-in-info, .as-clocked-out-info {
                                    background-color: transparentize($default-text-color, 0.94);
                                    span {
                                        color: $default-text-color-light;
                                    }
                                }
                                span {
                                    &.outlet-name {
                                        background-color: transparentize($default-text-color, 0.9);
                                        color: $default-text-color;
                                    }
                                }
                            }

                            &:hover {
                                .as-clock-in-out-info-wrapper {
                                    .as-clocked-in-info, .as-clocked-out-info {
                                        background-color: transparentize($default-text-color, 0.92);
                                        span {
                                            color: $default-text-color;
                                        }
                                    }
                                    span {
                                        &.outlet-name {
                                            background-color: transparentize($default-text-color, 0.9);
                                            color: $default-text-color-dark;
                                        }
                                    }
                                }
                            }
    
                            &:focus {
                                .as-clock-in-out-info-wrapper {
                                    .as-clocked-in-info, .as-clocked-out-info {
                                        background-color: transparentize($default-text-color, 0.9);
                                        span {
                                            color: $default-text-color;
                                        }
                                    }
                                    span {
                                        &.outlet-name {
                                            background-color: transparentize($default-text-color, 0.8);
                                            color: $default-text-color-dark;
                                        }
                                    }
                                }
                            }
                        }

                        &.go-to-order-btn {
                            --btn-height: 220px;
                            .outlet-svg-wrapper {
                                margin-bottom: calc(var(--btn-height) / 2.5);
                            }
                        }

                    }

                    &.as-go-to-touch-order-btn {
                        --btn-width: 160px;
                        width: var(--btn-width);
                        margin: 5px 10px;
                        padding: 15px 10px;
                        border-radius: 10px;
                        cursor: pointer;
                        user-select: none;
                        -webkit-user-drag: none;
                        -webkit-user-select: none;
                        position: relative;
                        overflow: hidden;
                        border: 1px solid transparentize($default-border-color, 0.5);
                        background: transparentize($default-menu-category-highlight-color, 0.8);
                        color: $default-text-color;
                        box-shadow: 0px 2px 10px transparentize(#000000, 0.94);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.1s ease-in-out;
                        &:focus {
                            background: transparentize($default-menu-category-highlight-color, 0.6);
                            color: $default-text-color-dark;
                        }
                    }
                }
            }
        }
    }
}


.as-pos-dashboard-card {
    --master-padding: 20px;
    --card-background-color: #ffffff;
    --header-footer-min-height: 50px;
    box-sizing: border-box;
    background-color: var(--card-background-color);
    border: 1px solid transparentize($default-border-color, 0.8);
    border-radius: 10px;
    box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
    padding-bottom: calc(var(--master-padding) / 2);
    overflow: hidden;

    .header {
        padding: calc(var(--master-padding) / 2) var(--master-padding);
        min-height: var(--header-footer-min-height);
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.show-border {
            border-bottom: 1px solid transparentize($default-border-color, 0.8);
        }

        .title-wrapper {
            flex: 1;
            span {
                &.title {
                    color: $default-text-color-dark;
                }
            }
            .as-radio-group-transparent {
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center !important;
                input {
                    &.as-radio-group-btn{
                        &:checked {
                            + label {
                                cursor: pointer !important;
                                &.as-radio-group-label {
                                    color: $default-text-color !important;
                                    background-color: var(--card-background-color) !important;
                                }
                            }
                        }
                    }
                }
                label {
                    cursor: pointer !important;
                    &.as-radio-group-label {
                        background: transparent !important;
                        color: $default-border-color !important;
                        padding: 0 12px 0 0 !important;
                    }
                }
            }

            &.with-icon {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .icon-wrapper {
                    --title-icon-wrapper-size: 30px;
                    width: var(--title-icon-wrapper-size);
                    height: var(--title-icon-wrapper-size);
                    background-color: transparentize($default-theme-color, 0.9);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: var(--title-icon-wrapper-size);
                    margin-right: calc(var(--title-icon-wrapper-size) * 0.4);
                    .title-icon {
                        color: $default-theme-color;
                        font-size: calc(var(--title-icon-wrapper-size) * 0.5);
                    }

                    &.green {
                        background-color: transparentize($default-green-color, 0.9);
                        .title-icon {
                            color: $default-green-color;
                        }
                    }
                    &.purple {
                        background-color: transparentize($default-purple-accent, 0.9);
                        .title-icon {
                            color: $default-purple-accent;
                        }
                    }
                    &.yellow {
                        background-color: transparentize($default-yellow-color, 0.9);
                        .title-icon {
                            color: $default-yellow-color;
                        }
                    }
                    &.red {
                        background-color: transparentize($default-red-color, 0.9);
                        .title-icon {
                            color: $default-red-color;
                        }
                    }
                }
            }
        }

        .action-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 2;

            .input-wrapper {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .input-group {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    margin: 0 5px;
                    label {
                        white-space: nowrap;
                        margin-right: 5px;
                        padding: 5px 0;
                    }
                    input {
                        &.filter-input {
                            background-color: transparent;
                            border: 1px solid transparent;
                            border-bottom: 1px solid transparentize($default-border-color, 0.64);
                            border-radius: 0;
                            box-shadow: none;
                        }
                    }
                }
            }
        }

    }

    .sub-header {
        padding: calc(var(--master-padding) / 4) var(--master-padding);
        &.show-border {
            border-bottom: 1px solid transparentize($default-border-color, 0.8);
        }
    }

    .body {
        padding: calc(var(--master-padding) / 2) var(--master-padding);
        box-sizing: border-box;
        width: 100%;
        min-height: 300px;
        overflow: hidden;

        &.auto {
            min-height: unset;
        }
        
        &.overflow {
            max-height: 500px;
            overflow: auto;
        }

        &:hover {
            overflow-y: auto;
        }

        .pos-dasboard-today-summary {
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            .summary-container {
                padding: 5px;
                flex: 1;
                .summary-title {
                    color: $default-text-color-light;
                    padding-left: 5px;
                }
                .summary-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: transparentize($default-border-color, 0.9);
                    margin: 5px 0;
                    padding: 4px;
                    --icon-wrapper-size: 30px;
                    --icon-size: 14px;
                    border-radius: var(--icon-wrapper-size);
                    padding-right: calc(var(--icon-wrapper-size) / 2);
                    .summary-title-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-right: calc(var(--icon-wrapper-size) / 2);
                        .icon-wrapper {
                            width: var(--icon-wrapper-size);
                            height: var(--icon-wrapper-size);
                            background-color: transparentize($default-purple-accent, 0.9);
                            border-radius: var(--icon-wrapper-size);
                            margin-right: calc(var(--icon-wrapper-size) / 4);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .icon {
                                color: $default-purple-accent;
                                font-size: var(--icon-size);
                            }
                            svg {
                                &.icon {
                                    height: var(--icon-size) !important;
                                    width: auto !important;
                                    path, circle, rect, line, polygon, ellipse {
                                        fill: $default-purple-accent !important;
                                    }
                                }
                            }
                        }
                        span {
                            &.title {
                                color: $default-text-color-light;
                            }
                        }
                    }
                    span {
                        &.value {
                            color: $default-text-color-dark;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        table {
            &.pos-dashboard-tbl {
                table-layout: fixed;
                width: 100%;
                max-width: 100%;
                font-size: 12px;

                tbody,thead {
                    tr {
                        td, th {
                            padding: 5px 0;
                            color: $default-text-color-dark;
                            text-align: left;
                            &:nth-child(6) {
                                color: $default-text-color-light;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        &:nth-child(odd) {
                            td {
                                border-top: 1px solid transparentize($default-border-color, 0.64);
                                border-bottom: 1px solid transparentize($default-border-color, 0.64);
                            }
                        }
                    }
                }

                &.upcoming-events {
                    tbody {
                        tr {
                            td, th {
                                &:nth-child(6) {
                                    div {
                                        display: block;
                                        padding: 4px 0;
                                        span {
                                            .starts-icon {
                                                color: transparentize($default-green-color, 0.4);
                                            }
                                            .ends-icon {
                                                color: transparentize($default-red-color, 0.4);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }

        .as-pos-dashboard-card {
            border: none;
            border-radius: unset;
            box-shadow: unset;
        }
    }

    .footer {
        padding: calc(var(--master-padding) / 2) var(--master-padding);
        padding-bottom: calc(var(--master-padding) / 4);
        min-height: var(--header-footer-min-height);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &.show-border {
            border-top: 1px solid transparentize($default-border-color, 0.8);
        }
    }
}


.as-pos-dashboard-card-wrapper {
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid transparentize($default-border-color, 0.8);
    box-shadow: 0px 2px 15px transparentize(#000000, 0.94);
    padding: 0;
    overflow: hidden;
    
    .as-pos-dashboard-card {
        border: none;
        box-shadow: none;
    }
}


.top-least-selling-chart-container {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}